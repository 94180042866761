<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Computer: {{ agent.computerName }}</h1>
      </div>
      <div class="column is-12">
        <div class="tabletitle">
          <span class="name">
            <div class="buttons">
              <router-link
                :to="{
                  name: 'Agents',
                  params: { id: agent.clientID },
                }"
                class="button"
                style="text-decoration: none"
              >
                Agents
              </router-link>
              <template v-if="team.apiType != 'Datto'">
                <button class="button" @click="pushManagementPackageToAgent">
                  <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Push Management
                  Package
                </button></template>
            </div>
            <div class="tabletitle-slim">
              <span class="name"
              ><strong>OS Type:</strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.osType }}
            </span>
          </div> 
          <div class="tabletitle-slim">
              <span class="name"
              ><strong>Status:</strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.status }}
            </span>
          </div> 
          <div class="tabletitle-slim">
              <span class="name"
              ><strong>Site:</strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.locationName }}
            </span>
          </div> 
          </span>
          <span class="option">
            <div class="tabletitle-slim">
              <span class="name"
              ><strong>Computer ID: </strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.computerID }}
            </span>
          </div> 
          <div class="tabletitle-slim">
              <span class="name"
              ><strong>Client ID: </strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.clientID }}
            </span>
          </div> 
          <div class="tabletitle-slim">
              <span class="name"
              ><strong>OS Version: </strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.OS }}
            </span>
          </div>

          <template v-if="team.apiType != 'Datto'">        
          <div class="tabletitle-slim">
              <span class="name"
              ><strong>Deployed Management Package: </strong></span>
            <span class="option" style="font-size: small">
                  {{ agent.versionManagementPackage }}
            </span>
          </div>
          <div class="tabletitle-slim">
              <span class="name"
              ><strong>Available Management Package: </strong></span>
            <span class="option" style="font-size: small">
              <template v-if="agent.osType == 'Linux'">{{ MPVersionLin }}</template>
              <template v-else-if="agent.osType == 'Mac'">{{ MPVersionMac }}</template>
              <template v-else>{{ MPVersionWin }}</template>
            </span>
          </div>
        </template> 
          </span>
        </div>
      </div>


      <div class="column is-12">
        <template v-if="agent.osType == 'Windows'">
          <Agent_Windows v-bind:agent="agent" />
        </template>
        <template v-else-if="agent.osType == 'Linux'">
          <Agent_Linux v-bind:agent="agent" />
        </template>
        <template v-else-if="agent.osType == 'Mac'">
          <Agent_Mac v-bind:agent="agent" />
        </template>
      </div>
      <div class="column is-12"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Buffer } from "buffer";
import moment from "moment";
import { toast } from "bulma-toast";
import Agent_Windows from "@/components/layout/Agent/Windows.vue";
import Agent_Linux from "@/components/layout/Agent/Linux.vue";
import Agent_Mac from "@/components/layout/Agent/Mac.vue";
export default {
  name: "Agent",
  components: {
    Agent_Windows,
    Agent_Linux,
    Agent_Mac,
  },
  data() {
    return {
      team: {
        members: [],
        created_by: {},
      },
      agent: {},
      MPVersionWin: "",
      MPVersionLin: "",
      MPVersionMac: "",
      DattoVersion: "",
      tools: [],
    };
  },
  mounted() {
    this.getTeam();
    this.getAgent();
    this.getTools();

  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    getMPVersionWin() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "management package") {
          this.MPVersionWin = this.tools[i].version;
        }
      }
    },
    getMPVersionLin() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "lum.sh") {
          this.MPVersionLin = this.tools[i].version;
        }
      }
    },
    getMPVersionMac() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "brew.sh") {
          this.MPVersionMac = this.tools[i].version;
        }
      }
    },
    getDattoVersion() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "dattopackagezip") {
          this.DattoVersion = this.tools[i].version;
        }
      }
    },
    async getTools() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/motd/get_tool_versions/")
        .then((response) => {
          this.tools = response.data;
          this.getMPVersionWin();
          this.getMPVersionLin();
          this.getMPVersionMac();
          this.getDattoVersion();
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${agentID}/`)
        .then((response) => {
          this.agent = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },



    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async pushManagementPackageToAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/rmm/push_management_package_to_agent/${agentID}/`)
        .then((response) => {
          toast({
            message: "Push request sent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          toast({
            message: error,
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

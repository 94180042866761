<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12 is-multiline">
        <div class="column is-12">
          <h1 class="title">Add member</h1>
          <form @submit.prevent="submitForm">
            <div class="field">
              <label>Email</label>
              <div class="control">
                <input type="email" name="email" class="input" v-model="username" />
              </div>
            </div>
            <div class="field">
              <label>First Name</label>
              <div class="control">
                <input type="text" name="name" class="input" v-model="first_name" />
              </div>
            </div>
            <div class="field">
              <label>Last Name</label>
              <div class="control">
                <input type="text" name="name" class="input" v-model="last_name" />
              </div>
            </div>
            <div class="field">
              <label>Password</label>
              <div class="control">
                <input
                  type="password"
                  name="password1"
                  class="input"
                  v-model="password1"
                />
              </div>
            </div>
            <div class="field">
              <label>Retype Password</label>
              <div class="control">
                <input
                  type="password"
                  name="password2"
                  class="input"
                  v-model="password2"
                />
              </div>
            </div>
            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
            <div class="field">
              <div class="control">
                <button class="button is-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <div class="column is-12"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "AddMember",
  data() {
    return {
      username: "",
      first_name: "",
      last_name: "",
      password1: "",
      password2: "",
      errors: [],
    };
  },
  methods: {
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      if (this.username === "") {
        this.errors.push("Username is missing");
      }
      if (this.password1 === "") {
        this.errors.push("Password is to short");
      }
      if (this.password1 !== this.password2) {
        this.errors.push("Passwords do not match");
      }

      if (!this.errors.length) {
        const formData = {
          username: this.username,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password1,
        };

        await axios
          .post("/api/v1/users/", formData)
          .then((response) => {
            toast({
              message: "Member successfully added",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            //this.$router.push({ name: "Team" });
            const emailData = { email: this.username };

            axios
              .post("/api/v1/teams/add_member/", emailData)
              .then((response) => {
                this.$router.push({ name: "Team" });
              })
              .catch((error) => {
                if (error.response) {
                  for (const property in error.response.data) {
                    this.errors.push(`${property}: ${error.response.data[property]}`);
                  }
                } else if (this.errors.message) {
                  this.errors.push("Something went wrong, please try again.");
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`);
              }
            } else if (this.errors.message) {
              this.errors.push("Something went wrong, please try again.");
            }
          });
      }

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

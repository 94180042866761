t
<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="tabletitle">
          <span class="name"><h1 class="title">RMMmax Service Plans</h1></span>
          <span class="option buttons">
            <template v-if="$store.state.team.plan !== 'undefined'">
              <h1 class="subtitle">
                Current Plan: <strong>{{ $store.state.team.plan }}</strong>
              </h1>
            </template>
          </span>
        </div>
      </div>
      <div class="column is-12 mt-3 mb-3">
        <p>
          These tiers are an incredible deal for any MSP looking to streamline their operations and maximize efficiency. Tier 1 offers an unbeatable opportunity to kickstart your journey without any financial commitment. With essential features and support for up to 15 agents, it's the perfect starting point for small MSPs or those just dipping their toes into the industry. Tier 2 takes things up a notch, providing enhanced capabilities and flexibility for just $25. This tier is tailor-made for growing MSPs, offering advanced features while accommodating a larger agent count of up to 1500. And for those seeking the ultimate in performance and scalability, Tier 3 is the way to go. Priced at just $50, Tier 3 is designed for established MSPs with extensive agent networks, supporting up to 5000 agents. With unparalleled performance and a wealth of features, Tier 3 is the premium choice for MSPs looking to take their business to new heights.

        </p>
      </div>
      <template v-for="plan in plans">
        <template v-if="plan.isEnabled == true">
          <div class="column is-4">
            <div class="box">
              <h2 class="subtitle">{{ plan.name }}</h2>
              <p class="mb-4">{{ plan.description }}</p>

              <h4 class="is-size-3 mb-2">${{ plan.price }}</h4>
              <p>
                Max {{ plan.max_agents }} agents ( ${{
                  pricePerAgent(plan.price, plan.max_agents)
                }}
                cents per agent )
              </p>
              <template v-if="$store.state.team.plan !== plan.name">
                <button @click="subscribe(plan.name)" class="button is-primary mt-3">
                  Subscribe
                </button>
              </template>
              <template v-else="">
                <div class="buttons">
                  <template v-if="team.stripe_customer_id != null">
                    <button @click="openCustomerPortal()" class="button is-warning mt-3">
                      Manage
                    </button></template
                  ><template v-else>
                    <button @click="" class="button is-primary mt-3">
                      Active {{ team.stripe_customer_id }}
                    </button></template
                  >
                </div>
              </template>
            </div>
          </div>
        </template>
      </template>
      <hr />
      <div class="column is-12"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "Plans",
  data() {
    return {
      pub_key: "",
      stripe: null,
      plans: [],
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  async mounted() {
    this.getTeam();
    this.getPlans();
    await this.getPubKey();
    this.stripe = Stripe(this.pub_key);
  },
  methods: {
    pricePerAgent(price, agents) {
      return (+price / +agents).toFixed(2);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (response.data.name.length > 0) {
            this.team = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getPlans() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/plans/getplans/`)
        .then((response) => {
          this.plans = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async cancelPlan() {
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/teams/cancel_plan/")
        .then((response) => {
          this.$store.commit("setTeam", {
            id: response.data.id,
            username: response.data.name,
            plan: response.data.plan.name,
            max_agents: response.data.plan.max_agents,
            max_clients: response.data.plan.max_clients,
          });
          toast({
            message: "Subscription was canceled successufully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.$router.push("/dashboard/team");
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getPubKey() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/stripe/get_stripe_pub_key/")
        .then((response) => {
          this.pub_key = response.data.pub_key;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async openCustomerPortal() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/stripe/create_portal_session/")
        .then((response) => {
          //this.$router.push({ path: response.data.url.url });
          window.location = response.data.url.url;
        })
        .catch((error) => {
          console.log(error);
          toast({
            message: "Stripe.com failed to return customer portal",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
    async subscribe(plan) {
      this.$store.commit("setIsLoading", true);
      const data = {
        plan: plan,
      };
      await axios
        .post("/api/v1/teams/upgrade_plan/", data)
        .then((response) => {
          //console.log(response.data);
          this.$store.commit("setTeam", {
            id: response.data.id,
            username: response.data.name,
            plan: response.data.plan.name,
            max_agents: response.data.plan.max_agents,
            max_clients: response.data.plan.max_clients,
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            message: "Failed to upgrade plan",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: "bottom-right",
          });
        });
      if (plan !== "Free") {
        await axios
          .post("/api/v1/stripe/create_checkout_session/", data)
          .then((response) => {
            console.log(response);
            return this.stripe.redirectToCheckout({ sessionId: response.data.sessionId });
          })
          .catch((error) => {
            console.log(error);
            toast({
              message: "Stripe.com failed to return checkout session",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: "bottom-right",
            });
          });
      } else {
        toast({
          message: "Plan upgrade was successfull",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
      }
      this.$router.push("/dashboard/team");
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-8">
        <h1 class="title">{{ client.clientName }} Agents</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>

          <router-link
            class="button"
            to="/dashboard/clients"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
          <button class="button is-success" @click="enableAllToolsForAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Prime Client
          </button>
          <button class="button is-danger" @click="purgeAgents">
            <va-icon class="icon ion-md-trash mr-1"></va-icon> Purge Agents
          </button>
        </div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <template v-if="checkDateWithin2Days(client.lastSync) === false" class="text-danger">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Last Client Sync:</strong></p></span>
              <span class="option"><small style="font-size: small">{{ format_date(client.lastSync) }}</small></span>
              </div>
          </template>
          <template v-else class="text-success">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Last Client Sync:</strong></p></span>
              <span class="option"><small style="font-size: small">{{ format_date(client.lastSync) }}</small></span>
              </div>
          </template>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span>
              <span class="option"><img class="" src="/images/green_check.png" /></span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span>
              <span class="option"><img class="" src="/images/red_x.png" /></span>
            </div>
          </template>
          <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Logs</strong></p></span>
              <span class="option">
                <router-link
                  class=""
                  style="text-decoration: none"
                  :to="{ name: 'TeamServiceLogs' }">
            <va-icon class="icon ion-md-today" style="color:darkgrey;"></va-icon></router-link>
              </span>
            </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle">
            <span class="name">
              <label class="form-checkbox">
                <input type="checkbox" v-model="Windows" @click="reloadAgents" />
                <va-icon class="form-icon"></va-icon> Windows
              </label>
              <label class="form-checkbox ml-4">
                <input type="checkbox" v-model="Linux" @click="reloadAgents" />
                <va-icon class="form-icon"></va-icon> Linux
              </label>
              <label class="form-checkbox ml-4">
                <input type="checkbox" v-model="Mac" @click="reloadAgents" />
                <va-icon class="form-icon"></va-icon> Mac
              </label>
            </span>
            <span class="option"> </span>
          </div>
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="agentTable">
              <thead>
                <tr>
                  <th style="width: 12px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 15px"><va-icon class="icon ion-md-apps"></va-icon></th>
                  <th>Agent Name</th>
                  <th>Site</th>
                  <th>OS</th>
                  <th>Status</th>
                  <th>Last Contact</th>
                  <th scope="col">Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                    <template v-if="agent.osType == 'Linux'">  
                        <AgentMenu_Linux :agent="agent" /> 
                    </template>
                    <template v-else-if="agent.osType == 'Mac'"> 
                        <AgentMenu_Mac :agent="agent" />   
                    </template>
                    <template v-else>
                        <AgentMenu_Windows :agent="agent" /></template>
                      </td>
                    <td>{{ agent.computerName }}</td>
                    <td>{{ agent.locationName }}</td>
                    <td>{{ agent.OS }}</td>
                    <td>{{ agent.status }}</td>
                    <td>{{ format_date(agent.lastContact) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <img src="/images/green_check.png" />
                      <span style="display: none">enabled</span>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" />
                      <span style="display: none">disabled</span>
                    </td>
                  </tr></template
                >
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <template v-if="agent.osType == 'Linux'">
                        <td>
                          <AgentMenu_Linux :agent="agent" />
                        </td>
                      </template>
                      <template v-else-if="agent.osType == 'Mac'">
                        <td>
                          <AgentMenu_Mac :agent="agent" />
                        </td>
                      </template>
                      <template v-else
                        ><td>
                          <AgentMenu_Windows :agent="agent" /></td
                      ></template>
                      <td>{{ agent.computerName }}</td>
                      <td>{{ agent.locationName }}</td>
                      <td>{{ agent.OS }}</td>
                      <td>{{ agent.status }}</td>
                      <td>{{ format_date(agent.lastContact) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <img src="/images/green_check.png" />
                        <span style="display: none">enabled</span>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" />
                        <span style="display: none">disabled</span>
                      </td>
                    </tr></template
                  >
                </template>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p>No agents were returned for client.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import AgentMenu_Windows from "@/components/layout/AgentMenu-Windows";
import AgentMenu_Mac from "@/components/layout/AgentMenu-Mac";
import AgentMenu_Linux from "@/components/layout/AgentMenu-Linux";
import ClientMenu from "@/components/layout/ClientMenu";
export default {
  name: "Agents",
  components: {
    AgentMenu_Windows,
    AgentMenu_Mac,
    AgentMenu_Linux,
    ClientMenu,
  },
  data() {
    return {
      team: {
        members: [],
        sites: [],
        created_by: {},
      },
      errors: [],
      agents: [],
      agentConfig: {},
      client: {},
      clients: [],
      num_agents: 0,
      selected: [],
      selectAll: false,
      Windows: true,
      Mac: true,
      Linux: true,
      dataTable: null,
    };
  },
  mounted() {
    this.getTeam();
    this.getAgents();
    this.getClients();
    this.getClient();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    submitForm() {
      this.getAgents();
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      this.clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/teams/get_my_team/`)
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClient() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;

      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/all_agents_by_client/${clientID}/`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
          setTimeout(function () {
           this.dataTable = $("#agentTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async reloadAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/all_agents_by_client/${clientID}/`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = [];
          for (let i in response.data) {
            if (response.data[i].osType == "Windows" && this.Windows == true) {
              this.agents.push(response.data[i]);
            }
            if (response.data[i].osType == "Linux" && this.Linux == true) {
              this.agents.push(response.data[i]);
            }
            if (response.data[i].osType == "Mac" && this.Mac == true) {
              this.agents.push(response.data[i]);
            }
          }
          this.dataTable.destroy();
            setTimeout(function () {
              this.dataTable = $("#agentsTable").DataTable({
                pagingType: "full_numbers",
                pageLength: 25,
                processing: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                  "<'row'<'col-sm-12'tr>>" +
                  "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                  "<'row'<'col-sm-12'B>>",
                buttons: ["copy", "csv", "print"],
                lengthMenu: [
                  [25, 50, 100, -1],
                  [25, 50, 100, "All"],
                ],
              });
            }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/agents/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.reloadAgents();

            toast({
              message: "Agent status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async purgeAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/rmm/purge/agents/`, this.selected)
          .then((response) => {
            this.reloadAgents();
            toast({
              message: "Purged agent data completed successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            this.errors.push(this.errors.message);
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async enableAllToolsForAgents() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/rmm/agents/enable_all_client_tools/${clientID}`)
        .then((response) => {})
        .catch((error) => {
          this.errors.push(error);
        });

      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        await axios
          .post(`/api/v1/rmm/agents/enable_all_agent_tools/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.reloadAgents();

            toast({
              message: "Agent status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push(error.response);
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
      }
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

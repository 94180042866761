<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">SurfLog Agent Manager for {{ agent.computerName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <hr />
        <div class="buttons">
          <router-link
            :to="{
              name: 'SurfLogClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Agents
          </router-link>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentConfigParams"
            aria-expanded="false"
            aria-controls="agentConfigParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentSurflogs"
            aria-expanded="false"
            aria-controls="agentSurflogs"
          >
            <va-icon class="icon ion-md-list mr-1"></va-icon> Raw Logs
          </button>
          <button class="button is-success" @click="scanAgent">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan Agent
          </button>
          <button class="button is-success" @click="enableSurflog">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
          <!-- End of Buttons -->
        </div>
      </div>
      <!-- status box  -->
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"><strong>Last Sync:</strong></span>
            <span class="option">
              <template v-if="checkDateWithin2Days(agentConfig.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="agentConfig.isEnabled === true">
            <div class="tabletitle-slim">
              <span class="name"><strong>Agent Enabled:</strong></span>
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"><strong>Agent Enabled:</strong></span>
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- start Settings dropdown -->
      <div class="column is-12">
        <div class="collapse" id="agentConfigParams">
          <div class="card card-body">
            <h1 class="subtitle">Settings</h1>
            <p class="mb-5">
              This is the Surflog parameters area that will control how this agent uses
              Surflog
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="agentConfig.isEnabled"
                    />Enable Agent Scans</label
                  >
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="agentConfig.emailReport"
                    />Email Scan Reports</label
                  >
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-success"
                    data-bs-toggle="collapse"
                    data-bs-target="#agentConfigParams"
                    aria-expanded="false"
                    aria-controls="agentConfigParams"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Settings -->
      <!-- start RawLogs dropdown -->
      <div class="column is-12">
        <div class="collapse" id="agentSurflogs">
          <div class="card card-body">
            <div>
              <span
                style="
                   {
                    display: inline;
                    float: left;
                  }
                "
                ><h1 class="subtitle">Raw History Logs</h1></span
              >
              <span
                style="
                   {
                    display: inline;
                    float: right;
                  }
                "
              >
                <button class="button is-danger" @click="purgeWebLogs">
                  <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Purge Logs
                </button></span
              >
            </div>
            <table class="table" id="webLogs">
              <thead>
                <tr>
                  <th style="width: 20px"></th>
                  <th>Title</th>
                  <th>URL</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>Duration</th>
                  <th>Visits</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in agentLogs" v-bind:key="log.id">
                  <td v-if="log.webBrowser === 'Chrome'">
                    <img src="/images/chrome.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else-if="log.webBrowser === 'Firefox'">
                    <img src="/images/firefox.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else-if="log.webBrowser === 'Internet Explorer 10/11 / Edge'">
                    <img src="/images/edge.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else-if="log.webBrowser === 'Moon'">
                    <img src="/images/moon.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else-if="log.webBrowser === 'Yandex'">
                    <img src="/images/Yandex.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else-if="log.webBrowser === 'Safari'">
                    <img src="/images/safari.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else-if="log.webBrowser === 'Internet Explorer'">
                    <img src="/images/ie.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td v-else>
                    <img src="/images/chromium.png" alt="{{ log.webBrowser }}" />
                  </td>
                  <td>
                    {{ log.title }}
                  </td>
                  <td>
                    {{ log.URL }}
                  </td>
                  <td>
                    {{ format_date(log.visitTime) }}
                  </td>
                  <td>{{ log.userProfile }}</td>
                  <td>{{ log.visitDuration }}</td>
                  <td>{{ log.visitCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end Raw Logs -->
      <div class="column is-12">
        <!-- start Charts dropdown -->
        <div class="card card-body">
          <h1 class="subtitle">Agent History Charts</h1>
          <div class="chartcontainer">
            <div class="BrowserTypes">
              <div class="box mr-3">
                <h2 class="subtitle mt-3">
                  <va-icon class="icon ion-md-analytics"></va-icon> Browser Usage
                </h2>
                <SurfLogAgentBrowserTypesPie :computerID="this.$route.params.id" />
              </div>
            </div>
            <div class="UsageByHour">
              <div class="box">
                <h2 class="subtitle mt-3">
                  <va-icon class="icon ion-md-analytics"></va-icon> Hourly Usage
                </h2>
                <SurfLogAgentUsageByHourBar :computerID="this.$route.params.id" />
              </div>
            </div>
            <div class="ProtocolTypes">
              <div class="box mr-3">
                <h2 class="subtitle mt-3">
                  <va-icon class="icon ion-md-analytics"></va-icon> Protocol Usage
                </h2>
                <SurfLogAgentProtocolTypesPie :computerID="this.$route.params.id" />
              </div>
            </div>
            <div class="UsageByDay">
              <div class="box">
                <h2 class="subtitle mt-3">
                  <va-icon class="icon ion-md-analytics"></va-icon> Daily Usage
                </h2>
                <SurfLogAgentUsageByDayBar :computerID="this.$route.params.id" />
              </div>
            </div>
            <div class="UsageByProfile">
              <div class="box">
                <h2 class="subtitle mt-3">
                  <va-icon class="icon ion-md-analytics"></va-icon> Top Profile Usage
                </h2>
                <SurfLogAgentUsageByProfileBar :computerID="this.$route.params.id" />
              </div>
            </div>
          </div>
        </div>
        <!-- end rawlogs -->
      </div>
    </div>
  </div>
</template>

<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import SurfLogAgentBrowserTypesPie from "@/components/charts/surflog/SurfLogAgentBrowserTypes.vue";
import SurfLogAgentUsageByHourBar from "@/components/charts/surflog/SurfLogAgentUsageByHour.vue";
import SurfLogAgentProtocolTypesPie from "@/components/charts/surflog/SurfLogAgentProtocolTypes.vue";
import SurfLogAgentUsageByDayBar from "@/components/charts/surflog/SurfLogAgentUsageByDay.vue";
import SurfLogAgentUsageByProfileBar from "@/components/charts/surflog/SurfLogAgentUsageByProfile.vue";
export default {
  name: "SurfLogAgentManager",
  components: {
    SurfLogAgentBrowserTypesPie,
    SurfLogAgentUsageByHourBar,
    SurfLogAgentProtocolTypesPie,
    SurfLogAgentUsageByDayBar,
    SurfLogAgentUsageByProfileBar,
  },
  data() {
    return {
      errors: [],
      agent: {},
      agentLogs: [],
      agentConfig: {},
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getAgent();
    this.getAgentConfig();
    this.getAgentLogs();
  },
  methods: {
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${agentID}`)
        .then((response) => {
          this.agent = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgentConfig() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_surflog_agent_config/${computerID}/`)
        .then((response) => {
          this.agentConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async purgeWebLogs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/purge_surflogs_for_agent/${computerID}/`)
        .then((response) => {
          this.agentLogs = [];
          toast({
            message: "Surflog agent logs purged successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgentLogs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_surflog_agent_logs/${computerID}/`)
        .then((response) => {
          this.agentLogs = response.data;
          setTimeout(function () {
            $("#webLogs").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Scan Client Function
    async scanAgent() {
      this.errors = [];
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const computerID = this.$route.params.id;
        await axios
          .get(`/api/v1/surflog/get_surflogs_for_agent/${computerID}/`)
          .then((response) => {
            toast({
              message: "Surflog agent scan completed successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },

    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/surflog/edit_surflog_agent_config/", this.agentConfig)
        .then((response) => {
          toast({
            message: "Surflog agent configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="tabletitle">
            <span class="name"><h1 class="title">New Client Defaults</h1></span >
            <span class="option">          
              <router-link
            class="button is-success"
            :to="{ name: 'RMMEnvironment' }"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-build"></va-icon> <strong>RMM Environment</strong>
          </router-link></span></div>
        
        <p class="mb-3 mt-6">When enabled, any new clients or agents that report into RMMmax will automaticlly receive the following configurations as their default settings.</p>
        <P class="mt-4"><input type="checkbox" v-model="clientDefaults.clientEnable" @click="setDefaults" /> Enable New Client Onboarding</P>
      </div>
      <div class="column is-12">
        <template v-if="clientDefaults.clientEnable == true">
          <va-collapse
          v-model="agentsEnable"
          style="width: 100%"
          header="Agents"
        >
        <div class="card card-body">
        <P class="mt-3"><input type="checkbox" v-model="clientDefaults.agentDesktopEnable" @click="setAgentDesktopEnable" /> <strong>Enable Desktop / Laptop Agents</strong></P>
        <P class="mt-3"><input type="checkbox" v-model="clientDefaults.agentServerEnable" @click="setAgentServerEnable" /> <strong>Enable Server Agents</strong></P>
      </div>
      </va-collapse>
          <va-collapse
          v-model="chocolateyEnable"
          style="width: 100%"
          header="Chocolatey"
          class="mt-4"
        >
        <ChocolateyDefaults :clientDefaults="clientDefaults"/>
      </va-collapse>
      <va-collapse
          v-model="lumEnable"
          style="width: 100%"
          header="Linux Updates"
          class="mt-4"
        >
        <LUMClientDefaults :clientDefaults="clientDefaults"/>
      
      </va-collapse>
        <va-collapse
          v-model="brewEnable"
          style="width: 100%"
          header="HomeBrew"
          class="mt-4"
        >
        <BrewClientDefaults :clientDefaults="clientDefaults"/>
      
      </va-collapse>
        <va-collapse
          v-model="bitlockerEnable"
          style="width: 100%"
          header="BitLocker"
          class="mt-4"
        >    
        <div class="card card-body">
        <P class="mt-3"><input type="checkbox" v-model="clientDefaults.bitlockerEnable" @click="setBitLockerEnable" /> <strong>Enable BitLocker Tool</strong></P>
      </div>
      </va-collapse>
        <va-collapse
          v-model="bleachbitEnable"
          style="width: 100%"
          header="BleachBit"
          class="mt-4"
        >
        <div class="card card-body">
        <P class="mt-3"><input type="checkbox" v-model="clientDefaults.bleachbitEnable" @click="setBleachBitEnable" /> <strong>Enable BleachBit Tool</strong></P>
      </div>
      </va-collapse>
        <va-collapse
          v-model="defenderEnable"
          style="width: 100%"
          header="Windows Defender"
          class="mt-4"
        >
        <div class="card card-body">
        <P class="mt-3"><input type="checkbox" v-model="clientDefaults.defenderEnable" @click="setDefenderEnable" /> <strong>Enable Defender Tool</strong></P>
      </div>
      
      </va-collapse>
        <va-collapse
          v-model="surflogEnable"
          style="width: 100%"
          header="SurfLog"
          class="mt-4"
        >
        <div class="card card-body">
        <P class="mt-3"><input type="checkbox" v-model="clientDefaults.surflogEnable" @click="setSurfLogEnable" /> <strong>Enable SurfLog Tool</strong></P>
      </div>
      
      </va-collapse>

        </template>
      </div>
      <div class="column is-12">
        <p></p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { toast } from "bulma-toast";
import ChocolateyDefaults from "@/components/forms/ClientDefaults/ChocolateyDefaults";
import LUMClientDefaults from "@/components/forms/ClientDefaults/LUMDefaults";
import BrewClientDefaults from "@/components/forms/ClientDefaults/BrewDefaults";
export default {
  name: "Client",
  components: {
    ChocolateyDefaults,
    LUMClientDefaults,
    BrewClientDefaults,
  },
  data() {
    return {
      packageName: '',
      approvedPackages: [],
      clientDefaults: {},

    };
  },
  mounted() {
    this.getDefaults()
  },
  methods: {
    async setDefaults() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/enable_clients_defaults/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getDefaults() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/get_clients_defaults/`)
        .then((response) => {
          this.clientDefaults = response.data
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async setBitLockerEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_bitLocker_default_enable/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async setBleachBitEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_bleachbit_default_enable/`)
        .then((response) => {

          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async setDefenderEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_defender_default_enable/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async setSurfLogEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_surflog_default_enable/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async setAgentDesktopEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_agent_desktop_enable/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async setAgentServerEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_agent_server_enable/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Let's Setup Your Team</h1>
        <p class="mt-2">
          Your team helps defines some of the basic information that allows RMMMax to
          assist in managing your RMM or RAT environment. Each environment uses different
          access methods so make sure to select the correct host type and provide the API
          credentials required by that host. Afterwards you will be redirected to our
          plans and pricing page where you can select from our available plans. We suggest
          you give our Free Demo plan a try if this is your first time with us.
        </p>
      </div>
      <div class="column is-12">
        <h1 class="subtitle">Select your environment</h1>
        <p class=""></p>
        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Team Name</label>
            <div class="control">
              <input type="text" class="input" v-model="name" />
            </div>
          </div>
          <div class="field">
            <label class="checkbox-inline"
              ><input class="form-check-input mr-2" type="checkbox" v-model="mfa" />Enable
              Multi Factor Authenication</label
            >

            <p class="mt-2">
              <small class="text-muted ml-4 mt-2 mb-2">
                (Enable MFA if you want all team members to be required to use MFA to
                login to RMMmax)</small
              >
            </p>
          </div>
          <div class="field">
            <label>RMM or RAT Type</label>
            <div class="control">
              <div class="select">
                <select v-model="apiType">
                  <option value="Automate" selected>ConnectWise Automate</option>
                  <option value="TacticalRMM">Tactical RMM</option>
                  <option value="Datto">Datto RMM</option>
                  <option value="ScreenConnect">ConnectWise Control</option>
                </select>
              </div>
            </div>
          </div>
          <template v-if="apiType !== ''">
            <h1 class="subtitle mt-5">Setting up access to your environment</h1>
            <template v-if="apiType == 'Automate'">
              <p class="mt-3">
                When using ConnectWise Automate as a RMM platform you will need to create
                a new user and give that user the "integrator" checkbox status. Once you
                have that user setup you can set the API User and API Password fields. The
                integrator checkbox is found at the bottom left side of the users settings
                window in the User Manager section of the Control Center.
              </p>
              <p>
                The RMM Host name is FQDN of your Automate host. If you are hosted with
                ConnectWise then it would be your
                <strong>(root domain name).hostedrmm.com.</strong>
              </p>
              <p class="">
                Typically the API URL will look like
                <strong>https://(root domain name).hostedrmm.com/cwa/api/v1/</strong>
                (make sure to include the ending slash). By default the api is accessable
                via this URL but if you have issues connecting then refer to CW Support
                teams for correct base API URL for your environment.
              </p>
              <div class="field">
                <label>RMM or RAT Hostname</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="rmmHost"
                    placeholder="rmm.mydomain.com"
                  />
                </div>
              </div>

              <div class="field">
                <label>API URL</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="apiURL"
                    placeholder="https://rmm.mydomain.com/api/v1/"
                  />
                </div>
              </div>
              <div class="field">
                <label>API User</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiUser" />
                </div>
              </div>
              <div class="field">
                <label>API Password</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiPass" />
                </div></div
            ></template>
            <template v-if="apiType == 'TacticalRMM'">
              <p class="mt-3">
                When setting up your team and you use Tactical RMM as a RMM platform you
                need an user in Tactical RMM to bind to the API key you will generate as a
                API Token. You should create a new user on Tactical RMM and then create an
                API token for that user, but you can bind to an existing user is you
                desire. Once you have the user created, select the main "Settings" from
                the menubar and in the window that is displayed select "API KEYS" from the
                left hand menu. Add a new key for the user you created to use, then use
                the key as the API Token in the form below.
              </p>
              <p>
                The RMM Host name is FQDN of your RMM host.
                <strong>rmm.(root domain name)</strong> is the most common but will depend
                on your environment setups.
              </p>
              <p class="mb-5">
                The API URL will be the same URL you used when you setup Tactical RMM for
                API access. Typically by default this is
                <strong>https://api.yourdomain.com/</strong>, Use the same API URL as the
                one you created during the TacticalRMM installation.
              </p>
              <div class="field">
                <label>RMM or RAT Hostname</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="rmmHost"
                    placeholder="rmm.mydomain.com"
                  />
                </div>
              </div>

              <div class="field">
                <label>API URL</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="apiURL"
                    placeholder="https://api.mydomain.com/"
                  />
                </div>
              </div>
              <div class="field">
                <label>API Token</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiToken" />
                </div>
              </div>
            </template>
            <template v-if="apiType == 'Datto'">
              <p class="mt-3">
                API access to Datto RMM requires it to be enabled and the API Key and API
                Secret to access the Datto environment.
              </p>
              <label>Setup API Access on Datto</label>
              <ul class="mb-5">
                <li class="mt-3">
                  1) Navigate to
                  <strong>Setup > Account Settings > Access Control</strong>(legacy UI) or
                  (New UI) <strong>Setup > Global Settings > Accesss Control</strong>
                </li>
                <li class="mt-3">2) Set Enable API Access to ON</li>
                <li class="mt-3">
                  3) Navigate to <strong>Setup > Users</strong> and select the username
                  that you would like to enable API access for.
                </li>
                <li class="mt-3">
                  4) Click <strong>Generate API Keys</strong> to generate an API Key and
                  API Secret for this user.
                </li>
                <li class="mt-3">
                  5) Capture the API Key, API Secret and the API URL that is provided
                  during key creation. They will be used here to allow access to your
                  environment. They should be stored safely and securely locally.
                </li>
                <li class="mt-3">
                  6) If you are using the legacy UI, the API URL field is added to the
                  Users Details page when API Access is enabled for the Datto account
                </li>
              </ul>
              <p>
                The RMM Host name is FQDN of your RMM host.
                <strong>concord.rmm.datto.com</strong> is the most common but will depend
                on your environment setups.
              </p>
              <p>
                The API URL is provided to you during the API key creation process. If you
                were using "concord" as your main RMM URL then the API URL may look
                something like this
                <strong>https://concord-api.centrastage.net/</strong>
              </p>
              <p class="mb-5">
                Now that you have the information needed, complete the form to enable
                access to your environment.
              </p>
              <div class="field">
                <label>RMM or RAT Hostname</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="rmmHost"
                    placeholder="rmm.mydomain.com"
                  />
                </div>
              </div>

              <div class="field">
                <label>API URL</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="apiURL"
                    placeholder="https://concord-api.centrastage.net/"
                  />
                </div>
              </div>
              <div class="field">
                <label>API Key</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiToken" />
                </div>
              </div>
              <div class="field">
                <label>API Secret</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiSecret" />
                </div>
              </div>
            </template>
            <template v-if="apiType == 'ScreenConnect'">
              <p class="mt-3">
                ConnectWise Control by default does not come with an API that you can
                authenticate against or connect to endpoints that will allow you to do
                stuff. Stuff like collecting the company and agent data from the
                environment or being able to send commands down to the local agent. But
                don't be discouraged! Tim Steffen, a.k.a BigDessert has built a nice
                little Screen Connect extension that places the api functions need with
                the authentication support required to make a safe API for CWC.
              </p>
              <p>
                To get started you will need to log in as an administrator to your CW
                Control Environment and go to the Admin section, select Extensions, then
                find and select the Browse Extension Marketplace. Look for and install the
                <strong>RMM+</strong> extra RMM helper functions extention from DataComm.
              </p>
              <p>
                As part of the setup of the RMM+ extension you need to provide 2 forms of
                security to complete the configuration of the API on CW Control. An
                "AllowedOrigin" and a very strong pass phrase, we prefer a generated key
                which you can get at
                <a href="https://randomkeygen.com" target="_blank">RandomKeyGen</a>. This
                key will be passed securely across the Internet to the API so we recommend
                that you use CodeIgniter or 256-bit WEP keys as they do not contain
                characters that may cause distortion of the HTTPS requests.
              </p>
              <p class="mb-5">
                The allowed origin setting is basically another password that is set but
                this is in the form of a FQDM. It can be pretty much any name you want it
                to be as long as it matches the name configured here.
              </p>
              <div class="field">
                <label>RMM or RAT Hostname</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="rmmHost"
                    placeholder="rmm.mydomain.com"
                  />
                </div>
              </div>

              <div class="field">
                <label>API URL</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    v-model="apiURL"
                    placeholder="https://rmm.mydomain.com/remoteaccess/"
                  />
                </div>
              </div>
              <div class="field">
                <label>Allowed Origin</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiToken" />
                </div>
              </div>
              <div class="field">
                <label>Access Key</label>
                <div class="control">
                  <input type="text" class="input" v-model="apiSecret" />
                </div>
              </div>
            </template>

            <div class="field">
              <label>Billing Email</label>
              <div class="control">
                <input type="text" class="input" v-model="billingEmail" placeholder="" />
              </div>
            </div>
            <div class="field">
              <label>Notification Email</label>
              <div class="control">
                <input type="text" class="input" v-model="notificationEmail" />
              </div>
            </div>
            <div class="field">
              <label>Address</label>
              <div class="control">
                <input type="text" class="input" v-model="address" />
              </div>
            </div>
            <div class="field">
              <label>City</label>
              <div class="control">
                <input type="text" class="input" v-model="city" />
              </div>
            </div>
            <div class="field">
              <label>Zipcode</label>
              <div class="control">
                <input type="text" class="input" v-model="zipcode" />
              </div>
            </div>
            <div class="field">
              <label>Country</label>
              <div class="control">
                <input type="text" class="input" v-model="country" />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <button class="button is-success">Submit</button>
              </div>
            </div>
          </template>
        </form>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>

        <h2 class="subtitle mt-6">Agreement Terms</h2>
        <p class="mt-1">
          By submitting this form you are giving RMMMax access and permissions to your RMM
          platform as described in the form above. You agree to allow RMMMax to query,
          cache and display data about your clients and agent. You also agree to allow
          RMMMax to interact with your agents durinng the performance of the duties you
          have assigned to the agents. You can revoke this permission at anytime from your
          RMM platform or by editing your teams infomation and changing or removing the
          settings for your RMM platform.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { doHardWork } from "@/workers/rmmsync/worker-api";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "AddTeam",
  data() {
    return {
      errors: [],
      members: [],
      plan: [],
      name: "",
      mfa: false,
      rmmHost: "",
      apiType: "",
      apiURL: "",
      apiUser: "",
      apiPass: "",
      apiToken: "",
      apiSecret: "",
      address: "",
      city: "",
      zipcode: "",
      country: "",
      billingEmail: "",
      notificationEmail: "",
    };
  },
  methods: {
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      if (!this.name.length > 0) {
        this.errors.push("Team Name can not be blank.");
      }

      if (!this.errors.length) {
        const team = {
          name: this.name,
          mfa: this.mfa,
          rmmHost: this.rmmHost,
          apiType: this.apiType,
          apiURL: this.apiURL.replace(/\/?$/, "/"),
          apiUser: this.apiUser,
          apiPass: this.apiPass,
          apiToken: this.apiToken,
          apiSecret: this.apiSecret,
          billingEmail: this.billingEmail,
          notificationEmail: this.notificationEmail,
          address: this.address,
          city: this.city,
          zipcode: this.zipcode,
          country: this.country,
        };

        await axios
          .post("/api/v1/teams/", team)
          .then((response) => {
            //console.log(response);
            toast({
              message: "Team added successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            this.$store.commit("setTeam", {
              id: response.data.id,
              name: this.name,
              rmmHost: this.rmmHost,
              apiType: this.apiType,
              apiURL: this.apiURL,
              apiUser: this.apiUser,
              apiPass: this.apiPass,
              apiToken: this.apiToken,
              apiSecret: this.apiSecret,
            });
          })
          .catch((error) => {
            console.log(error);
          });
        const token = localStorage.getItem("token");
        //await doHardWork(token, "rmmSync", axios.defaults.baseURL);
        this.$router.push({ name: "Plans" });
        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

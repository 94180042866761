<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="tabletitle">
          <span class="name">
            <h1 class="title">{{ team.name }}</h1>
            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div></span
          >
          <span class="option">
            <template v-if="team.created_by.id === $store.state.user.id">
              <div class="buttons">
                <router-link
                  style="text-decoration: none"
                  :to="{ name: 'AddMember' }"
                  class="button is-success"
                  ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Add
                  member</router-link
                >
                <button
                  class="button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#userAccessLogs"
                  aria-expanded="false"
                  aria-controls="userAccessLogs"
                >
                  <va-icon class="icon ion-md-cog mr-1"></va-icon> Access Logs
                </button>
                <router-link
                  style="text-decoration: none"
                  :to="{ name: 'EditMember', params: { id: $store.state.user.id } }"
                  class="button"
                  ><va-icon class="icon ion-md-contact mr-1"></va-icon> Manage
                  Me</router-link
                >
              </div>
            </template>
          </span>
        </div>
      </div>
      <div class="column is-6">
        <div class="box">
          <p><strong>RMM Host: </strong>{{ team.rmmHost }}</p>
          <p><strong>Host Type: </strong>{{ team.apiType }}</p>
          <p><strong>Plan: </strong>{{ $store.state.team.plan }}</p>
          <p><strong>Agent Limit: </strong>{{ $store.state.team.max_agents }}</p>
          <p><strong>Billing Email: </strong>{{ team.billingEmail }}</p>
          <p><strong>Notifications: </strong>{{ team.notificationEmail }}</p>
          <template v-if="team.created_by.id === $store.state.user.id">
            <p class="mt-3">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{ name: 'Plans' }"
                class="button is-warning"
              >
                <va-icon class="icon ion-md-map mr-1"></va-icon> Change Plan</router-link
              >
            </p>
          </template>
        </div>
      </div>
      <div class="column is-6">
        <div class="box">
          <p><strong>API Configurations</strong></p>
          <p><strong>API URL: </strong>{{ team.apiURL }}</p>
          <p><strong>API Username: </strong>{{ team.apiUser }}</p>
          <template v-if="team.created_by.id === $store.state.user.id">
            <p><strong>API Password: </strong>{{ team.apiPass }}</p>
            <p><strong>API Token: </strong>{{ team.apiToken }}</p>
            <p><strong>API Secret: </strong>{{ team.apiSecret }}</p>
          </template>
          <template v-else>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </template>

          <div class="buttons mt-3">
            <template v-if="team.created_by.id === $store.state.user.id">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{ name: 'EditTeam' }"
                class="button is-warning"
              >
                <va-icon class="icon ion-md-build mr-1"></va-icon> Edit Team</router-link
              ></template
            >
            <button @Click="testConnection()" class="button is-warning">
              <va-icon class="icon ion-md-cloud-done mr-1"></va-icon>
              Test Connection
            </button>
          </div>
        </div>
      </div>
      <hr />

      <!-- start User Access logs dropdown -->
      <div class="column is-12">
        <div class="collapse" id="userAccessLogs">
          <div class="card card-body">
            <h1 class="subtitle">User Access Logs</h1>
            <table class="table is-fullwidth" id="userLogsTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in Logs" v-bind:key="log.id">
                  <td>{{ format_date(log.logDate) }}</td>
                  <td>{{ log.username }}</td>
                  <td>{{ log.message }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end User Access Logs -->

      <div class="column is-12">
        <div class="card card-body">
          <h2 class="subtitle">Members</h2>
          <table class="table is-fullwidth">
            <thead>
              <template v-if="team.created_by.id === $store.state.user.id">
                <th style="width: 40px"></th
              ></template>
              <th>User Name</th>
              <th>Full Name</th>
              <th>Last Login</th>
              <th>Enabled</th>
            </thead>
            <tbody>
              <tr v-for="(member, itemObjKey) in team.members" v-bind:key="member.id">
                <template v-if="team.created_by.id === $store.state.user.id"
                  ><td>
                    <div class="dropdown">
                      <button class="dropbtn">
                        <va-icon class="icon ion-md-apps"></va-icon>
                      </button>
                      <div class="dropdown-content">
                        <a
                          v-if="team.created_by.id !== member.id"
                          @click="enableMember(itemObjKey)"
                          >Enable / Disable</a
                        >
                        <a @click="resetPassword(itemObjKey)">Reset Password</a>
                        <a
                          v-if="team.created_by.id !== member.id"
                          @click="removeMember(itemObjKey)"
                          >Remove Member</a
                        >
                      </div>
                    </div>
                  </td></template
                >
                <td>
                  <template v-if="team.created_by.id == member.id">
                    <va-icon class="icon ion-md-star-outline"></va-icon>
                  </template>
                  {{ member.username }}
                </td>
                <td>{{ member.first_name }} {{ member.last_name }}</td>
                <td>{{ format_date(member.last_login) }}</td>
                <td v-if="member.is_active === true">
                  <img src="/images/green_check.png" />
                </td>
                <td v-else><img src="/images/red_x.png" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "Team",
  data() {
    return {
      errors: [],
      Logs: [],
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getUserAccessLogs();
  },
  methods: {
    async getUserAccessLogs() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/logs/get_user_access_logs`)
        .then((response) => {
          this.Logs = response.data;
          setTimeout(function () {
            $("#userLogsTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "lBfrtip",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    async getTeam() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.team.apiURL === "") {
        this.errors.push(
          "API URL is not defined. Client and agent data will not be available until access to RMM is provided."
        );
      }
      this.$store.commit("setIsLoading", false);
    },

    async testConnection() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/rmm/test/connection/")
        .then((response) => {
          alert(response.data["message"]);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async resetPassword(member_index) {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const myMember = this.team.members[member_index];

      await axios
        .post("/api/v1/users/reset_password/", { email: myMember.username })
        .then((response) => {
          toast({
            message: "Password Reset sent to user.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
    async removeMember(member_index) {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const myMember = this.team.members[member_index];

      await axios
        .post("/api/v1/teams/removeTeamMember/", { email: myMember.username })
        .then((response) => {
          this.getTeam();
          toast({
            message: "Removed user from system.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
    async enableMember(member_index) {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const myMember = this.team.members[member_index];

      await axios
        .post("/api/v1/teams/enableTeamMember/", { email: myMember.username })
        .then((response) => {
          this.getTeam();
          toast({
            message: "Enable / Disable user completed.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">Defender Client Manager for {{ client.clientName }}</h1>
        <div v-if="$store.state.team.max_agents > num_agents"></div>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>
          <router-link
            to="/dashboard/clients"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#clientConfigParams"
            aria-expanded="false"
            aria-controls="clientConfigParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentExclusionsForm"
            aria-expanded="false"
            aria-controls="agentExclusionsForm"
          >
            <va-icon class="icon ion-md-warning mr-1"></va-icon> Exclusions
          </button>
          <!--- Scan Client button does not pass job to worker!!
          <button class="button is-success" @click="scanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan Client
          </button>  --->

          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
          <button class="button is-success" @click="runQuickScan">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Run Quick Scan
          </button>
          <button class="button is-success" @click="runFullScan">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Run Full Scan
          </button>
        </div>
      </div>
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin2Days(clientConfig.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Service Logs:</strong></p></span
            >
            <span class="option">
              <router-link
                class=""
                style="text-decoration: none"
                :to="{
                  name: 'LogViewer',
                  params: {
                    logname: 'Defender',
                    id: client.clientID,
                  },
                }"
                ><va-icon class="icon ion-md-today" style="color: darkgrey"></va-icon
              ></router-link>
            </span>
          </div>
        </div>
      </div>
      <!-- start Settings dropdown -->
      <div class="column is-12">
        <div class="collapse" id="clientConfigParams">
          <div class="card card-body">
            <h1 class="subtitle">Settings</h1>
            <p class="mb-5">
              This is the Defender client configuration area. You can enable or disable
              Defender scans across the client here.
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.isEnabled"
                    />Enable Client Scans</label
                  >
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-success"
                    data-bs-toggle="collapse"
                    data-bs-target="#clientConfigParams"
                    aria-expanded="false"
                    aria-controls="clientConfigParams"
                  >
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Settings -->
      <!-- start agentExclusions dropdown -->
      <div class="column is-12">
        <div class="collapse" id="agentExclusionsForm">
          <div class="card card-body">
            <div class="tabletitle mb-4">
              <span class="name"> <h1 class="subtitle">Add Exclusions</h1></span>
              <span class="option">
                <button class="button is-success" @click="pushExclusions">
                  <va-icon class="icon ion-md-open mr-1"></va-icon> Push Exclusions
                </button>
              </span>
            </div>
            <p class="mb-3">
              Exclusions created here will be applied to all enabled agents under this
              client. There are two types of exclusions, the common file and folder
              exclusions and Attack Surface Reduction Only exclusions. For ASR rules, if
              you add one exclusion, it will affect every ASR rule.
            </p>
            <form @submit.prevent="submitExclusionsForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="exclusionForm.isFile"
                    />Attack Surface Reduction Only Exclusion</label
                  >
                </div>
              </div>
              <div class="field">
                <label>Exclusion (Full path to file or folder)</label>
                <div class="control">
                  <input
                    type="text"
                    name="name"
                    class="input"
                    v-model="exclusionForm.fileFolder"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-success">
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
            <h1 class="subtitle mt-5">Exclusions</h1>
            <p class="mb-1">
              The following entries repersent the expected exclusions for all agents under
              this client.
            </p>
            <hr />
            <div class="buttons">
              <button class="button is-danger" @click="deleteExclusions">
                <va-icon class="icon ion-md-trash mr-1"></va-icon> Delete Selected
                Exclusions
              </button>
            </div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th style="width: 20px">
                    <label class="form-checkbox">
                      <input
                        type="checkbox"
                        v-model="selectExclAll"
                        @click="selectExcl"
                      />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th>Type</th>
                  <th>Exclude</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ex in clientExclusions" v-bind:key="ex.id">
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" :value="ex.id" v-model="selectExcled" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </td>
                  <td v-if="ex.isFile == true">
                    <va-icon class="icon ion-md-copy mr-1"></va-icon>
                  </td>
                  <td v-else>
                    <va-icon class="icon ion-md-folder-open mr-1"></va-icon>
                  </td>
                  <td>{{ ex.fileFolder }}</td>
                  <td v-if="ex.isEnabled === true">
                    <img src="/images/green_check.png" />
                  </td>
                  <td v-else><img src="/images/red_x.png" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end agentExclusions dropdown -->
      <!-- Main View agents -->

      <div class="column is-12">
        <div class="card card-body">
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="clientDefenderTable">
              <thead>
                <tr>
                  <th style="width: 15px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 30px">
                    <va-icon class="icon ion-md-apps"></va-icon>
                  </th>
                  <th>Agent Name</th>
                  <th>AntiVirus</th>
                  <th>AntiSpyware</th>
                  <th>Scan Status</th>
                  <th>Last Sync</th>
                  <th scope="col">Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                      <AgentMenu :agent="agent" />
                    </td>
                    <td>{{ agent.computerName }}</td>
                    <td>
                      <template v-for="status in agentsStatus" v-bind:key="status.id">
                        <div v-if="status.computerID == agent.computerID">
                          <div class="ml-1">
                            Signature Version:
                            <small class="text-muted" style="font-size: small">{{
                              status.AntivirusSignatureVersion
                            }}</small>
                          </div>
                          <div class="ml-1">
                            Last Updated:
                            <small class="text-muted" style="font-size: small">
                              {{
                                format_date(status.AntivirusSignatureLastUpdated)
                              }}</small
                            >
                          </div>
                        </div>
                      </template>
                    </td>
                    <td>
                      <template v-for="status in agentsStatus" v-bind:key="status.id">
                        <div v-if="status.computerID == agent.computerID">
                          <div class="ml-1">
                            Signature Version:
                            <small class="text-muted" style="font-size: small">{{
                              status.AntispywareSignatureVersion
                            }}</small>
                          </div>
                          <div class="ml-1">
                            Last Updated:
                            <small class="text-muted" style="font-size: small">{{
                              format_date(status.AntispywareSignatureLastUpdated)
                            }}</small>
                          </div>
                        </div>
                      </template>
                    </td>
                    <td>
                      <template v-for="status in agentsStatus" v-bind:key="status.id">
                        <div v-if="status.computerID == agent.computerID">
                          <div class="ml-1" v-if="status.QuickScanAge >= 0">
                            Last Scan:
                            <small class="text-muted"
                              >{{ status.QuickScanAge }} days</small
                            >
                          </div>
                          <div class="ml-1" v-else>
                            Last Scan: <small class="text-muted">+99 days</small>
                          </div>
                          <div class="ml-1">
                            Scan Date:
                            <small class="text-muted" style="font-size: small">{{
                              format_date(status.QuickScanEndTime)
                            }}</small>
                          </div>
                        </div>
                      </template>
                    </td>
                    <td>{{ myAgentLastSync(agent.computerID) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <div v-if="myAgentIsEnabled(agent.computerID)">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </div>
                      <div v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </div>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <template v-if="agent.status == 'Online'">
                          <span style="display: none">Online</span>
                        </template>
                        <template v-else>
                          <span style="display: none">Offline</span>
                        </template>
                        <AgentMenu :agent="agent" />
                      </td>
                      <td>{{ agent.computerName }}</td>
                      <td>
                        <template v-for="status in agentsStatus" v-bind:key="status.id">
                          <div v-if="status.computerID == agent.computerID">
                            <div class="ml-1">
                              Signature Version:
                              <small class="text-muted">{{
                                status.AntivirusSignatureVersion
                              }}</small>
                            </div>
                            <div class="ml-1">
                              Last Updated:
                              <small class="text-muted">
                                {{
                                  format_date(status.AntivirusSignatureLastUpdated)
                                }}</small
                              >
                            </div>
                          </div>
                        </template>
                      </td>
                      <td>
                        <template v-for="status in agentsStatus" v-bind:key="status.id">
                          <div v-if="status.computerID == agent.computerID">
                            <div class="ml-1">
                              Signature Version:
                              <small class="text-muted">{{
                                status.AntispywareSignatureVersion
                              }}</small>
                            </div>
                            <div class="ml-1">
                              Last Updated:
                              <small class="text-muted">{{
                                format_date(status.AntispywareSignatureLastUpdated)
                              }}</small>
                            </div>
                          </div>
                        </template>
                      </td>
                      <td>
                        <template v-for="status in agentsStatus" v-bind:key="status.id">
                          <div v-if="status.computerID == agent.computerID">
                            <div class="ml-1" v-if="status.QuickScanAge >= 0">
                              Last Scan:
                              <small class="text-muted"
                                >{{ status.QuickScanAge }} days</small
                              >
                            </div>
                            <div class="ml-1" v-else>
                              Last Scan: <small class="text-muted">+99 days</small>
                            </div>
                            <div class="ml-1">
                              Scan Date:
                              <small class="text-muted">{{
                                format_date(status.QuickScanEndTime)
                              }}</small>
                            </div>
                          </div>
                        </template>
                      </td>
                      <td>{{ format_date(agent.lastContact) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <div v-if="myAgentIsEnabled(agent.computerID)">
                          <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span
                          >
                        </div>
                        <div v-else>
                          <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span
                          >
                        </div>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >diabled</span
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </template>
          <template>
            <p>No agents were returned for client.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
      <!-- end Main View card -->
    </div>
  </div>
</template>

<script>
import { doClientWork } from "@/workers/defender/defender-client-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import AgentMenu from "@/components/layout/AgentMenu-Windows";
import ClientMenu from "@/components/layout/ClientMenu";
export default {
  name: "DefenderClientManager",
  components: {
    AgentMenu,
    ClientMenu,
  },
  data() {
    return {
      num_agents: 0,
      exclusionForm: {},
      agentsConfigs: [],
      agentStatus: [],
      errors: [],
      clientConfig: {},
      clientExclusions: [],
      client: {},
      clients: [],
      agents: [],
      selected: [],
      selectAll: false,
      selectExcled: [],
      selectExclAll: false,
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getClients();
    this.getAgentsStatus();
    this.getConfig();
    this.getExclusions();
  },
  methods: {
    myAgentIsEnabled(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isEnabled;
        }
      }
    },
    myAgentLastSync(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.format_date(this.agentsConfigs[i].lastSync);
        }
      }
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    selectExcl() {
      this.selectExcled = [];
      if (!this.selectExclAll) {
        for (let i in this.clientExclusions) {
          this.selectExcled.push(this.clientExclusions[i].id);
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    // Get Client Data function
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

    // Get Team data
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    // Get client exclusions Functions

    async getConfig() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_defender_client_config/${clientID}/`)
        .then((response) => {
          this.clientConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get client exclusions Functions

    async getExclusions() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_client_exclusions/${clientID}/`)
        .then((response) => {
          this.clientExclusions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async refreshAgentsStatus() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agents_status_by_client/${clientID}/`)
        .then((response) => {
          this.agentsStatus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await axios
        .get(`/api/v1/defender/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //get agent statuses
    async getAgentsStatus() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agents_status_by_client/${clientID}/`)
        .then((response) => {
          this.agentsStatus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      // get agent configs
      await axios
        .get(`/api/v1/defender/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      // get Agents
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      setTimeout(function () {
        $("#clientDefenderTable").DataTable({
          pagingType: "full_numbers",
          pageLength: 25,
          processing: true,
          dom:
            "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
            "<'row'<'col-sm-12'B>>",
          lengthMenu: [
            [25, 50, 100, -1],
            [25, 50, 100, "All"],
          ],
          buttons: ["copy", "csv", "print"],
        });
      }, 100);
      this.$store.commit("setIsLoading", false);
    },

    // Enable Agents Function
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/defender/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgentsStatus();

            toast({
              message: "Defender status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);
      this.clientConfig.clientID = this.$route.params.id;

      await axios
        .post("/api/v1/defender/edit_defender_client_config/", this.clientConfig)
        .then((response) => {
          //console.log(response.data);
          toast({
            message: "Defender client configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Submit exclusions Form Function
    async submitExclusionsForm() {
      this.$store.commit("setIsLoading", true);
      this.exclusionForm.clientID = this.$route.params.id;
      if (!this.exclusionForm.isFile == true) {
        this.exclusionForm.isFile = false;
      }
      await axios
        .post("/api/v1/defender/add_client_exclusion/", this.exclusionForm)
        .then((response) => {
          //console.log(response.data);
          this.getExclusions();
          toast({
            message: "Defender client exclusions updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async deleteExclusions() {
      this.errors = [];
      if (!this.selectExcled.length) {
        this.errors.push(
          "You need to select 1 or more exclusions from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const clientID = this.$route.params.id;
        await axios
          .post(
            `/api/v1/defender/delete_client_exclusion/${clientID}/`,
            this.selectExcled
          )
          .then((response) => {
            //console.log(response.data);
            this.getExclusions();
            this.selectExcled = [];
            this.selectExclAll = false;
            toast({
              message: "Defender client exclusions deleted successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.$store.commit("setIsLoading", false);
    },
    async runQuickScan() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const clientID = this.$route.params.id.toString();
        const URL = axios.defaults.baseURL;
        const agents = this.selected.toString();
        await doClientWork(token, "runQuickScan", clientID, URL, agents);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Defender running quick scan sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    async runFullScan() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const clientID = this.$route.params.id.toString();
        const URL = axios.defaults.baseURL;
        const agents = this.selected.toString();

        await doClientWork(token, "runFullScan", clientID, URL, agents);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Defender running full scan sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    async pushExclusions() {
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      const clientID = this.$route.params.id.toString();
      const URL = axios.defaults.baseURL;
      const agents = "";
      await doClientWork(token, "pushExclusions", clientID, URL, agents);
      toast({
        message: "Defender sending exclusions to local agents successfull",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

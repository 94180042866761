<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-8">
        <h1 class="title">Clients</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <router-link
            to="/dashboard/all_agents"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Show All
            Agents</router-link
          >          
          <button class="button is-success" @click="enableClients">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
          <button class="button is-danger" @click="purgeClients">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Purge Clients
          </button>
        </div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <template v-if="team.hideDisabledClients === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span>
              <span class="option"><img class="" src="/images/green_check.png" /></span>
            </div>
          </template>
          <template v-else>            
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span>
              <span class="option"><img class="" src="/images/red_x.png" /></span>
            </div>
          </template>
        </div>
      </div>
      <div class="column is-12">
        <div class="card card-body">
          <template v-if="clients.length">
            <table class="table is-fullwidth" id="clientTable">
              <thead>
                <tr>
                  <th style="width: 12px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 15px"></th>
                  <th>Company [{{ num_clients }}]</th>
                  <th>
                    [{{ num_agents }}] Agents, [{{ enabledAgents() }} /
                    {{ $store.state.team.max_agents }}] Enabled
                  </th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledClients === false">
                  <tr v-for="client in clients" v-bind:key="client.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="client.clientID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <ClientMenu :client="client" />
                    </td>
                    <td>{{ client.clientName }}</td>
                    <td>
                      [<strong>{{ agentCount(client.clientID) }}</strong
                      >] Agents, [<strong>{{ enabledAgentCount(client.clientID) }}</strong
                      >] Enabled
                    </td>
                    <td v-if="client.isEnabled === true">
                      <img src="/images/green_check.png" /><span style="display: none"
                        >enabled</span
                      >
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="client in clients" v-bind:key="client.id">
                    <tr v-if="client.isEnabled === true" v-bind:key="client.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="client.clientID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <ClientMenu :client="client" />
                      </td>
                      <td>{{ client.clientName }}</td>
                      <td>
                        [<strong>{{ agentCount(client.clientID) }}</strong
                        >] Agents, [<strong>{{
                          enabledAgentCount(client.clientID)
                        }}</strong
                        >] Enabled
                      </td>
                      <td v-if="client.isEnabled === true">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </td>
                    </tr></template
                  >
                </template>
              </tbody>
            </table>
          </template>
          <template v-else><p>No clients have been assigned to you.</p></template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ClientWorker } from "@/workers/clientworker/client-worker-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import axios from "axios";
import { toast } from "bulma-toast";
import ClientMenu from "@/components/layout/ClientMenu";
export default {
  name: "Clients",
  components: {
    ClientMenu,
  },
  data() {
    return {
      num_agents: 0,
      agents: [],
      errors: [],
      team: {
        members: [],
        sites: [],
        created_by: {},
      },
      clients: [],
      selected: [],
      selectAll: false,
      showNextButton: false,
      showPreviousButton: false,
      currentPage: 1,
      query: "",
      num_clients: 0,
    };
  },
  mounted() {
    this.getTeam();
    this.getClients();
    this.getAgents();
  },
  methods: {
    submitForm() {
      this.getClients();
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.clients) {
          this.selected.push(this.clients[i].clientID);
        }
      }
    },
    agentCount(clientID) {
      let c = 0;
      for (let i in this.agents) {
        if (this.agents[i].clientID == clientID) {
          c = c + 1;
        }
      }
      return c;
    },
    enabledAgentCount(clientID) {
      let c = 0;
      for (let i in this.agents) {
        if (this.agents[i].clientID == clientID && this.agents[i].isEnabled == true) {
          c = c + 1;
        }
      }
      return c;
    },
    enabledAgents() {
      let c = 0;
      for (let i in this.agents) {
        if (this.agents[i].isEnabled == true) {
          c = c + 1;
        }
      }
      return c;
    },
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/agents/`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async purgeClients() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more clients from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/rmm/purge/clients/`, this.selected)
          .then((response) => {
            this.clients = [];
            this.selected = [];
            this.selectAll = false;
            toast({
              message: "Purged client data completed successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            console.log(error);
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      this.clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/teams/get_my_team/`)
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.num_clients = response.data.length;
          this.clients = response.data;
          setTimeout(function () {
            $("#clientTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async refreshClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.num_clients = response.data.length;
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async enableClients() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/clients/enable_clients_by_client_id/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshClients();

            toast({
              message: "Client status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async pushManagementPackageToClient() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clients: this.selected,
          axiosBaseURL: axios.defaults.baseURL,
        };
        await ClientWorker(token, "pushManagementPackageToClient", CMDDATA);
        this.selected = [];
        toast({
          message: "RMMMax management package has been queued.",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

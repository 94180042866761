<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import axios from "axios";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
export default {
  name: "DashboardBar2",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "URLs Requested",
            data: [],
            fill: false,
            axis: "x",
            borderWidth: 1,
            backgroundColor: [
              "rgba(54, 162, 235, 0.4)",
              "rgba(153, 102, 255, 0.4)",
              "rgba(201, 203, 207, 0.4)",
              "rgba(255, 99, 132, 0.4)",
              "rgba(255, 159, 64, 0.4)",
              "rgba(255, 205, 86, 0.4)",
              "rgba(75, 192, 192, 0.4)",
            ],
            borderColor: [
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
            ],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    async getStats() {
      this.$store.commit("setIsLoading", true);
      this.chartData.labels[0] = [];
      this.chartData.datasets[0].data[0] = [];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      await axios
        .get(`/api/v1/stats/surflog/get_surflog_count_history/`)
        .then((response) => {
          for (let i in response.data) {
            this.chartData.datasets[0].data.push(response.data[i].urlCount);
            let d = new Date(response.data[i].statDate);
            let month = months[d.getMonth()];
            this.chartData.labels.push(month);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

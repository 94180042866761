import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    isAuthenticated: false,
    token: '',
    user:{
      id: 0,
      username: '',
    },
    team:{
    id: 0,
    name: '',
    plan: '',
    max_agents: 0,
    max_clients: 0,
    rmmHost: '',
    apiType: '',
    apiURL: '',
    apiUser: '',
    apiPass: '',
    apiToken: '',
    apiSecret: '',
    }
  },

  getters: {
  },
  mutations: {
    initializeStore(state){
      if (localStorage.getItem('token')){
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        state.user.username = localStorage.getItem('username')
        state.user.id = localStorage.getItem('userid')
        state.team.name = localStorage.getItem('team_name')
        state.team.id = localStorage.getItem('team_id')
        state.team.plan = localStorage.getItem('team_plan')           
        state.team.max_agents = localStorage.getItem('team_max_agents')  
        state.team.max_clients = localStorage.getItem('team_max_clients')  
        state.team.rmmHost = localStorage.getItem('team_rmmHost')
        state.team.apiType = localStorage.getItem('team_apiType')
        state.team.apiURL = localStorage.getItem('team_apiURL')
        state.team.apiUser = localStorage.getItem('team_apiUser')
        state.team.apiPass = localStorage.getItem('team_apiPass')
        state.team.apiToken = localStorage.getItem('team_apiToken')
        state.team.apiSecret = localStorage.getItem('team_apiSecret')
      }else{
        state.token = ''
        state.isAuthenticated = false
        state.user.id = 0
        state.user.username = ''
        state.team.id = 0
        state.team.name = ''
        state.team.plan = ''          
        state.team.max_agents = 0
        state.team.max_clients = 0
        state.team.rmmHost = ''
        state.team.apiType = ''
        state.team.apiURL = ''
        state.team.apiUser = ''
        state.team.apiPass = ''
        state.team.apiToken = ''
        state.team.apiSecret = ''
      }
    },
    setIsLoading(state,status){
      state.isLoading = status
    },
    setToken(state,token){
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state){
      state.token = ''
      state.isAuthenticated = false
    },
    setUser(state, user) {
        state.user = user
    },
    setTeam(state, team){
      state.team = team
      localStorage.setItem('team_id', team.id)
      localStorage.setItem('team_name', team.name)
      localStorage.setItem('team_plan', team.plan)
      localStorage.setItem('team_max_agents', team.max_agents)
      localStorage.setItem('team_max_clients', team.max_clients)
      localStorage.setItem('team_rmmHost', team.rmmHost)
      localStorage.setItem('team_apiType', team.apiType)
      localStorage.setItem('team_apiURL', team.apiURL)
      localStorage.setItem('team_apiUser', team.apiUser)
      localStorage.setItem('team_apiPass', team.apiPass)
      localStorage.setItem('team_apiToken', team.apiToken)
      localStorage.setItem('team_apiSecret', team.apiSecret)
    },
  },
  actions: {
  },
  modules: {
  }
})

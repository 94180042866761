<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-8 is-offset-2">
        <div class="box">
          <h1 class="title">Welcome Team Leader</h1>
          <p class="mt-3">
            We wanted to welcome you as the team leader of your organization to RMMMax.
            Upon a successfull login you will be presented a team setup page. If you need
            assistance with the setup you will find help in our Help Center. Reach out to
            the RMMMax team anytime via the Help Center, we are here to help.
          </p>
          <p>
            If you have any feedback or suggestions, please send them to sales@RMMMax.com
            Thank you for your support and trust in us.
          </p>
          <p class="mt-3">
            But first, we have an confirmation email on the way to you, please check your
            mailbox for the RMMMax.com Activation email.
          </p>
          <p class="mt-3">
            Click the link in the email to complete your account activation.
          </p>
          <h2 class="subtitle mt-3">Best regards, RMMMax Team</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SignupThankyou",

  methods: {},
};
</script>

<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData1"
    :chart-id="chartId1"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Bar } from "vue-chartjs";
import axios from "axios";
import {
  Chart as ChartJS1,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS1.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
export default {
  name: "SurfLogAgentUsageByHourBar",
  components: {
    Bar,
  },
  props: {
    chartId11: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    computerID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chartData1: {
        labels: [
          "12am",
          "1am",
          "2am",
          "3am",
          "4am",
          "5am",
          "6am",
          "7am",
          "8am",
          "9am",
          "10am",
          "11am",
          "12pm",
          "1pm",
          "2pm",
          "3pm",
          "4pm",
          "5pm",
          "6pm",
          "7pm",
          "8pm",
          "9pm",
          "10pm",
          "11pm",
        ],
        datasets: [
          {
            data: [],
            fill: false,
            axis: "x",
            borderWidth: 1,
            label: "Web Usage By Hour Of Day",
            backgroundColor: [
              "rgba(255, 99, 132, 0.4)",
              "rgba(255, 159, 64, 0.4)",
              "rgba(255, 205, 86, 0.4)",
              "rgba(75, 192, 192, 0.4)",
              "rgba(54, 162, 235, 0.4)",
              "rgba(153, 102, 255, 0.4)",
              "rgba(201, 203, 207, 0.4)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  mounted() {
    this.getAgentLogs();
  },

  methods: {
    async getAgentLogs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.computerID;
      await axios
        .get(`/api/v1/surflog/get_surflog_agent_usage_by_hour/${computerID}/`)
        .then((response) => {
          this.chartData1.datasets[0].data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">

      <div class="column is-4 is-offset-2">
        <h1 class="title mt-5">Member Login</h1>
        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Email</label>
            <div class="control">
              <input type="email" name="email" class="input" v-model="username" />
            </div>
          </div>
          <div class="field">
            <label>Password</label>
            <div class="control">
              <input type="password" name="password" class="input" v-model="password" />
            </div>
          </div>
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
          <div class="field">
            <div class="control">
              <div class="buttons">
                <button class="button is-success mt-2">Submit</button>
              </div>
            </div>
          </div>
        </form>
        </div>
        <div class="column is-5 is-offset-1">

<div class="mt-5">
<h1 class="subtitle"><strong>Are you new to the RMMmax family?</strong></h1>
<p class="mb-3">Sign up today and unlock the full potential of our feature-rich platform for your first 15 agents at absolutely no cost. We can't wait to welcome you to our community of empowered MSPs!</p>
<router-link
                  to="/sign-up"
                  class="button is-success"
                  style="text-decoration: none"
                >
                  <strong> Sign Up </strong>
                </router-link>
    </div>


        
        <h2 class="subtitle mt-5">Forgot Password?</h2><p class="mt-3">Place your email in the form above and select to reset password. This will cause an email to be sent to the address provided if it has an account with instructions to reset password.

        </p>
        <div class="buttons mt-4">
        <button class="button is-warning" @click="resetPassword">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Reset Password
          </button>
          <button class="button is-warning" @click="resendActivation">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Resend Activation
          </button>
      </div></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import { doHardWork } from "@/workers/rmmsync/worker-api";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errors: [],
    };
  },
  methods: {
    //submit login form
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      let myTeam = {};
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      this.errors = [];
      if (this.username === "") {
        this.errors.push("Username is missing");
        this.$store.commit("setIsLoading", false);
      }
      if (this.password === "") {
        this.errors.push("Password is to short");
        this.$store.commit("setIsLoading", false);
      }
      if (!this.errors.length) {
        const formData = {
          username: this.username,
          password: this.password,
        };

        await axios
          .post("/api/v1/token/login/", formData)
          .then((response) => {
            const token = response.data.auth_token;
            this.$store.commit("setToken", token);
            axios.defaults.headers.common["Authorization"] = "Token " + token;
            localStorage.setItem("token", token);

          })
          .catch((error) => {
            if (error.response) {
              this.errors.push(error.response.data);
              this.$store.commit("setIsLoading", false);

            } else if (this.errors.message) {
              this.errors.push("Something went wrong, please try again.");
              this.$store.commit("setIsLoading", false);
            }
          });

        await axios
          .get("/api/v1/users/me")
          .then((response) => {
            this.$store.commit("setUser", {
              id: response.data.id,
              username: response.data.username,
            });
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("userid", response.data.id);
          })
          .catch((error) => {
            //console.log(error);
          });

        await axios
          .get("/api/v1/teams/get_my_team/")
          .then((response) => {
            //console.log(response.data);
            myTeam = response.data;

            this.$store.commit("setTeam", {
              id: response.data.id,
              username: response.data.name,
              rmmHost: response.data.rmmHost,
              apiType: response.data.apiType,
              apiURL: response.data.apiURL,
              apiUser: response.data.apiUser,
              apiPass: response.data.apiPass,
              apiToken: response.data.apiToken,
              plan: response.data.plan.name,
              max_agents: response.data.plan.max_agents,
              max_clients: response.data.plan.max_clients,
            });
            axios
              .get("/api/v1/logs/login_user_access/")
              .then((response) => {
                
              })
              .catch((error) => {
                //console.log(error);
              });
              // Create Base64 Object
              var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}};
              const encodedString = Base64.encode(this.password);

              axios
              .get(`/api/v1/sso/update/${encodedString}/`)
              .then((response) => {
               
              })
              .catch((error) => {
                //console.log(error);
              });
          })
          .catch((error) => {
            //console.log(error);
          });
//MFA tests here
        if (myTeam.mfa == true) {
          await axios
          .get("/api/v1/team/send_mfa/")
          .then((response) => {
            const aNumber = Number(window.prompt("A code has been emailed to you, input that code here.", ""));
            console.log(aNumber.toString().length)
            if(aNumber.toString().length == 6){
              axios
              .get(`/api/v1/team/my_mfa/${aNumber}/`)
              .then((response) => {
                console.log("Successfull send of MFA - " + response.status);
                if(response.status !== 200){
                  axios
                  .post("/api/v1/token/logout")
                  .then((response) => {
                  console.log("Failed MFA, User Logged Out");
                  axios.defaults.headers.common["Authorization"] = "";
                  localStorage.removeItem("token");
                  this.$store.commit("removeToken");
                  this.$router.push("/Login");
                  })
                  .catch((error) => {
                    console.log(JSON.stringify(error));
                    axios.defaults.headers.common["Authorization"] = "";
                    localStorage.removeItem("token");
                    this.$store.commit("removeToken");
                    this.$router.push("/Login");
                  });
                }
              })
              .catch((error) => {
                  console.log("Error on validating MFA - " + error);
                  axios
                  .post("/api/v1/token/logout")
                  .then((response) => {
                  console.log("Logged out");
                  })
                  .catch((error) => {
                    console.log(JSON.stringify(error));
                  });
                  axios.defaults.headers.common["Authorization"] = "";
                  localStorage.removeItem("token");
                  this.$store.commit("removeToken");
                  this.$router.push("/Login");
              });
            
            }else{
              console.log("Failed on validating MFA");
                  axios
                  .post("/api/v1/token/logout")
                  .then((response) => {
                  console.log("Logged out");
                  })
                  .catch((error) => {
                    console.log(JSON.stringify(error));
                  });
                  axios.defaults.headers.common["Authorization"] = "";
                  localStorage.removeItem("token");
                  this.$store.commit("removeToken");
                  this.$router.push("/Login");
            }
          })
          .catch((error) => {
            toast({
              message: "MFA Login failed to fetch code",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
                console.log("Error on requesting MFA - " + error);
                axios
                .post("/api/v1/token/logout")
                .then((response) => {
                console.log("Logged out");
                })
                .catch((error) => {
                  console.log(JSON.stringify(error));
                });
                axios.defaults.headers.common["Authorization"] = "";
                localStorage.removeItem("token");
                this.$store.commit("removeToken");
                this.$router.push("/Login");

          });
          
        }

        if (myTeam.apiURL !== "") {
          const token = localStorage.getItem("token");
          await doHardWork(token, "rmmSync", axios.defaults.baseURL);
        }
        this.$router.push("/dashboard");
        this.$store.commit("setIsLoading", false);
        this.$forceUpdate();
      }
    },
    //reset Password
    async resetPassword() {
      this.$store.commit("setIsLoading", true);
      let myTeam = {};
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      this.errors = [];
      if (this.username === "") {
        this.errors.push(
          "Email address is missing, please provide the users email address "
        );
        this.$store.commit("setIsLoading", false);
      }
      const email = {email:this.username};
      await axios
        .post("/api/v1/users/reset_password/", email)
        .then((response) => {
          this.$store.commit("setIsLoading", false);
          toast({
            message: "Password reset email has been sent to user",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
            this.errors.push("Something went wrong, please try again.");
            this.$store.commit("setIsLoading", false);
        });
    },
        //resend Activation email
    async resendActivation() {
      this.$store.commit("setIsLoading", true);
      let myTeam = {};
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      this.errors = [];
      if (this.username === "") {
        this.errors.push(
          "Email address is missing, please provide the users email address "
        );
        this.$store.commit("setIsLoading", false);
      }
      const email = {email:this.username};
      await axios
        .post("/api/v1/users/resend_activation/", email)
        .then((response) => {


        })
        .catch((error) => {});          
      this.$store.commit("setIsLoading", false);
      toast({
          message: "Re-activation email has been sent to user",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
          });        
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">Surflog Client Manager for {{ client.clientName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>
          <router-link
            to="/dashboard/clients"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#clientConfigParams"
            aria-expanded="false"
            aria-controls="clientConfigParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#clientSurflogCharts"
            aria-expanded="false"
            aria-controls="clientSurflogCharts"
          >
            <va-icon class="icon ion-md-analytics mr-1"></va-icon> Charts
          </button>
          <router-link
            :to="{
              name: 'SurfLogClientRawLogs',
              params: { id: this.$route.params.id, agents: this.agents },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-list mr-1"></va-icon> Raw Logs</router-link
          >

          <button class="button is-success" @click="scanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan Client
          </button>

          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
        </div>
      </div>
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"><strong>Last Sync:</strong></span>
            <span class="option">
              <template v-if="checkDateWithin2Days(clientConfig.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"><strong>Viewing All Agents:</strong></span>
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"><strong>Viewing All Agents:</strong></span>
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Service Logs:</strong></p></span>
              <span class="option">
                <router-link
                  class=""
                  style="text-decoration: none"
                  :to="{
                    name: 'LogViewer',
                    params: {
                      logname: 'SurfLog',
                      id: client.clientID,
                    },
                  }"><va-icon class="icon ion-md-today" style="color:darkgrey;"></va-icon></router-link>
              </span>
            </div>
        </div>
      </div>

      <!-- start Settings dropdown -->
      <div class="column is-12">
        <div class="collapse" id="clientConfigParams">
          <div class="card card-body">
            <h1 class="subtitle">Settings</h1>
            <p class="mb-5">
              This is the Surflog parameters area that will control how agents use Surflog
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.isEnabled"
                    />Enable Client Scans</label
                  >
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.emailReport"
                    />Email Scan Reports</label
                  >
                </div>
              </div>
              <div class="field">
                <label>Retention Days</label>
                <div class="control">
                  <div class="select">
                    <select v-model="clientConfig.retentionDays">
                      <option value="7">7</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-success"
                    data-bs-toggle="collapse"
                    data-bs-target="#clientConfigParams"
                    aria-expanded="false"
                    aria-controls="clientConfigParams"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Settings -->
      <!-- start Charts dropdown -->
      <div class="column is-12">
        <div class="collapse" id="clientSurflogCharts">
          <div class="card card-body">
            <h1 class="subtitle">Client History Charts</h1>
            <div class="chartcontainer">
              <div class="BrowserTypes">
                <div class="box mr-3">
                  <h2 class="subtitle mt-3">
                    <va-icon class="icon ion-md-analytics"></va-icon> Browser Usage
                  </h2>
                  <SurfLogClientBrowserTypesPie :clientID="this.$route.params.id" />
                </div>
              </div>
              <div class="UsageByHour">
                <div class="box">
                  <h2 class="subtitle mt-3">
                    <va-icon class="icon ion-md-analytics"></va-icon> Hourly Usage
                  </h2>
                  <SurfLogClientUsageByHourBar :clientID="this.$route.params.id" />
                </div>
              </div>
              <div class="ProtocolTypes">
                <div class="box mr-3">
                  <h2 class="subtitle mt-3">
                    <va-icon class="icon ion-md-analytics"></va-icon> Protocol Usage
                  </h2>
                  <SurfLogClientProtocolTypesPie :clientID="this.$route.params.id" />
                </div>
              </div>
              <div class="UsageByDay">
                <div class="box">
                  <h2 class="subtitle mt-3">
                    <va-icon class="icon ion-md-analytics"></va-icon> Daily Usage
                  </h2>
                  <SurfLogClientUsageByDayBar :clientID="this.$route.params.id" />
                </div>
              </div>
              <div class="UsageByProfile">
                <div class="box">
                  <h2 class="subtitle mt-3">
                    <va-icon class="icon ion-md-analytics"></va-icon> Top 10 Profiles By
                    Usage
                  </h2>
                  <SurfLogClientUsageByProfileBar :clientID="this.$route.params.id" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Charts Drop down -->

      <!-- Main View agents -->

      <div class="column is-12">
        <div class="card card-body">
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="surflogAgents">
              <thead>
                <tr>
                  <th style="width: 20px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 30px"><va-icon class="icon ion-md-apps"></va-icon></th>
                  <th>Agent Name</th>
                  <th>OS</th>
                  <th>Logs</th>
                  <th>Last Scan</th>
                  <th scope="col">Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                      <AgentMenu :agent="agent" />
                    </td>
                    <td>{{ agent.computerName }}</td>
                    <td>{{ agent.OS }}</td>
                    <td>{{ myLogCount(agent.computerID) }}</td>
                    <td>{{ myAgentLastScan(agent.computerID) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <div v-if="myAgentIsEnabled(agent.computerID)">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </div>
                      <div v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </div>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                          <AgentMenu :agent="agent" />
                      </td>
                      <td>{{ agent.computerName }}</td>
                      <td>{{ agent.OS }}</td>
                      <td>{{ myLogCount(agent.computerID) }}</td>
                      <td>{{ myAgentLastScan(agent.computerID) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <div v-if="myAgentIsEnabled(agent.computerID)">
                          <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span
                          >
                        </div>
                        <div v-else>
                          <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span
                          >
                        </div>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </template>
          <template>
            <p>No agents were returned for client.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
      <!-- end Main View card -->
    </div>
  </div>
</template>

<script>
import { doClientWork } from "@/workers/surflog/surflog-client-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import SurfLogClientBrowserTypesPie from "@/components/charts/surflog/SurfLogClientBrowserTypes.vue";
import SurfLogClientUsageByHourBar from "@/components/charts/surflog/SurfLogClientUsageByHour.vue";
import SurfLogClientProtocolTypesPie from "@/components/charts/surflog/SurfLogClientProtocolTypes.vue";
import SurfLogClientUsageByDayBar from "@/components/charts/surflog/SurfLogClientUsageByDay.vue";
import SurfLogClientUsageByProfileBar from "@/components/charts/surflog/SurfLogClientUsageByProfile.vue";
import AgentMenu from "@/components/layout/AgentMenu-Windows";
import ClientMenu from "@/components/layout/ClientMenu";

export default {
  name: "SurfLogClientManager",
  components: {
    SurfLogClientBrowserTypesPie,
    SurfLogClientUsageByHourBar,
    SurfLogClientProtocolTypesPie,
    SurfLogClientUsageByDayBar,
    SurfLogClientUsageByProfileBar,
    AgentMenu,
    ClientMenu,
  },
  data() {
    return {
      rawDataColumns: [
        { label: "Browser Type", field: "browserType", numeric: false, html: false },
        { label: "URL", field: "URL", numeric: false, html: false },
        { label: "Title", field: "title", numeric: false, html: false },
        { label: "Date", field: "visitTime", numeric: false, html: false },
        { label: "User Profile", field: "userProfile", numeric: false, html: false },
        { label: "Duration", field: "visitDuration", numeric: false, html: false },
        { label: "Visits", field: "visitCount", numeric: true, html: false },
      ],
      num_agents: 0,
      agentsConfigs: [],
      clientLogs: [],
      errors: [],
      clientConfig: {},
      client: {},
      clients: [],
      agents: [],
      selected: [],
      selectAll: false,
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getClient();
    this.getClients();
    this.getAgents();
    this.getConfig();
    this.getClientLogs();
    this.getAgentsConfigs();
  },
  methods: {
    myLogCount(computerID) {
      for (let i in this.clientLogs) {
        if (this.clientLogs[i].computerID === computerID) {
          return this.clientLogs[i].total;
        }
      }
      return 0;
    },
    myAgentName(computerID) {
      for (let i in this.agents) {
        if (this.agents[i].computerID === computerID) {
          return this.agents[i].computerName;
        }
      }
    },
    myAgentIsEnabled(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isEnabled;
        }
      }
    },
    myAgentLastScan(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.format_date(this.agentsConfigs[i].lastSync);
        }
      }
      return "Unscanned";
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClient() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getConfig() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_surflog_client_config/${clientID}/`)
        .then((response) => {
          this.clientConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Configs  function
    async getAgentsConfigs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          console.log(response.data);
          this.agentsConfigs = response.data;
          setTimeout(function () {
            $("#surflogAgents").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Refresh Agents Configs  function
    async refreshAgentsConfigs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          console.log(response.data);
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Enable Agents Function
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/surflog/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgentsConfigs();

            toast({
              message: "Surflog status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Purge Agents logs Function
    async purgeWebLogs() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/surflog/purge/weblogs/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.clientLogs = [];

            toast({
              message: "Purge of SurfLogs for selected agents was successful",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Scan Client Function

    async scanClient() {
      this.errors = [];
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const clientID = this.$route.params.id.toString();
        const URL = axios.defaults.baseURL;
        const agents = "";
        await doClientWork(token, "scanClient",  clientID, URL, agents);
        toast({
          message: "Surflog client scan started successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },

    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);
      this.clientConfig.clientID = this.$route.params.id;
      await axios
        .post("/api/v1/surflog/edit_surflog_client_config/", this.clientConfig)
        .then((response) => {
          toast({
            message: "Surflog client configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClientLogs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_surflogs_client_count/${clientID}/`)
        .then((response) => {
          console.log(response.data);
          this.clientLogs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
      </div>
      <div class="column is-10 is-offset-1">
        <div class="card card-body">
          <h1 class="title">RMM Environment</h1>
          <p  class="mb-3">
            RMMMax has settings that require modification to facilitate automated communications between the RMM and RMMMax. Ensure that "Enable Daily Sync" is activated to allow RMMMax to retrieve your client and agent information from the RMM environment.
            </p><p class="mb-3">
              You have the option to conceal disabled agents and clients from your views. However, it is imperative to first enable the agents and clients you wish to view before activating this mode. Remember to deactivate this mode to enable any new clients or agents manually. This does not impeed the ability of preconfigured onboarding presets.
            </p><p class="mb-3">
              The system can send email notifications to you regarding the actions it is executing on your RMM. Activate the email service and then specify the types of emails you wish to receive from the available "Notifications" menu list.
            </p>
              <template v-if="team.apiType != 'Datto'">
              <P>
              RMMmax uses powershell and bash scripting to assist in the management of each tool at the agent level. The "Deploy Management Packages (Non Datto MSPs)" is an automated service designed to push out the RMMMax management package to all online and enabled agents on RMMMax. This package must be deployed before initiating any tool syncing. 
              </p></template>
              <template v-else><p>For Datto users, you must acquire the RMMmax components from the Datto ComStore and import them into the Datto environment, Currently eight components are available on the Datto Com Store for RMMmax, you will need all eight.</p></template>
          
          <h1 class="subtitle mt-6">Enable Onboarding Presets</h1>
          <p class="mb-4"><strong>How would you like to handle new clients?</strong><br><br>
          By establishing client defaults proactively, we can govern the desired approach for RMMmax in handling incoming (new) clients. By default, the standard procedure entails the manual configuration of any new client before the deployment of RMMmax tools and services. If this "disabled by default" configuration is satisfactory, no further preset configurations are required. However, for those seeking an automated onboarding process with RMMmax tools, this is the designated interface for configuring presets to ensure the seamless activation of clients, agents and associated services.
        <br></p>        
          <div class="buttons mb-3">
          <router-link
            to="/dashboard/client"
            class="button is-success"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Set Client and Agent Default Settings</router-link
          ></div>
          <h1 class="subtitle mt-6">Enable Basic Communications</h1>
          <p class="mb-5">
            Use the settings below to turn on or off the basic communications between the
            RMM environment and RMMMax. When disabled all automation for RMM or tools will
            stop and no data will be collected. Think of these settings as the master switch for all RMMmax to RMM interactions.
          </p>

          <form @submit.prevent="submitRMMEnvironmentForm" class="row g-4">
            <div class="col-auto">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="team.rmmSync"
                    />Enable Daily RMM Sync</label
                  >
                </div>
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="team.rmmSyncEmail"
                    />Send Notification Email</label
                  >
                </div>
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="team.toolAutomation"
                    />Allow Tool Automation</label
                  >
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="team.hideDisabledClients"
                    />Hide Disabled Clients</label
                  >
                </div>
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="team.hideDisabledAgents"
                    />Hide Disabled Agents</label
                  >
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="field">
                <div class="control"></div>
                <div class="control"></div>
                <div class="control"></div>
                <div class="control">
                  <button class="button is-success">Save</button>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <template v-if="team.apiType !== 'Datto'">
            <div class="mt-3">
              <h1 class="subtitle mt-3">Deploy The Management Package</h1>
              <p>
                The management package is a small set of powershell scripts that get
                placed onto the local agent. These scripts allow RMMMax to do more complex
                tasks by using these scripts during queries and command functions. This
                package should be distributed to all agents enabled for management. RMMMax
                will attempt to install to all online agents and you can deploy as often
                as you need to to capture online agents previously offline.
              </p>
              <p>
                Make sure to enable your clients and agents first, afterwards visit this
                page and select the "Push To Enabled Agents" button to have RMMMax push
                the deployment of the management package to each online and enabled agent.
                You may need to revisit this control if RMMMax versions change to
                distribute updated managemnt packages.
              </p>
              <div class="buttons mt-3">
                <button class="button is-success mt-2" @click="getManagementPackage">
                  <va-icon class="icon ion-md-cloud-download mr-1"></va-icon> Get
                  Management Package
                  {{ MPVersion }}
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mt-3">
              <h1 class="subtitle mt-3">Need To Deploy Datto Components?</h1>
              <p>
                Datto components are the key to integrating with the rmm environment.
                Before RMMMax can start managing agents within Datto you will need to
                import each component, currently 8 components from the Datto ComStore. These
                components provide the functionality to get the data from the agent to
                RMMMax for each tool enabled. For backwards compatibility we also have a ZIP file with all the current components. Uses the download only if instructed to do so, otherwise search the Datto ComStore for RMMmax.

              </p>
              <div class="buttons mt-3">
                <button class="button is-success mt-2" @click.prevent="downloadItem()">
                  <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
                  Download Datto Components {{ DattoVersion }}
                </button>
              </div>
            </div>
          </template>
          <template v-if="team.apiType == 'TacticalRMM'">
            <hr />
            <div class="mt-3">
              <h1 class="subtitle mt-3">Deploy Tactical URL Actions</h1>
              <p>
                TacticalRMM provides the ability to add URL Actions that can launch
                different RMMmax tools from within the Tactical Web Console. This allows
                the user to rapidly access the tools in RMMmax for a given client or
                agent. This control will place the URL actions entries into the Tactical
                RMM database adding client and agent actions to tactical. For this
                automated process to work correctly the API user account you use for
                RMMmax access must have "core -> edit global settings" permissions set for
                the role assigned API user.
              </p>
              <div class="buttons mt-3">
                <button class="button is-success mt-2" @click.prevent="setUrlAction()">
                  <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
                  Deploy URL Actions
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import axios from "axios";
import { doHardWork } from "@/workers/rmmsync/worker-api";
export default {
  name: "RMMEnvironment",
  data() {
    return {
      errors: [],
      MPVersion: "",
      DattoVersion: "",
      tools: [],
      team: {
        members: [],
        created_by: {},
      },
      notify: {},
    };
  },
  mounted() {
    this.getTeam();
    this.getTools();
  },
  methods: {
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    getMPVersion() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "management package") {
          this.MPVersion = this.tools[i].version;
        }
      }
    },
    getDattoVersion() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "dattopackagezip") {
          this.DattoVersion = this.tools[i].version;
        }
      }
    },
    async getTools() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/motd/get_tool_versions/")
        .then((response) => {
          this.tools = response.data;
          this.getMPVersion();
          this.getDattoVersion();
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async submitRMMEnvironmentForm() {
      this.errors = [];
      if (this.team.rmmSync === undefined) {
        this.team.rmmSync = false;
      }
      if (this.team.rmmSyncEmail === undefined) {
        this.team.rmmSyncEmail = false;
      }
      if (this.team.toolAutomation === undefined) {
        this.team.toolAutomation = false;
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/teams/edit_team_rmm_environment/", this.team)
          .then((response) => {

            toast({
              message: "RMM environment updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.team = response.data;
            if (this.team.rmmSync == true) {
              this.syncRMM();
            }
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async getManagementPackage() {
      this.errors = [];
      if (!this.errors.length) {
        const token = localStorage.getItem("token");
        await doHardWork(token, "getManagementPackage", axios.defaults.baseURL);
        toast({
          message: "RMMMax management package has been queued.",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
      }
    },
    // Download zip files
    async downloadItem() {
      const url = "https://console.rmmmax.com/files/RMMMax-DattoRMM-Components.zip";
      await axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Datto_Components_Zip";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    // Set Tactical URL Actions
    async setUrlAction() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      await doHardWork(token, "setURLAction", axios.defaults.baseURL);
      toast({
                message: "RMM environments URL Actions update sent successfully",
                type: "is-success",
                dismissible: true,
                pauseOnHover: true,
                duration: 3000,
                position: "bottom-right",
              });
      this.$store.commit("setIsLoading", false);
    },
    async syncRMM() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "rmmSync", axios.defaults.baseURL);
      toast({
        message: "RMM sync of clients and Computers has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
  },
};
</script>

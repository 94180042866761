<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">BitLocker Client Manager for {{ client.clientName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>
          <router-link
            to="/dashboard/clients"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#autoPolicyDisplay"
            aria-expanded="false"
            aria-controls="autoPolicyDisplay"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Auto Policy
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#clientConfigParams"
            aria-expanded="false"
            aria-controls="clientConfigParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>
          <router-link
            class="button"
            style="text-decoration: none"
            :to="{
              name: 'BitLockerKeyProtectorExport',
              params: {
                clientID: client.clientID,
              },
            }"
            ><va-icon class="icon ion-md-folder mr-1"></va-icon> Export Keys
          </router-link>
          <button class="button is-success" @click="scanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Rescan Client
          </button>
          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
        </div>
      </div>
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin2Days(clientConfig.lastSync) === false">
                <p class="text-danger"  style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option"
                ><img class="ml-5" src="/images/green_check.png"
              /></span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option"><img class="ml-5" src="/images/red_x.png" /></span>
            </div>
          </template>
          <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Service Logs:</strong></p></span>
              <span class="option">
                <router-link
                  class=""
                  style="text-decoration: none"
                  :to="{
                    name: 'LogViewer',
                    params: {
                      logname: 'BitLocker',
                      id: client.clientID,
                    },
                  }"><va-icon class="icon ion-md-today" style="color:darkgrey;"></va-icon></router-link>
              </span>
            </div>
        </div>
      </div>
      <!-- start Settings dropdown -->
      <div class="column is-12">
        <div class="collapse" id="clientConfigParams">
          <div class="card card-body">
            <h1 class="subtitle">Settings</h1>
            <p class="mb-5">
              This is the BitLocker client configuration area. You can enable or disable
              Bitlocker scans across the client here.
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.isEnabled"
                    />Enable Client Scans</label
                  >
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-success">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Settings -->
      <!-- start Auto Policy Display dropdown -->
      <div class="column is-12">
        <div class="collapse" id="autoPolicyDisplay">
          <div class="card card-body">
            <h1 class="subtitle">Auto Policy</h1>
            <p class="mb-5">
              Auto policies allow you to define default actions pushed down to the
              selected agents and to deploy actions in bulk.Use this tool to add new
              policies to the <i>Available Policies</i> list. To deploy a policy, select
              from the agent list the agents that you want to apply a policy to, then
              select the deploy policy button beside the chosen policy.
            </p>
            <form @submit.prevent="submitAutoPolicyForm">
              <div class="field">
                <label class="mr-2"
                  ><strong
                    >Will the policy be applied automaticlly to matching agents.</strong
                  ></label
                >
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="isEnabled"
                    />Enable Policy</label
                  >
                </div>
              </div>
              <div class="field">
                <label class="select-inline mr-2"
                  ><strong
                    >Choose the device types the policy should include.</strong
                  ></label
                >
                <div class="control">
                  <span class="">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="desktop"
                      />Desktop</label
                    ></span
                  ><span class="ml-2">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="server"
                      />Server</label
                    ></span
                  >
                </div>
              </div>

              <div class="field">
                <label class="select-inline mr-2"
                  ><strong>Select the volume the policy applies to.</strong></label
                >
                <div class="control">
                  <div class="select">
                    <select v-model="mountPoint">
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="F">F</option>
                      <option value="G">G</option>
                      <option value="H">H</option>
                      <option value="I">I</option>
                      <option value="J">J</option>
                      <option value="K">K</option>
                      <option value="L">L</option>
                      <option value="M">M</option>
                      <option value="N">N</option>
                      <option value="O">O</option>
                      <option value="P">P</option>
                      <option value="Q">Q</option>
                      <option value="R">R</option>
                      <option value="S">S</option>
                      <option value="T">T</option>
                      <option value="U">U</option>
                      <option value="V">V</option>
                      <option value="W">W</option>
                      <option value="X">X</option>
                      <option value="Y">Y</option>
                      <option value="Z">Z</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="control mt-3">
                  <lable
                    ><strong
                      >If volume is not already encrypted make encryption size.</strong
                    ></lable
                  >

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="encryptionSize"
                      id="128"
                      v-model="encryptSize128"
                      checked
                    />
                    <label class="form-check-label" for="128"> 128 Bit Encryption </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="encryptionSize"
                      id="256"
                      v-model="encryptSize256"
                    />
                    <label class="form-check-label" for="256"> 256 Bit Encryption </label>
                  </div>
                </div>
              </div>

              <div class="field">
                <label><strong>Apply the following Key Protector type.</strong></label>
                <div class="control">
                  <div class="select">
                    <select v-model="keyProtector">
                      <option value="TPM" selected>TPM Protector</option>
                      <option value="TPMPIN">TPM and Pin Protector</option>
                      <option value="PASSWORD">Password Protector</option>
                      <option value="TPMRECOVERYPASSWORD">
                        TPM and Recovery Password Protector
                      </option>
                      <option value="RECOVERYPASSWORD">
                        Recovery Password Protector
                      </option>
                      <option value="RECOVERYKEY">Recovery Key Protector</option>
                      <option value="STARTUPKEY">Startup Key Protector</option>
                      <option value="TPMSTARTUPKEY">TPM and Startup Key Protector</option>
                      <option value="TPMPINSTARTUPKEY">
                        TPM, PIN and Startup Key Protector
                      </option>
                      <option value="ADACCOUNT">
                        Active Directory Account Protector
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <template v-if="keyProtector == 'TPM'">
                <p>No extra parameters are required.</p>
              </template>
              <template v-if="keyProtector == 'TPMPIN'">
                <div class="field">
                  <label
                    ><strong>Pin Code</strong> (6 numerical digits minium, 20 digits
                    maxium)</label
                  >
                  <div class="control">
                    <input type="text" class="input" v-model="PINCODE" />
                  </div>
                </div>
              </template>
              <template v-if="keyProtector == 'PASSWORD'">
                <div class="field">
                  <label><strong>Password</strong></label>
                  <div class="control">
                    <input type="text" class="input" v-model="PASSWORD" />
                  </div>
                </div>
              </template>
              <template v-if="keyProtector == 'RECOVERYPASSWORD'">
                <p>
                  This key protector will auto generate a 48 digit passcode which will be
                  stored with the agent in RMMmax.
                </p>
              </template>
              <template v-if="keyProtector == 'TPMRECOVERYPASSWORD'">
                <p>
                  This key protector will auto generate a 48 digit passcode which will be
                  stored with the agent in RMMmax.
                </p>
              </template>
              <template v-if="keyProtector == 'STARTUPKEY'">
                <div class="field">
                  <label
                    ><strong>Startup Key Path</strong> (File path (without filename) to
                    store key, key is auto generated.)</label
                  >
                  <div class="control">
                    <input type="text" class="input" v-model="STARTUPKEY" />
                  </div>
                </div>
              </template>
              <template v-if="keyProtector == 'TPMSTARTUPKEY'">
                <div class="field">
                  <label
                    ><strong>Startup Key Path</strong> (File path (without filename) to
                    store key, key is auto generated)</label
                  >
                  <div class="control">
                    <input type="text" class="input" v-model="STARTUPKEY" />
                  </div>
                </div>
              </template>
              <template v-if="keyProtector == 'TPMPINSTARTUPKEY'">
                <div class="field">
                  <label
                    ><strong>Startup Key Path</strong> (File path (without filename) to
                    store key, key is auto generated.)</label
                  >
                  <div class="control">
                    <input type="text" class="input" v-model="STARTUPKEY" />
                  </div>
                </div>
                <div class="field">
                  <label
                    ><strong>Pin Code</strong> (6 numerical digit minium, 20 digit
                    maxium)</label
                  >
                  <div class="control">
                    <input type="text" class="input" v-model="PINCODE" />
                  </div>
                </div>
              </template>
              <template v-if="keyProtector == 'RECOVERYKEY'">
                <div class="field">
                  <label
                    ><strong>Recovery Key Path</strong> (File path (without file name) to
                    store key, key is auto generated.)</label
                  >
                  <div class="control">
                    <input type="text" class="input" v-model="RECOVERYKEY" />
                  </div>
                </div>
              </template>
              <div class="field">
                <div class="control">
                  <button class="button is-success">Create Policy</button>
                </div>
              </div>
            </form>
            <template v-if="autoPolicies.length">
              <div class="tabletitle mt-5">
                <span class="name"> <h1 class="subtitle">Available Policies</h1></span>
                <span class="option"></span>
              </div>

              <table class="table is-fullwidth" id="policyTable">
                <thead>
                  <tr>
                    <th></th>
                    <th>Type</th>
                    <th>Volume</th>
                    <th>Workstations</th>
                    <th>Servers</th>
                    <th>Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="policy in autoPolicies" v-bind:key="policy.id">
                    <td>
                      <button
                        class="button is-danger"
                        @click="removeAutoPolicy(policy.id)"
                      >
                        <va-icon class="icon ion-md-trash"></va-icon>
                      </button>
                      <button
                        class="button is-success ml-1"
                        @click="pushAutoPolicy(policy.id)"
                      >
                        <va-icon class="icon ion-md-cloud-upload"></va-icon>
                      </button>
                    </td>
                    <td>{{ policy.protectorType }}</td>
                    <td>{{ policy.mountPoint }}:</td>
                    <td v-if="policy.desktop === true">
                      <img src="/images/green_check.png" />
                    </td>
                    <td v-else><img src="/images/red_x.png" /></td>
                    <td v-if="policy.server === true">
                      <img src="/images/green_check.png" />
                    </td>
                    <td v-else><img src="/images/red_x.png" /></td>
                    <td v-if="policy.isEnabled === true">
                      <img src="/images/green_check.png" />
                    </td>
                    <td v-else><img src="/images/red_x.png" /></td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <div class="mt-6">&nbsp;</div>
              <p>No policies were returned for client.</p>
            </template>
          </div>
        </div>
      </div>
      <!-- end Auto Policy Display -->
      <!-- Main View agents -->

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle mb-4">
            <span class="name"> <h1 class="subtitle">Agent Status</h1></span>
            <span class="option">
              <button class="button is-success" @click="refreshClientData()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="clientTable">
              <thead>
                <tr>
                  <th style="width: 20px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 30px"><va-icon class="icon ion-md-apps"></va-icon></th>
                  <th>Agent Name</th>
                  <th>Volumes</th>
                  <th>Size</th>
                  <th>Status</th>
                  <th>Last Scan</th>
                  <th scope="col">Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                      <AgentMenu :agent="agent" />
                    </td>
                    <td>{{ agent.computerName }}</td>
                    <td>
                      <template v-for="volume in agentsVolumes" v-bind:key="volume.id">
                        <div v-if="volume.computerID == agent.computerID">
                          <span class="ml-1">{{ volume.mountPoint }}:</span>
                          <span class="ml-1" style="font-size: x-small">({{ volume.volumeType }})</span>
                        </div>
                      </template>
                    </td>
                    <td>
                      <template v-for="volume in agentsVolumes" v-bind:key="volume.id">
                        <div v-if="volume.computerID == agent.computerID">
                          <span class="ml-1">{{ volume.capacityGB }} GB</span>
                        </div>
                      </template>
                    </td>
                    <td>
                      <template v-for="volume in agentsVolumes" v-bind:key="volume.id">
                        <div v-if="volume.computerID == agent.computerID">
                          <span class="ml-1">{{ volume.volumeStatus }}</span>
                        </div>
                      </template>
                    </td>
                    <td>{{ myAgentLastScan(agent.computerID) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <div v-if="myAgentIsEnabled(agent.computerID)">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </div>
                      <div v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </div>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                        <AgentMenu :agent="agent" />
                      </td>
                      <td>{{ agent.computerName }}</td>
                      <td>
                        <template v-for="volume in agentsVolumes" v-bind:key="volume.id">
                          <div v-if="volume.computerID == agent.computerID">
                            <span class="ml-1">{{ volume.mountPoint }}:</span>
                            <span class="ml-1" style="font-size: x-small">({{ volume.volumeType }})</span>
                          </div>
                        </template>
                      </td>
                      <td>
                        <template v-for="volume in agentsVolumes" v-bind:key="volume.id">
                          <div v-if="volume.computerID == agent.computerID">
                            <span class="ml-1">{{ volume.capacityGB }} GB</span>
                          </div>
                        </template>
                      </td>
                      <td>
                        <template v-for="volume in agentsVolumes" v-bind:key="volume.id">
                          <div v-if="volume.computerID == agent.computerID">
                            <span class="ml-1">{{ volume.volumeStatus }}</span>
                          </div>
                        </template>
                      </td>
                      <td>{{ myAgentLastScan(agent.computerID) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <div v-if="myAgentIsEnabled(agent.computerID)">
                          <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span
                          >
                        </div>
                        <div v-else>
                          <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span
                          >
                        </div>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </template>
          <template>
            <p>No agents were returned for client.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
      <!-- end Main View card -->
    </div>
  </div>
</template>

<script>
import { doClientWork } from "@/workers/bitlocker/bitlocker-client-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import AgentMenu from "@/components/layout/AgentMenu-Windows";
import ClientMenu from "@/components/layout/ClientMenu";
export default {
  name: "BitLockerClientManager",
  components: {
    AgentMenu,
    ClientMenu,
  },
  data() {
    return {
      autoPolicies: [],
      PINCODE: "",
      PASSWORD: "",
      STARTUPKEY: "",
      RECOVERYKEY: "",
      mountPoint: "",
      encryptSize128: "",
      encryptSize256: "",
      keyProtector: "",
      desktop: false,
      server: false,
      isEnabled: false,
      num_agents: 0,
      agentsConfigs: [],
      agentsStatus: [],
      agentsVolumes: [],
      errors: [],
      clientConfig: {},
      client: {},
      clients: [],
      agents: [],
      selected: [],
      selectAll: false,
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getClients();
    this.getClient();
    this.getAgents();
    this.getConfig();
    this.getAgentsConfigs();
    this.getAgentsVolumes();
    this.getAutoPolicy();
  },
  methods: {
    myAgentIsEnabled(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isEnabled;
        }
      }
    },

    myAgentLastScan(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.format_date(this.agentsConfigs[i].lastSync);
        }
      }
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async refreshClientData() {
      this.getAgents();
      this.getConfig();
      this.refreshAgentsConfigs();
      this.getAgentsVolumes();
    },
    // Get Client Data function
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClient() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getConfig() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_bitlocker_client_config/${clientID}/`)
        .then((response) => {
          this.clientConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Configs  function
    async getAgentsConfigs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
          setTimeout(function () {
            $("#clientTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // refresh Agents Configs  function
    async refreshAgentsConfigs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Enable Agents Function
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/bitlocker/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgentsConfigs();

            toast({
              message: "BitLocker status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);
      this.clientConfig.clientID = this.$route.params.id;
      await axios
        .post("/api/v1/bitlocker/edit_bitlocker_client_config/", this.clientConfig)
        .then((response) => {
          //console.log(response.data);
          toast({
            message: "BitLocker client configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Volumes  function
    async getAgentsVolumes() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_agents_volumes_by_client/${clientID}/`)
        .then((response) => {
          this.agentsVolumes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async scanClient() {
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      const clientID = this.$route.params.id.toString();
      const URL = axios.defaults.baseURL;
      const agents = "";
      const Policy = "";
      await doClientWork(token, "scanClient", clientID, URL, agents, Policy);
      toast({
        message: "Sent sync request to client successfully",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
      this.$store.commit("setIsLoading", false);
    },

    // Add Auto Policy  function
    async submitAutoPolicyForm() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      if (!this.keyProtector.length > 0) {
        this.errors.push("You must select a Key protector type.");
      }
      let EncAES = "Aes128";
      let desktop = false;
      let server = false;
      let laptop = false;
      let startUpPath = "";
      let Pin = "";
      let Password = "";
      let isEnabled = false;
      if (this.encryptSize256) {
        EncAES = "Aes256";
      }
      if (this.STARTUPKEY) {
        startUpPath = this.STARTUPKEY;
      }
      if (this.RECOVERYKEY) {
        startUpPath = this.RECOVERYKEY;
      }
      if (this.PINCODE) {
        Pin = this.PINCODE;
      }
      if (this.PASSWORD) {
        Password = this.PASSWORD;
      }
      if (this.desktop) {
        desktop = true;
      } else {
        desktop = false;
      }
      if (this.server) {
        server = true;
      } else {
        server = false;
      }
      if (this.isEnabled) {
        isEnabled = true;
      } else {
        isEnabled = false;
      }
      const clientID = this.$route.params.id;
      const data = {
        clientID: clientID,
        mountPoint: this.mountPoint,
        keyType: this.keyProtector,
        encryptType: EncAES,
        path: startUpPath,
        password: Password,
        pin: Pin,
        desktop: desktop,
        server: server,
        isEnabled: isEnabled,
      };
      await axios
        .post(`/api/v1/bitlocker/manage/auto_policy/`, data)
        .then((response) => {
          //console.log(response.data);
          this.getAutoPolicy();
          this.isEnabled = false;
          this.server = false;
          this.desktop = false;
          this.PINCODE = "";
          this.PASSWORD = "";
          this.STARTUPKEY = "";
          this.RECOVERYKEY = "";
          toast({
            message: "Add new auto policy added successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    // Get Auto Policies  function
    async getAutoPolicy() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_auto_policies_for_client/${clientID}/`)
        .then((response) => {
          this.autoPolicies = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Remove Auto Policies  function
    async removeAutoPolicy(ID) {
      this.$store.commit("setIsLoading", true);
      const data = {
        clientID: this.$route.params.id,
        policyID: ID,
      };
      await axios
        .post(`/api/v1/bitlocker/remove_auto_policy_for_client/`, data)
        .then((response) => {
          this.getAutoPolicy();
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // push auto policy to Agents Function
    async pushAutoPolicy(ID) {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      const clientID = this.$route.params.id.toString();
      const URL = axios.defaults.baseURL;
      const agentsList = this.selected.toString();
      const PolicyID = "" + ID.toString();
        await doClientWork(token, "pushAutoPolicy", clientID, URL, agentsList, PolicyID);
        
        toast({
          message: "Policy request sent to agents successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });

        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

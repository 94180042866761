<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">BleachBit Agent Manager for {{ agent.computerName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: 'BleachBitClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Agents
          </router-link>

          <button class="button is-success" @click="refreshOptions">
            <va-icon class="icon ion-md-redo mr-1"></va-icon> Rescan
          </button>
          <button class="button is-success" @click="cleanOptions">
            <va-icon class="icon ion-md-flame mr-1"></va-icon> Clean
          </button>
          <button class="button is-success" @click="overwriteOptions">
            <va-icon class="icon ion-md-bonfire mr-1"></va-icon> Overwrite
          </button>
          <button class="button is-success" @click="openAutoCleanForm">
            <va-icon class="icon ion-md-alarm mr-1"></va-icon> Auto Clean
          </button>
          <button class="button is-success" @click="openForm">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Wipe Free Space
          </button>
          <button class="button is-success" @click="openShredForm">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Shred Location
          </button>
        </div>
        <!-- Set Auto Clean Options pop-up form -->
        <div id="AutoCleanSettingsForm">
          <form @submit.prevent="autoCleanOptions" class="form-container">
            <h1 class="subtitle">Auto Clean Settings</h1>
            <div class="control">
              <p>
                <span class="mr-2">Time:</span>
                <span
                  ><select v-model="agentConfigs.autoCleanTOD">
                    <option value="12AM">12AM</option>
                    <option value="1AM">1AM</option>
                    <option value="2AM">2AM</option>
                    <option value="3AM">3AM</option>
                    <option value="4AM">4AM</option>
                    <option value="5AM">5AM</option>
                    <option value="6AM">6AM</option>
                    <option value="7AM">7AM</option>
                    <option value="8AM">8AM</option>
                    <option value="9AM">9AM</option>
                    <option value="10AM">10AM</option>
                    <option value="11AM">11AM</option>
                    <option value="12PM">12PM</option>
                    <option value="1PM">1PM</option>
                    <option value="2PM">2PM</option>
                    <option value="3PM">3PM</option>
                    <option value="4PM">4PM</option>
                    <option value="5PM">5PM</option>
                    <option value="6PM">6PM</option>
                    <option value="7PM">7PM</option>
                    <option value="8PM">8PM</option>
                    <option value="9PM">9PM</option>
                    <option value="10PM">10PM</option>
                    <option value="11PM">11PM</option>
                  </select></span
                >
              </p>
            </div>
            <div class="control">
              <p class="mt-2">
                <span class="mr-4">Day:</span>
                <span
                  ><select v-model="agentConfigs.autoCleanDOW">
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </select></span
                >

                <span>
                  <button type="submit" class="button is-success ml-3">Execute</button>
                  <button type="button" class="button is-warning ml-3" @click="closeForm">
                    Close
                  </button></span
                >
              </p>
            </div>
          </form>
        </div>
        <!-- Wipe Free Space pop-up form -->
        <div id="WipeFreeSpaceForm">
          <form @submit.prevent="submitWipeFreeSpaceForm" class="form-container">
            <h1 class="subtitle">Wipe Free Space</h1>
            <input type="text" class="input mb-2" v-model="WipeFreeSpace" />
            <button type="submit" class="button is-success ml-3">Execute</button>
            <button type="button" class="button is-warning ml-3" @click="closeForm">
              Close
            </button>
          </form>
        </div>
        <div id="ShredLocationForm">
          <form @submit.prevent="submitShredLocationForm" class="form-container">
            <h1 class="subtitle">Shred Location</h1>
            <input type="text" class="input mb-2" v-model="ShredLocation" />
            <button type="submit" class="button is-success ml-3">Execute</button>
            <button type="button" class="button is-warning ml-3" @click="closeForm">
              Close
            </button>
          </form>
        </div>
      </div>
      <!-- End of Buttons -->
      <!-- status box  -->
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin2Days(agentConfigs.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfigs.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfigs.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="agentConfigs.isEnabled === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
        </div>
      </div>
      <!---  Main View --->
      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle">
            <span class="name"><h1 class="subtitle">Options</h1></span>
            <span class="option">
              <label class="form-checkbox"
                >Show All Options
                <input type="checkbox" v-model="showAllOptions" @click="" />
                <va-icon class="form-icon"></va-icon>
              </label>
              <button class="button is-success ml-5" @click="refreshAgent()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>

          <template v-if="agentOptions.length >= 1">
            <table class="table is-fullwidth" id="agentBBOptions">
              <thead>
                <tr>
                  <th style="width: 20px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th>Option</th>
                  <th>Files</th>
                  <th>Disk Space</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="option in agentOptions">
                  <template
                    v-if="option.files == 0 && showAllOptions == false"
                  ></template>
                  <template v-else>
                    <tr v-bind:key="option.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="option.option"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon> </label
                        ><template v-if="isAutoCleanOption(option.option)"
                          ><va-icon class="icon ion-md-alarm ml-1"></va-icon
                        ></template>
                      </td>
                      <td>
                        <span class="ml-1">{{ option.option }}</span>
                      </td>
                      <td>
                        <span class="ml-1">{{ option.files }}</span>
                      </td>
                      <td>
                        <span class="ml-1">{{ option.diskSpace }} </span>
                      </td>
                    </tr></template
                  ></template
                >
              </tbody>
            </table>
            <div>
              <span class="mr-5" style="display: inline-flex"
                ><h2 class="subtitle">Total Files: {{ agentConfigs.fileCount }}</h2></span
              >
              <span class="ml-5" style="display: inline-flex"
                ><h2 class="subtitle">
                  Total Space: {{ getMB(agentConfigs.diskSpace) }}
                </h2></span
              >
            </div>
          </template>
          <template v-else>
            <p>This agent has no options listed in the database.</p></template
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { toast } from "bulma-toast";
export default {
  name: "BleachBitAgentManager",
  data() {
    return {
      errors: [],
      showAllOptions: false,
      WipeFreeSpace: "",
      ShredLocation: "",
      agentConfigs: {},
      agentOptions: [],
      agentAutoCleanOptions: [],
      agent: {},
      team: {
        members: [],
        created_by: {},
      },
      selected: [],
      selectAll: false,
    };
  },
  mounted() {
    this.getTeam();
    this.getAgent();
    this.getAgentConfigs();
    this.getAgentOptions();
    this.closeForm();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    openForm() {
      document.getElementById("WipeFreeSpaceForm").style.display = "block";
    },
    openShredForm() {
      document.getElementById("ShredLocationForm").style.display = "block";
    },
    openAutoCleanForm() {
      document.getElementById("AutoCleanSettingsForm").style.display = "block";
    },
    closeForm() {
      document.getElementById("WipeFreeSpaceForm").style.display = "none";
      document.getElementById("ShredLocationForm").style.display = "none";
      document.getElementById("AutoCleanSettingsForm").style.display = "none";
    },
    getMB(value) {
      if (parseInt(value) != "NaN") {
        let v = value.toString().length;
        if (v > 9) {
          let i = (value / 1000000000).toFixed(2);
          return `${i} GB`;
        } else if (v > 6) {
          let i = (value / 1000000).toFixed(2);
          return `${i} MB`;
        } else if (v > 3) {
          let i = (value / 1000).toFixed(2);
          return `${i} kB`;
        } else {
          return `${value} B`;
        }
      } else return 0;
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agentOptions) {
          this.selected.push(this.agentOptions[i].option);
        }
      }
    },
    isAutoCleanOption(option) {
      for (let i in this.agentAutoCleanOptions) {
        if (this.agentAutoCleanOptions[i] == option) {
          return true;
        }
      }
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },

    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${agentID}`)
        .then((response) => {
          this.agent = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    refreshAgent() {
      this.getAgentConfigs();
      this.getAgentOptions();
    },
    // Get Agents Configs  function
    async getAgentConfigs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/get_agent_config/${computerID}/`)
        .then((response) => {
          this.agentConfigs = response.data;
          var options = response.data.autoClean.slice(1, -1);
          var optlist = options.split(",");
          for (let i in optlist) {
            var mystr = optlist[i].trim();
            var mystr1 = mystr.split("'");
            this.agentAutoCleanOptions.push(mystr1[1]);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agent options  function
    async getAgentOptions() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/get_agent_options/${computerID}/`)
        .then((response) => {
          this.agentOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    // Rescan Agent data  function
    async refreshOptions() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/refresh_agent_options/${computerID}/`)
        .then((response) => {
          //console.log(response.data);
          toast({
            message: "Options refresh sent to agent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // clean options Function
    async cleanOptions() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more options from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const computerID = this.$route.params.id;
        await axios
          .post(`/api/v1/bleachbit/clean_options_for_agent/${computerID}/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;

            toast({
              message: "BleachBit clean options sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Overwrite options Function
    async overwriteOptions() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more options from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const computerID = this.$route.params.id;
        await axios
          .post(
            `/api/v1/bleachbit/overwrite_options_for_agent/${computerID}/`,
            this.selected
          )
          .then((response) => {
            this.selected = [];
            this.selectAll = false;

            toast({
              message: "BleachBit overwrite options sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },

    //Wipe Free Space
    async submitWipeFreeSpaceForm() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      const data = { location: this.WipeFreeSpace };
      await axios
        .post(`/api/v1/bleachbit/wipe_free_space_for_agent/${computerID}/`, data)
        .then((response) => {
          this.closeForm();
          toast({
            message: "Wipe Free Space command sent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              /* this.errors.push(`${property}: ${error.response.data[property]}`); */
            }
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    //Wipe Free Space
    async submitShredLocationForm() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      const data = { location: this.ShredLocation };
      await axios
        .post(`/api/v1/bleachbit/shred_location_for_agent/${computerID}/`, data)
        .then((response) => {
          this.closeForm();
          toast({
            message: "Shredding request command sent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              /* this.errors.push(`${property}: ${error.response.data[property]}`); */
            }
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    // auto clean options Function
    async autoCleanOptions() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more options from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.closeForm();
        this.$store.commit("setIsLoading", true);
        const computerID = this.$route.params.id;
        const schedule = {
          DOW: this.agentConfigs.autoCleanDOW,
          TOD: this.agentConfigs.autoCleanTOD,
        };

        await axios
          .post(
            `/api/v1/bleachbit/auto_clean_schedule_for_agent/${computerID}/`,
            schedule
          )
          .then((response) => {})
          .catch((error) => {
            if (error.response) {
              this.errors.push("Schedule Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        await axios
          .post(
            `/api/v1/bleachbit/auto_clean_options_for_agent/${computerID}/`,
            this.selected
          )
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.getAgentConfigs();
            this.refreshAgentOptions();
            toast({
              message: "BleachBit Auto Clean status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Options Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

import Worker from 'worker-loader!./worker';
import * as Comlink from 'comlink';

const worker = Comlink.wrap(new Worker());
export const doHardWork = worker.doHardWork;

//worker.addEventListener('message', msg => console.log(msg.data));


//worker.addEventListener('message', (e) => {
//    console.log('we received back',e)

//});


// export function sendMessage(msg){
//     worker.postMessage(msg)
// }
<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">BleachBit Client Manager for {{ client.clientName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>
          <router-link
            to="/dashboard/clients"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#clientConfigParams"
            aria-expanded="false"
            aria-controls="clientConfigParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>

          <button class="button is-success" @click="scanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan Client
          </button>

          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>

          <button class="button is-success" @click="cleanOptions">
            <va-icon class="icon ion-md-flame mr-1"></va-icon> Clean
          </button>
          <button class="button is-success" @click="overwriteOptions">
            <va-icon class="icon ion-md-bonfire mr-1"></va-icon> Overwrite
          </button>
        </div>
      </div>
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin2Days(clientConfig.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Service Logs:</strong></p></span>
              <span class="option">
                <router-link
                  class=""
                  style="text-decoration: none"
                  :to="{
                    name: 'LogViewer',
                    params: {
                      logname: 'BleachBit',
                      id: client.clientID,
                    },
                  }"><va-icon class="icon ion-md-today" style="color:darkgrey;"></va-icon></router-link>
              </span>
            </div>
        </div>
      </div>
      <!-- start Settings dropdown -->
      <div class="column is-12">
        <div class="collapse" id="clientConfigParams">
          <div class="card card-body">
            <h1 class="subtitle">Settings</h1>
            <p class="mb-5">
              This is the BleachBit client configuration area. You can enable or disable
              BleachBit scans across the client here.
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.isEnabled"
                    />Enable Client Scans</label
                  >
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-success">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Settings -->

      <!-- Main View agents -->

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle mb-4">
            <span class="name"> <h1 class="subtitle">Agent Status</h1></span>
            <span class="option">
              <button class="button is-success" @click="refreshClientData()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="clientTable">
              <thead>
                <tr>
                  <th style="width: 12px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon ml-2"></va-icon>
                    </label>
                  </th>
                  <th style="width: 15px"><va-icon class="icon ion-md-apps"></va-icon></th>
                  <th>Agent Name</th>
                  <th>Files</th>
                  <th>Disk Space</th>
                  <th>Last Scan</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                      <AgentMenu :agent="agent" />
                    </td>
                    <td>{{ agent.computerName }}</td>
                    <td>{{ myAgentFileCount(agent.computerID) }}</td>
                    <td>{{ myAgentDiskSpace(agent.computerID) }}</td>
                    <td>{{ myAgentLastScan(agent.computerID) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <div v-if="myAgentIsEnabled(agent.computerID)">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </div>
                      <div v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </div>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                        <AgentMenu :agent="agent" />
                      </td>
                      <td>{{ agent.computerName }}</td>
                      <td>{{ myAgentFileCount(agent.computerID) }}</td>
                      <td>{{ myAgentDiskSpace(agent.computerID) }}</td>
                      <td>{{ myAgentLastScan(agent.computerID) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <div v-if="myAgentIsEnabled(agent.computerID)">
                          <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span
                          >
                        </div>
                        <div v-else>
                          <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span
                          >
                        </div>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </template>
          <template>
            <p>No agents were returned for client.</p>
          </template>
          <div>
            <span class="mr-5" style="display: inline-flex"
              ><h2 class="subtitle">Total Files: {{ totalFiles }}</h2></span
            >
            <span class="ml-5" style="display: inline-flex"
              ><h2 class="subtitle">Total Space: {{ getMB(totalDiskSpace) }}</h2></span
            >
          </div>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
      <!-- end Main View card -->
    </div>
  </div>
</template>

<script>
//import { bbClientWork } from "@/workers/bitlocker/bitlocker-client-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import AgentMenu from "@/components/layout/AgentMenu-Windows";
import ClientMenu from "@/components/layout/ClientMenu";
export default {
  name: "BleachBitClientManager",
  components: {
    AgentMenu,
    ClientMenu,
  },
  data() {
    return {
      num_agents: 0,
      agentsConfigs: [],
      agentsStatus: [],
      agentsOptions: [],
      errors: [],
      clientConfig: {},
      client: {},
      clients: [],
      agents: [],
      selected: [],
      selectAll: false,
      team: {
        members: [],
        created_by: {},
      },
      totalDiskSpace: 0,
      totalFiles: 0,
    };
  },
  mounted() {
    this.getTeam();
    this.getClients();
    this.getClient();
    this.getAgents();
    this.getConfig();
    this.getAgentsConfigs();
    this.getAgentsOptions();
  },
  methods: {
    myAgentIsEnabled(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isEnabled;
        }
      }
    },
    myAgentLastScan(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.format_date(this.agentsConfigs[i].lastSync);
        }
      }
    },
    getMB(value) {
      if (parseInt(value) != "NaN") {
        let v = value.toString().length;
        if (v > 9) {
          let i = (value / 1000000000).toFixed(2);
          return `${i} GB`;
        } else if (v > 6) {
          let i = (value / 1000000).toFixed(2);
          return `${i} MB`;
        } else if (v > 3) {
          let i = (value / 1000).toFixed(2);
          return `${i} kB`;
        } else {
          return `${value} B`;
        }
      } else return 0;
    },
    myAgentDiskSpace(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.getMB(this.agentsConfigs[i].diskSpace);
        }
      }
    },

    myAgentFileCount(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].fileCount;
        }
      }
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    refreshClientData() {
      this.getAgents();
      this.getConfig();
      this.refreshAgentsConfigs();
      this.getAgentsOptions();
    },
    // Get file and disk space totals
    getTotalBBSize() {
      for (let i in this.agentsConfigs) {
        this.totalFiles += this.agentsConfigs[i].fileCount;
        this.totalDiskSpace += this.agentsConfigs[i].diskSpace;
      }
    },
    // Get Client Data function
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClient() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
          setTimeout(function () {
            $("#clientTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getConfig() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/get_bleachbit_client_config/${clientID}/`)
        .then((response) => {
          this.clientConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Configs  function
    async getAgentsConfigs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
          this.getTotalBBSize();

        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // refresh Agents Configs  function
    async refreshAgentsConfigs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Enable Agents Function
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/bleachbit/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgentsConfigs();

            toast({
              message: "BleachBit status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },

    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);
      this.clientConfig.clientID = this.$route.params.id;
      await axios
        .post("/api/v1/bleachbit/edit_bleachbit_client_config/", this.clientConfig)
        .then((response) => {
          //console.log(response.data);
          toast({
            message: "BleachBit client configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Volumes  function
    async getAgentsOptions() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/bleachbit/get_agents_options_by_client/${clientID}/`)
        .then((response) => {
          this.agentsOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // clean options Function
    async cleanOptions() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more options from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        //const token = localStorage.getItem("token");
        //const CMDDATA = {
        //clientID: this.$route.params.id,
        //axiosBaseURL: axios.defaults.baseURL,
        //agents: this.selected,
        //};
        //await doClientWork(token, "cleanOptions", CMDDATA);

        const clientID = this.$route.params.id;
        await axios
          .post(`/api/v1/bleachbit/clean_options_for_client/${clientID}/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            toast({
              message: "BleachBit sent agents run cleaning successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            this.errors.push(error.message);
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Overwrite options Function
    async overwriteOptions() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more options from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const clientID = this.$route.params.id;
        await axios
          .post(
            `/api/v1/bleachbit/overwrite_options_for_client/${clientID}/`,
            this.selected
          )
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            toast({
              message: "BleachBit sent agents run overwrite successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            this.errors.push("Data was rejected by backend");
          });
        this.$store.commit("setIsLoading", false);
      }
    },
    async scanClient() {
      this.$store.commit("setIsLoading", true);
      const data = { clientID: this.$route.params.id };
      await axios
        .post(`/api/v1/bleachbit/sync_client/`, data)
        .then((response) => {
          toast({
            message: "Sent sync request to client successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          this.errors.push("Data was rejected by backend");
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

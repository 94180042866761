<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <router-link
            :to="{
              name: 'ChocolateyClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Chocolatey Manager
        </router-link>
      </div>

      <template v-for="site in sites">      
        <template v-if="site.directory.length >= 1">
          <div class="column is-6">
            <div class="card card-body">
              <h1>{{ site.siteName }}</h1>
              <p><strong>Last Updated: </strong>{{ format_date(site.lastUpdate) }}<hr/></p>
              <template v-for="d in replace(site.directory)">
                <div>
                  <span v-if="d.label.includes('.nupkg')">
                    <i class="icon ion-md-save mr-1"></i>{{ d.label }}</span
                  >
                  <span v-else><i class="icon ion-md-folder mr-1"></i>{{ d.label }}</span>
                  <template v-if="d.children">
                    <template v-for="c in d.children">
                      <div><i class="icon ion-md-save mr-1 ml-5"></i>{{ c.label }}</div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </template>      
      </template>

    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "ChocolateyBlockTree",

  data() {
    return {
      sites: [],
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getSites();
  },

  methods: {
    replace(st) {
      const rep = "'";
      const repWith = '"';
      const result = st.split(rep).join(repWith);
      return JSON.parse(result);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getSites() {
      this.$store.commit("setIsLoading", true);
      let list = [];
      let bc = [];
      const clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_sites/${clientID}/`)
        .then((response) => {
          this.sites = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { createVuestic } from "vuestic-ui";
import "vuestic-ui/css";
import "material-design-icons-iconfont/dist/material-design-icons.min.css";

   //   axios.defaults.baseURL = 'http://127.0.0.1:8000' 
     axios.defaults.baseURL = 'https://api.rmmmax.com'

createApp(App).use(store).use(router, axios).use(
      createVuestic({
        config: {
          colors: {
            variables: {
              // Default colors
              primary: "#23e066",
              secondary: "#002c85",
              success: "#40e583",
              info: "#2c82e0",
              danger: "#e34b4a",
              warning: "#ffc200",
              gray: "#babfc2",
              dark: "#34495e",
            },
          },
        },
      })
    ).mount('#app')

    
    
<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
      </div>
      <div class="column is-12">
        <h1 class="subtitle mt-3">Notifications</h1>
        <p class="mb-5">
          Notifications are automated emails sent to the notifications email address setup
          for your team. There are many types of notifications that can be sent, select
          from the list below to enable those notifications. This control requires that
          the (Send Notification Email) has been enabled under RMM Environment settings.
          Once enabled you can select which notifications you would like to receive. Some
          of these notification have the potential to send multiple notifications per day.
        </p>
      </div>
      <form @submit.prevent="submitNotifytForm" class="row g-4">
        <div class="column is-4">
          <div class="card card-body">
            <h2 class="mb-3">Automated RMM Sync</h2>
            <div class="field">
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.clientAgentSync"
                  />Send Client/Agent Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.bitlockerSync"
                  />Send BitLocker Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.chocolateySync"
                  />Send Chocolatey Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.bleachbitSync"
                  />Send BleachBit Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.defenderSync"
                  />Send Defender Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.surflogSync"
                  />Send SurfLog Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.lumSync"
                  />Send LUM Sync Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.homebrewSync"
                  />Send HomeBrew Sync Notice</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="column is-4">
          <div class="card card-body">
            <h2 class="mb-3">User Actions</h2>
            <div class="field">
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.bitlockerUserAction"
                  />Send BitLocker Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.chocolateyUserAction"
                  />Send Chocolatey Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.defenderUserAction"
                  />Send Defender Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.surflogUserAction"
                  />Send SurfLog Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.bleachbitUserAction"
                  />Send BleachBit Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.lumUserAction"
                  />Send LUM Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.homebrewUserAction"
                  />Send HomeBrew Notice</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="card card-body">
            <h2 class="mb-3">Console Actions</h2>
            <div class="field">
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.chocolateyUpdates"
                  />Send Chocolatey Update Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.lumUpdates"
                  />Send LUM Update Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.homebrewUpdates"
                  />Send HomeBrew Update Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.userLogin"
                  />Send User Login Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.purgeData"
                  />Send Data Purged Notice</label
                >
              </div>
              <div class="control">
                <label class="checkbox-inline"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="notify.purgelogs"
                  />Send Logs Purged Notice</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="column is-10 is-offset-1">
          <div class="control">
            <button class="button is-success mt-4">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import axios from "axios";
export default {
  name: "Notifications",
  data() {
    return {
      errors: [],
      team: {
        members: [],
        created_by: {},
      },
      notify: {},
    };
  },
  mounted() {
    this.getTeam();
    this.getNotify();
  },
  methods: {
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    getMPVersion() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "management package") {
          this.MPVersion = this.tools[i].version;
        }
      }
    },
    getDattoVersion() {
      for (let i in this.tools) {
        if (this.tools[i].toolName == "dattopackagezip") {
          this.DattoVersion = this.tools[i].version;
        }
      }
    },

    async getNotify() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_notifications/")
        .then((response) => {
          this.notify = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async submitNotifytForm() {
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/teams/edit_notifications/", this.notify)
        .then((response) => {
          toast({
            message: "Notifications updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.team = response.data;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              /* this.errors.push(`${property}: ${error.response.data[property]}`); */
            }
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <PushNotifications />
  <div class="app-layout">
    <Navbar />
    <div class="app-layout__content">
      <div class="app-layout__page">
        <div
          class="is-loading-bar has-text-centered"
          v-bind:class="{ 'is-loading': $store.state.isLoading }"
        >
          <div class="lds-dual-ring"></div>
        </div>
        <div class="layout fluid mt-2">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar";
import PushNotifications from "@/components/PushNotifications";
import axios from "axios";
export default {
  name: "App",
  components: {
    Navbar,
    PushNotifications,
  },

  beforeCreate() {
    this.$store.commit("initializeStore");
    if (this.$store.state.token) {
      axios.defaults.headers.common["Authorization"] = "Token " + this.$store.state.token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
    if (!this.$store.state.team.id) {
      this.$router.push("/dashboard/add-team");
    }
  },
};
</script>
<style lang="scss">
@import "../node_modules/bulma";
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 80px;
  }
}

$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;
.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__navbar {
    min-height: 4rem;
  }

  &__content {
    display: flex;
    height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: #ffffff;

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 999;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }
  &__page {
    flex-grow: 2;
    overflow-y: scroll;
  }
}
</style>

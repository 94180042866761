<template>
  <template v-if="isNaN(myPercent) === false">
    <div class="tabletitle">
      <span class="name">SurfLogs </span><span class="option"> [{{ myPercent }}%]</span>
    </div>
    <span><va-progress-bar :size="25" :color="myColor" :model-value="myPercent" /></span
  ></template>
</template>

<script>
import axios from "axios";
export default {
  name: "SurfLogResentSync",

  data() {
    return {
      configs: [],
      myPercent: 0,
      myColor: "danger",
    };
  },
  mounted() {
    this.getSurflogUpdates();
  },
  methods: {
    checkDateWithin2Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );

      return new Date(date) > oneMonthEarlier;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    async getSurflogUpdates() {
      await axios
        .get("/api/v1/surflog/get_all_Surflog_agent_configs/")
        .then((response) => {
          this.configs = response.data;
          let Max = this.configs.length;
          let Updated = 0;
          let x;
          for (let i = 0; i < this.configs.length; i++) {
            let x = this.checkDateWithin2Days(this.configs[i].lastSync);
            if (x === true) {
              Updated += 1;
            }
          }
          //console.log(Updated);
          this.myPercent = Math.round((Updated / Max) * 100);
          if (Number(Math.round((Updated / Max) * 100)) >= Number(90)) {
            this.myColor = "success";
          } else if (Number(Math.round((Updated / Max) * 100)) >= Number(70)) {
            this.myColor = "warning";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">Chocolatey Client Manager for {{ client.clientName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>
          <router-link
            to="/dashboard/clients"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#chocolateyParams"
            aria-expanded="false"
            aria-controls="chocolateyParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#approvedPackages"
            aria-expanded="false"
            aria-controls="approvedPackages"
          >
            <va-icon class="icon ion-md-gift mr-1"></va-icon> Approved Packages
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#installPackage"
            aria-expanded="false"
            aria-controls="installPackage"
          >
            <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon> Install Package
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#siteCaching"
            aria-expanded="false"
            aria-controls="siteCaching"
          >
            <va-icon class="icon ion-md-cloud-outline mr-1"></va-icon> Site Caching
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#chocoSources"
            aria-expanded="false"
            aria-controls="chocoSources"
          >
            <va-icon class="icon ion-md-cloud-outline mr-1"></va-icon> Sources
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#chocoClientErrorLogs"
            aria-expanded="false"
            aria-controls="chocoClientErrorLogs"
          >
            <va-icon class="icon ion-md-today mr-1"></va-icon> Error Logs
          </button>          
          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>
          <button class="button is-success" @click="scanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan Client
          </button>
          <button class="button is-success" @click="updateClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Update Client
          </button>
          <button class="button is-success" @click="openForm">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Set Cache Location
          </button>

          <!-- Site cache pop-up selection form -->
          <div id="mySiteCacheForm">
            <form @submit.prevent="submitSiteCacheForm" class="form-container">
              <h1 class="subtitle">Cache Locations</h1>
              <div class="select">
                <select v-model="siteID">
                  <template v-for="site in sites">
                    <template v-if="site.isEnabled == true">
                      <option :value="site.id">
                        {{ site.siteName }}
                      </option>
                    </template>
                    <template v-else>
                      <option disabled>
                        {{ site.siteName }}
                      </option>
                    </template></template
                  >
                </select>
              </div>
              <button type="submit" class="button is-success ml-3">
                <va-icon class="icon ion-md-save mr-1"></va-icon> Save
              </button>
              <button type="button" class="button is-warning" @click="closeForm">
                <va-icon class="icon ion-md-close mr-1"></va-icon> Close
              </button>
            </form>
          </div>
          <!-- End of Site cache selection form -->
        </div>
      </div>

      <!-- end buttons -->
      <!-- status box  -->
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Agent Sync:</strong></p></span
            >
            <span class="option">
              <template
                v-if="checkDateWithin30Days(chocoConfig.lastPackageSync) === false"
              >
                <p class="text-danger" style="font-size: small">
                  {{ format_date(chocoConfig.lastPackageSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(chocoConfig.lastPackageSync) }}
                </p></template
              >
            </span>
          </div>    
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Update Push:</strong></p></span
            >
            <span class="option">
              <template
                v-if="checkDateWithin30Days(chocoConfig.lastPackageUpdate) === false"
              >
                <p class="text-danger" style="font-size: small">
                  {{ format_date(chocoConfig.lastPackageUpdate) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(chocoConfig.lastPackageUpdate) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Install Push: </strong></p></span
            >
            <span class="option">
              <template
                v-if="checkDateWithin30Days(chocoConfig.lastPackageInstall) === false"
              >
                <p class="text-danger" style="font-size: small">
                  {{ format_date(chocoConfig.lastPackageInstall) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(chocoConfig.lastPackageInstall) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Service Logs:</strong></p></span>
              <span class="option">
                <router-link
                  class=""
                  style="text-decoration: none"
                  :to="{
                    name: 'LogViewer',
                    params: {
                      logname: 'Chocolatey',
                      id: client.clientID,
                    },
                  }"><va-icon class="icon ion-md-today" style="color:darkgrey;"></va-icon></router-link>
              </span>
            </div>
        </div>
      </div>
      <!-- start dropdown cards -->

      <!-- Install Package drop down -->
      <div class="column is-12">
        <div class="panel-collapse collapse" id="installPackage">
          <div class="card card-body">
            <h1 class="subtitle">Install Chocolatey Package</h1>
            <p class="mb-5">
              To install a package, select one or more agents then enter the Chocolatey
              package name to install. You can also select from the approved packages list
              to push package to agent(s). If you select to install a unapproved package
              and the agent is using caching then the agent will be instructed to get that
              package directly from the public repositories.
            </p>

            <form @submit.prevent="installPackageForm" class="row g-4">
              <label class="form-checkbox">
                <input type="checkbox" v-model="showApprovedPackages" />
                <va-icon class="form-icon"></va-icon> Install using an Approved Package
              </label>
              <template v-if="showApprovedPackages == false">
                <div class="col-auto">
                  <div class="field">
                    <label>Package Name</label>
                    <div class="control">
                      <input type="text" class="input" v-model="installPackageName" />
                    </div>
                  </div></div
              ></template>
              <template v-else>
                <div class="col-auto">
                  <div class="field">
                    <label class="select-inline mr-2">Approved Packages</label>
                    <div v-if="packages.length > 0" class="control mt-1">
                      <div class="select">
                        <select v-model="installApprovedPackage">
                          <option
                            v-for="approvedPackage in packages"
                            :value="approvedPackage.packageName"
                            :key="approvedPackage.id"
                          >
                            {{ approvedPackage.packageName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-else class="mt-1"><p>No Approved Packages Found</p></div>
                  </div>
                </div></template
              >
              <div class="col-auto">
                <div class="field">
                  <div class="control mt-4">
                    <button
                      class="button is-success"
                      data-bs-toggle="collapse"
                      data-bs-target="#installPackage"
                      aria-expanded="false"
                      aria-controls="installPackage"
                    >
                      <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon>
                      Install
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <hr />
            <div class="mt-3">
              <h1 class="subtitle mt-3">Need To Manage All Client Packages At Once?</h1>
              <p>
                To install all approved packages to all enabled and online agents select
                the Install Packages button. You can use the Upgrade Packages button to
                upgrade all approved packages on enabled and online agents. The Remove
                Packages will uninstall all approved packages from client.
              </p>
              <div class="buttons mt-3">
                <button
                  class="button is-success mt-2"
                  @click="installClientApprovedPackages"
                >
                  <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon> Install
                  Packages
                </button>
                <button
                  class="button is-success mt-2"
                  @click="upgradeClientApprovedPackages"
                >
                  <va-icon class="icon ion-md-trending-up mr-1"></va-icon> Upgrade
                  Packages
                </button>
                <button
                  class="button is-danger mt-2"
                  @click="removeClientApprovedPackages"
                >
                  <va-icon class="icon ion-md-trash mr-1"></va-icon> Remove Packages
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end Install Software drop down-->

      <!-- Client Error Log drop down -->
      <div class="column is-12">
        <div class="panel-collapse collapse" id="chocoClientErrorLogs">
          <div class="card card-body">
            <h1 class="subtitle">Current Agent(s) Errors</h1>
            <p class="mb-5">
              If any agent during the last run of either automated installs or updates, leaves any error logs in the standard chocolatey log, they are captured during the next package scan. If logs are listed here, they will be from the enabled agent under this client.
            </p>

            <hr />
            <template v-if="clientLogs.length != 0">
            <table class="table is-fullwidth" id="chocolateyClientErrorLogsTable">
              <thead>
                <tr>
                  <th>Agent Name</th>
                  <th>Date</th>
                  <th>Log</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="log in clientLogs" v-bind:key="log.id">

                    <td>{{ getAgentNameByID(log.computerID) }}</td>
                    <td>{{ log.logDate }}</td>
                    <td>{{ log.log }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p>No agent error logs at this time.</p>

          </template>
          </div>
        </div>
      </div>


        <!-- Sources dropdown -->

        <div class="collapse" id="chocoSources">
          <div class="card card-body">
            <h1 class="subtitle">Chocolatey Repository Sources</h1>
            <p class="mb-2">
            Chocolatey source repository management area allows you to add and remove 
            alternitive package repository sources. You can push enabled sources to 
            individual or groups of agents from the source list below. Sources are used with 
            standalone agents to control what repositories the agents will use and in 
            what priority. You will find agent sources listed under each 
            agents chocolatey manager. All chocolatey installs default to the public repository, 
            if you are going to use the default public repository then
            you will not need to do anything here. 
            </p>
            <p class="mt-3 mb-3">
            Here is how to host your own repository, <a href="https://docs.chocolatey.org/en-us/features/host-packages">Hosting Packages</a> 
            requires a bit more time and energy to manage. These are advanced configurations 
            which may not be required for you to manage agent software installs. But if you are looking to host software packages like 
            LOB applications and premium software updates, then hosting a repository may be for you.
            </p>
            <p class="mb-3"> 
            <ul>
       <li class="mt-2 mb-5"><strong>Priority</strong> - The priority order of this source as compared to other 
       sources, lower is better. Defaults to 0 (no priority). All priorities 
       above 0 will be evaluated first, then zero-based values will be 
       evaluated in config file order.</li>
       </ul>
            </p>

            <form @submit.prevent="submitSourceForm" class="row g-3">
              <div class="col-auto">
                <div class="field">
                  <label class="mb-2">Source Name</label> (required)
                  <div class="control">
                    <input type="text" class="resizedTextbox-md" v-model="source.name" />
                  </div>
                </div>
                <div class="field">
                  <label class="mb-2">URL</label> (required)
                  <div class="control">
                    <input type="text" class="resizedTextbox-md" v-model="source.URL" />
                  </div>
                </div>
              </div>

              <div class="col-auto">
                <div class="field">
                  <label class="mb-2">Username</label> (optional)
                  <div class="control">
                    <input
                      type="text"
                      class="resizedTextbox-md"
                      v-model="source.userName"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="mb-2">Password</label> (optional)
                  <div class="control">
                    <input
                      type="password"
                      class="resizedTextbox-md"
                      v-model="source.userPass"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="field">
                  <div class="control mt-3">
                    <label class="mt-2">Priority  </label>
                    <input class="resizedTextbox w-25 ml-2" v-model="source.priority" />
                  </div>
                </div>
                <div class="field">
                  <div class="control ">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2 mt-4"
                        type="checkbox"
                        v-model="source.isEnabled"
                      />Enable</label
                    >
                  </div>
                </div></div>
                <div class="col-auto">
                <div class="field">
                  <div class="control">
                    <button class="button is-success mt-2">
                      <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          <template v-if="sources.length >= 1">
            <div class="mt-3">
              <h1 class="subtitle mt-3">Need To Deploy Source Settings?</h1>
              <p>
                To deploy source settings to agents, select the checkbox beside one or
                more of the agents from the agent list below, then select the
                <i>Source</i> button from any source in the list and click
                <i>Set As Source Repo</i>.
              </p>
            </div>
            <hr />
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 30px"></th>
                  <th>Source Name</th>
                  <th>URL</th>
                  <th>Priority</th>
                  <th>Last Update</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr v-for="(mysource, itemObjKey) in sources" v-bind:key="mysource.id">
                  <th scope="id">
                    <div class="dropdown">
                      <button class="dropbtn">
                        <va-icon class="icon ion-md-apps"></va-icon>
                      </button>
                      <div class="dropdown-content">
                        <template v-if="mysource.isEnabled == true">
                          <a href="#" @click="setAgentSource(itemObjKey)"
                            ><va-icon class="icon ion-md-contacts mr-1"></va-icon>
                            Set As Source Repo
                          </a>
                          <a href="#" @click="pushClientSources(itemObjKey)"
                            ><va-icon class="icon ion-md-contacts mr-1"></va-icon>
                            Push Sources Out To Agents
                          </a>
                        </template>
                        <a href="#" @click="enableSource(itemObjKey)"
                          ><va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable
                          / Disable</a
                        >
                        <a href="#" @click="editSource(itemObjKey)"
                          ><va-icon class="icon ion-md-cog mr-1"></va-icon> Edit Source</a
                        >
                        <a href="#" @click="deleteSource(itemObjKey)"
                          ><va-icon class="icon ion-md-trash mr-1"></va-icon> Delete
                          Source</a
                        >
                      </div>
                    </div>
                  </th>
                  <td>{{ mysource.name }}</td>
                  <td>{{ mysource.URL }}</td>
                  <td>{{ mysource.priority }}</td>
                  <td>{{ format_date(mysource.lastUpdate) }}</td>
                  <td v-if="mysource.isEnabled === true">
                    <img src="/images/green_check.png" />
                  </td>
                  <td v-else><img src="/images/red_x.png" /></td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
          <p class="mt-3 mb-3">
          No added repository services available.
          </p>
          </template>
          </div>
        </div>

        <!-- end Sources -->

        <!-- site caching dropdown -->

        <div class="collapse" id="siteCaching">
          <div class="card card-body">
            <h1 class="subtitle">Site Caching</h1>
            <p class="mb-5">
              Site caching is typically used at sites / locations where 3 or more agent
              are attempting to do installs and updates from the Chocolatey community
              repositories. By using site caching in lieu of a chocolatey MSP licenses
              allows large agent counts at a single site to gain the benefits of using the
              community repositories without causing sites to reach community repositories
              limit restrictions.
            </p>

            <form @submit.prevent="submitSiteForm" class="row g-3">
              <div class="col-auto">
                <div class="field">
                  <label class="mb-2">Site Name</label>
                  <div class="control">
                    <input
                      type="text"
                      class="resizedTextbox-md"
                      v-model="site.siteName"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="mb-2">Network Share</label>
                  <div class="control">
                    <input
                      type="text"
                      class="resizedTextbox-md"
                      v-model="site.networkShare"
                    />
                  </div>
                </div>
              </div>

              <div class="col-auto">
                <div class="field">
                  <label class="mb-2">Username</label>
                  <div class="control">
                    <input
                      type="text"
                      class="resizedTextbox-md"
                      v-model="site.userName"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="mb-2">Password</label>
                  <div class="control">
                    <input
                      type="password"
                      class="resizedTextbox-md"
                      v-model="site.userPass"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="field">
                  <div class="control mt-4">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2 mt-3"
                        type="checkbox"
                        v-model="site.isEnabled"
                      />Enable</label
                    >
                  </div>
                </div>

                <div class="field">
                  <div class="control">
                    <button class="button is-success mt-4">
                      <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div class="mt-3">
              <h1 class="subtitle mt-3">Need To Deploy Site Settings?</h1>
              <p>
                To deploy site settings to agents, select the checkbox beside one or more
                of the agents from the agent list below, then select the
                <i>Site</i> button from any site in the list and click
                <i>Set As Caching Site</i>. You can use the buttons below to toggle the
                standalone and cache manager flags for all agents selected.
              </p>
              <div class="buttons mt-3">
                <button class="button is-success mt-2" @click="setAgentsAsStandAlone">
                  <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable /
                  Disable Agents As Standalone
                </button>
                <button class="button is-success mt-2" @click="setAgentsAsCacheManager">
                  <va-icon class="icon ion-md-man mr-1"></va-icon> Enable / Disable Agents
                  As Cache Manager
                </button>
              </div>
            </div>
            <hr />
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Site Name</th>
                  <th scope="col">Network Share</th>
                  <th scope="col">Last Update</th>
                  <th scope="col">Manager</th>
                  <th scope="col">Enabled</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr v-for="(mysite, itemObjKey) in sites" v-bind:key="mysite.id">
                  <th scope="id">
                    <div class="dropdown">
                      <button class="dropbtn">
                        <va-icon class="icon ion-md-apps"></va-icon>
                      </button>
                      <div class="dropdown-content">
                        <template v-if="mysite.isEnabled == true">
                          <a href="#" @click="setAgentSite(itemObjKey)"
                            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Set As
                            Caching Site</a
                          >
                          <a href="#" @click="updateSiteCache(itemObjKey)"
                            ><va-icon class="icon ion-md-trending-up mr-1"></va-icon>
                            Update Cache</a
                          ></template
                        >
                        <router-link
                          :to="{
                            name: 'ChocolateyBlockTree',
                            params: {
                              clientID: client.clientID,
                            },
                          }"
                          ><va-icon class="icon ion-md-folder mr-1"></va-icon> Cache Share
                          Status
                        </router-link>
                        <a href="#" @click="enableSite(itemObjKey)"
                          ><va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable
                          / Disable</a
                        >
                        <a href="#" @click="editSite(itemObjKey)"
                          ><va-icon class="icon ion-md-cog mr-1"></va-icon> Edit Site
                          Cache</a
                        >
                        <a href="#" @click="deleteSite(itemObjKey)"
                          ><va-icon class="icon ion-md-trash mr-1"></va-icon> Delete Site
                          Cache</a
                        >
                      </div>
                    </div>
                  </th>
                  <td>{{ mysite.siteName }}</td>
                  <td>{{ mysite.networkShare }}</td>
                  <td>{{ format_date(mysite.lastUpdate) }}</td>
                  <td>{{ getSiteManager(mysite.id) }}</td>
                  <td v-if="mysite.isEnabled === true">
                    <img src="/images/green_check.png" />
                  </td>
                  <td v-else><img src="/images/red_x.png" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- end site caching -->
        <!-- start Settings dropdown -->

        <div class="collapse" id="chocolateyParams">
          <div class="card card-body">
            <h1 class="subtitle">Settings</h1>
            <p class="mb-5">
              This is the chocolatey parameters area that will control how agents use
              Chocolatey
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="chocoConfig.autoInstall"
                    />Auto Install Packages</label
                  >

                  <label class="checkbox-inline ml-6"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="chocoConfig.autoUpdate"
                    />Auto Update Packages</label
                  >

                  <label class="checkbox-inline ml-6"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="chocoConfig.mspAutoLicense"
                    />Auto License Agents</label
                  >
                </div>
              </div>
              <div class="field">
                <label class="mb-3">Chocolatey MSP License</label>
                <div class="control">
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="chocoConfig.mspLicense"
                  ></textarea>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-success"
                    data-bs-toggle="collapse"
                    data-bs-target="#chocolateyParams"
                    aria-expanded="false"
                    aria-controls="chocolateyParams"
                  >
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
            <hr class="mt-5 mb-5" />
            <div>
              <h1 class="subtitle">Automated Update Schedules</h1>
              <p>
                You can schedule automated updates for all agents under this client or
                schedule different locations under this client for different automated
                updates schedules.
              </p>
            </div>
            <div class="mt-4">
              <form @submit.prevent="submitScheduleForm">
                <span class="a">                
                  <h2 class="subtitle">Add new schedule</h2>
                  <div class="field mt-5">
                    <label>Select Location(s)</label>
                    <div class="control mt-3">
                      <div class="select">
                        <select v-model="schedule.location">
                          <option>All</option>
                          <template v-for="site in locations">
                            <option>{{ site.locationName }}</option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <label>Schedule Type</label>
                    <div class="control mt-3">
                      <div class="select">
                        <select v-model="schedule.schedule">
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <template v-if="schedule.schedule == 'Weekly'">
                      <label>Schedule day of week</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="schedule.scheduleDay">
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                          </select>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="schedule.schedule == 'Monthly'">
                      <label>Schedule day of month</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="schedule.scheduleDay">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                          </select>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <label></label>
                      <div class="control mt-3"><p></p></div>
                    </template>
                  </div>
                </span>
                <span class="a">
                  <template v-if="schedule.schedule != undefined">
                    <div class="field mt-5">
                      <label>Schedule time (00:00)</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="schedule.scheduleHour">
                            <option value="00">00:00</option>
                            <option value="01">01:00</option>
                            <option value="02">02:00</option>
                            <option value="03">03:00</option>
                            <option value="04">04:00</option>
                            <option value="05">05:00</option>
                            <option value="06">06:00</option>
                            <option value="07">07:00</option>
                            <option value="08">08:00</option>
                            <option value="09">09:00</option>
                            <option value="10">10:00</option>
                            <option value="11">11:00</option>
                            <option value="12">12:00</option>
                            <option value="13">13:00</option>
                            <option value="14">14:00</option>
                            <option value="15">15:00</option>
                            <option value="16">16:00</option>
                            <option value="17">17:00</option>
                            <option value="18">18:00</option>
                            <option value="19">19:00</option>
                            <option value="20">20:00</option>
                            <option value="21">21:00</option>
                            <option value="22">22:00</option>
                            <option value="23">23:00</option>
                          </select>
                        </div>
                      </div>
                    </div></template
                  >
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <div class="control">
                      <button class="button is-success">
                        <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                      </button>
                    </div>
                  </div>
                </span>
              </form>

              </div>
              <div class="mt-3">
              <h1 class="subtitle">Schedules</h1>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 35px"></th>
                    <th>Location</th>
                    <th>Type</th>
                    <th>Day</th>
                    <th>Hour</th>
                    <th>Enabled</th>
                  </tr>
                </thead>
                <tbody class="table-group-divider">
                  <tr v-for="(sch, itemObjKey) in schedules" v-bind:key="sch.id">
                    <th scope="id">
                      <div class="dropdown">
                        <button class="dropbtn">
                          <va-icon class="icon ion-md-apps"></va-icon>
                        </button>
                        <div class="dropdown-content">
                          <a
                            href="#"
                            @click="enableSchedule(itemObjKey)"
                            style="text-decoration: none"
                          >
                            <va-icon class="icon ion-md-git-compare mr-1"></va-icon>
                            Enable / Disable Schedule
                          </a>
                          <a
                            href="#"
                            @click="editSchedule(itemObjKey)"
                            style="text-decoration: none"
                            ><va-icon class="icon ion-md-cog mr-1"></va-icon> Edit
                            Schedule</a
                          >
                          <a
                            href="#"
                            @click="deleteSchedule(itemObjKey)"
                            style="text-decoration: none"
                            ><va-icon class="icon ion-md-trash mr-1"></va-icon> Delete
                          </a>
                        </div>
                      </div>
                    </th>
                    <td>{{ sch.location }}</td>
                    <td>{{ sch.schedule }}</td>
                    <template v-if="sch.schedule == 'Weekly'">
                      <td v-if="sch.scheduleDay == 0">Sunday</td>
                      <td v-else-if="sch.scheduleDay == 1">Mondays</td>
                      <td v-else-if="sch.scheduleDay == 2">Tuesdays</td>
                      <td v-else-if="sch.scheduleDay == 3">Wednesdays</td>
                      <td v-else-if="sch.scheduleDay == 4">Thursdays</td>
                      <td v-else-if="sch.scheduleDay == 5">Fridays</td>
                      <td v-else="sch.scheduleDay == 6">Saturdays</td>
                      <td v-else>Unknown</td>
                    </template>
                    <template v-else>
                      <td>On Day {{ sch.scheduleDay }}</td></template
                    >
                    <td>{{ sch.scheduleHour }}:00</td>
                    <td v-if="sch.isEnabled === true">
                      <img src="/images/green_check.png" />
                    </td>
                    <td v-else><img src="/images/red_x.png" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>

            </div>

            <hr />
            <div class="mt-3">
              <h1 class="subtitle mt-3">Need To Deploy MSP licenses?</h1>
              <p>
                To manually license agents, select the checkbox beside the agents from the
                agent list below then select the <i>Set License</i> button to deploy MSP
                license to agents.
              </p>
              <div class="buttons">
                <button class="button is-success mt-2" @click="licenseAgents">
                  <va-icon class="icon ion-md-download mr-1"></va-icon> Set Licenses
                </button>
                <button class="button is-danger mt-2" @click="mspLicenseRemove">
                  <va-icon class="icon ion-md-trash mr-1"></va-icon> Remove Licenses
                </button>
                <button class="button is-success mt-2" @click="installChocolateyToAgents">
                  <va-icon class="icon ion-md-cloud-download mr-1"></va-icon> Deploy
                  Chocolatey
                </button>
                <button
                  class="button is-success mt-2"
                  @click="testChocolateyPackageVersionsCurrent"
                >
                  <va-icon class="icon ion-md-cloud-download mr-1"></va-icon> Test
                  Packages Are Current
                </button>
                <button class="button is-danger mt-2" @click="purgeClientPackageData">
                  <va-icon class="icon ion-md-trash mr-1"></va-icon> Purge Agent Data
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- end Settings -->

        <!-- start of apporved packages -->

        <div class="collapse" id="approvedPackages">
          <div class="card card-body">
            <div class="tabletitle">
            <span class="name"><h1 class="subtitle">Approved Packages</h1></span>
            <span class="option">
              <a @click="seedClient">
                <va-icon class="icon ion-md-gift"></va-icon></a>
            </span>
          </div>
            <p class="mb-4">
              Add Chocolatey.org package names and the application name you would like to
              associate the package with and select the save button. You can select any
              package from the list and it will populate this form allowing you to edit
              the package or enable / disable the package.
            </p>
            <form @submit.prevent="submitPackageForm" class="row g-4">
              <div class="col-auto">
                <label class="checkbox-inline mt-1"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="package.isEnabled"
                  />Enabled</label
                ><br />
                <label class="checkbox-inline mt-1"
                  ><input
                    class="form-check-input mr-2"
                    type="checkbox"
                    v-model="package.pushToAllClients"
                  />Approve package for all clients</label
                >
              </div>
              <div class="col-auto">
                <label>Chocolatey Package Name</label>
                <div class="control mt-2">
                  <input
                    type="text"
                    class="resizedTextbox-md"
                    v-model="package.packageName"
                  />
                </div>
              </div>
              <div class="col-auto">
                <div class="control mt-4">
                  <button class="button is-success">
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
            <hr />
            <table class="table" id="approvedPackagesTable">
              <thead>
                <tr>
                  <th>Package</th>
                  <th>Current Version</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(chocopack, itemObjKey) in packages" v-bind:key="chocopack.id">
                  <td>
                    <div class="dropdown">
                      <button class="dropbtn mr-3">
                        <va-icon class="icon ion-md-apps"></va-icon>
                      </button>
                      <div class="dropdown-content">
                        <a
                          href="#"
                          @click="enablePackage(itemObjKey)"
                          style="text-decoration: none"
                        >
                          <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable
                          / Disable Package
                        </a>
                        <a
                          href="#"
                          @click="editPackage(itemObjKey)"
                          style="text-decoration: none"
                          ><va-icon class="icon ion-md-cog mr-1"></va-icon> Edit
                          Package</a
                        >
                        <a
                          href="#"
                          @click="deletePackage(itemObjKey)"
                          style="text-decoration: none"
                          ><va-icon class="icon ion-md-trash mr-1"></va-icon> Delete
                          Package</a
                        >
                        <a
                          href="#"
                          @click="installAllAgents(itemObjKey)"
                          style="text-decoration: none"
                        >
                          <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon>
                          Install (all agents)
                        </a>
                        <a
                          href="#"
                          @click="upgradeAllAgents(itemObjKey)"
                          style="text-decoration: none"
                        >
                          <va-icon class="icon ion-md-trending-up mr-1"></va-icon> Update
                          (all agents)
                        </a>
                        <a
                          href="#"
                          @click="removeAllAgents(itemObjKey)"
                          style="text-decoration: none"
                        >
                          <va-icon class="icon ion-md-trash mr-1"></va-icon> Uninstall
                          (all agents)
                        </a>
                      </div>
                    </div>
                    <strong>{{ chocopack.packageName }}</strong></td>
                    
                  <td>
                    <template v-for="cap in currentAvailablePackages">
                      <template v-if="chocopack.packageName == cap.packageName">
                        {{ cap.version }}</template
                      >
                    </template>
                  </td>
                  <td v-if="chocopack.isEnabled === true">
                    <img src="/images/green_check.png" /><span style="display: none"
                      >enabled</span
                    >
                  </td>
                  <td v-else>
                    <img src="/images/red_x.png" /><span style="display: none"
                      >disabled</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Main View agents -->

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle mb-5">
            <span class="name"><h1 class="subtitle">Agent Status</h1></span>
            <span class="option">
              <button class="button is-success ml-5" @click="refreshAgents()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="chocolateyAgentsTable">
              <thead>
                <tr>
                  <th style="width: 20px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 30px"><va-icon class="icon ion-md-apps"></va-icon></th>
                  <th>Agent Name</th>
                  <th>Location</th>
                  <th>Chocolatey</th>
                  <th>Up To Date</th>
                  <th>Last Update</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                      <AgentMenu :agent="agent" />
                    </td>
                    <td>{{ agent.computerName }}</td>
                    <td>{{ agent.locationName }}</td>
                    <td>{{ myAgentChocoVersion(agent.computerID) }}</td>
                    <td>{{ myAgentPackagePercent(agent.computerID) }}% ({{ myAgentUpdateCount(agent.computerID) }})</td>
                    <td>{{ myAgentLastScan(agent.computerID) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <div v-if="myAgentIsEnabled(agent.computerID)">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </div>
                      <div v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </div>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                        <AgentMenu :agent="agent" />
                      </td>
                      <td>{{ agent.computerName }}</td>
                      <td>{{ agent.locationName }}</td>
                      <td>{{ myAgentChocoVersion(agent.computerID)}}</td>
                      <td>{{ myAgentPackagePercent(agent.computerID) }}% ({{ myAgentUpdateCount(agent.computerID) }})</td>
                      <td>{{ myAgentLastScan(agent.computerID) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <div v-if="myAgentIsEnabled(agent.computerID)">
                          <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span
                          >
                        </div>
                        <div v-else>
                          <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span
                          >
                        </div>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </template>
          <template>
            <p>No agents were returned for client.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
      <!-- end Main View card -->
    </div>
  </div>
</template>

<script>
import { doClientWork } from "@/workers/chocolatey/choco-client-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
import AgentMenu from "@/components/layout/AgentMenu-Windows";
import ClientMenu from "@/components/layout/ClientMenu";
export default {
  name: "ChocolateyClientManager",
  components: {
    AgentMenu,
    ClientMenu,
  },
  data() {
    return {
      num_locations: 0,
      locations: [],
      schedule: {},
      schedules: [],
      showApprovedPackages: false,
      installApprovedPackage: "",
      installPackageName: "",
      errors: [],
      agents: [],
      agentsConfigs: [],
      agentConfig: {},
      clientLogs: [],
      siteID: "",
      num_agents: 0,
      selected: [],
      selectAll: false,
      packages: [],
      currentAvailablePackages: [],
      sites: [],
      site: {},
      sources: [],
      source: {},
      chocoConfig: {},
      package: {},
      client: {},
      clients: [],
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.closeForm();
    this.getTeam();
    this.getClients();
    this.getAgents();
    this.getLocations();
    this.getSchedules();
    this.getSites();
    this.getSources();
    this.getPackages();
    this.getClientLogs();
    this.getCurrentAvailablePackages();
  },
  methods: {
      getAgentNameByID(computerID) {
      for (let i in this.agents) {
        if (this.agents[i].computerID === computerID) {
          return this.agents[i].computerName;
        }
      }
    },
    myAgentIsEnabled(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isEnabled;
        }
      }
    },
    myAgentLastScan(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.format_date(this.agentsConfigs[i].lastUpdate);
        }
      }
    },
    myAgentIsCurrent(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isCurrent;
        }
      }
    },
    myAgentUpdateCount(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].updateCount;
        }
      }
    },
    myAgentChocoVersion(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          if(this.agentsConfigs[i].chocoInstalled == true){
              return this.agentsConfigs[i].chocoVersion;
          }else{
              return "Not Installed"
          }
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async seedClient() {
      if (confirm("Would you like to seed the client's approved packages list\nwith the most common packages requested?") == true) {
        const clientID = this.$route.params.id;
        await axios
        .get(`/api/v1/chocolatey/seed_approved_packages/${clientID}/`)
        .then((response) => {
        toast({
              message: "Client packages now seeded successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });  
            this.refreshPackages();        
        })
        .catch((error) => {
          toast({
              message: "Client packages seeding failed.",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          console.log(error);
        });

      } else {
        toast({
              message: "Approved package seeding was canceled",
              type: "is-warning",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
      }
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        await axios
        .get(`/api/v1/chocolatey/get_chocolatey_config/${clientID}/`)
        .then((response) => {
          this.chocoConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Refresh Agents
    refreshAgents() {
      this.closeForm();
      this.refreshAgents();
      this.getSites();
      this.refreshPackages();
      this.getCurrentAvailablePackages();
    },
    getSiteManager(siteID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].siteID == siteID) {
          if (this.agentsConfigs[i].cacheManager == true) {
            for (let x in this.agents) {
              if (this.agents[x].computerID == this.agentsConfigs[i].computerID) {
                return this.agents[x].computerName;
              }
            }
          }
        }
      }
      return "None";
    },
    myAgentPackagePercent(computerID) {
      let uc = 0;
      let pc = 0;
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          uc = this.agentsConfigs[i].updateCount;
          pc = this.agentsConfigs[i].packageCount;
          break; // Exit loop once the values are found
        }
      }
      if (isNaN(uc) || isNaN(pc)) {
        return 0;
      } else if((uc == 0)){
        return 100;
      } else if((pc == 0)){
        return 0;
      } else {
        return Math.round(100 - ((uc/pc)*100));
      }
    },
    async editSite(siteIndex) {
      this.site = this.sites[siteIndex];
      //console.log("Edit Site Cache " + this.site.siteName);
    },
    async editSource(sourceIndex) {
      this.source = this.sources[sourceIndex];
      //console.log("Edit Site Cache " + this.site.siteName);
    },
    async deleteSite(siteIndex) {
      this.errors = [];
      const mysite = this.sites[siteIndex];
      //console.log("Delete Site Cache " + mysite.siteName);
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/delete_site/", mysite)
          .then((response) => {
            this.getSites();
            toast({
              message: "Removed site successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },
    async deleteSource(Index) {
      this.errors = [];
      const mysource = this.sources[Index];
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/delete_source/", mysource)
          .then((response) => {
            this.getSources();
            toast({
              message: "Removed source successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },
    async enablePackage(packageIndex) {
      if (this.packages[packageIndex].isEnabled == false) {
        this.packages[packageIndex].isEnabled = true;
      } else {
        this.packages[packageIndex].isEnabled = false;
      }
      this.packages[packageIndex].pushToAllClients = false;
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/chocolatey/edit_chocolatey_package/", this.packages[packageIndex])
        .then((response) => {
          this.refreshPackages();
          toast({
            message: "Chocolatey package updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.getPackages();
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              /* this.errors.push(`${property}: ${error.response.data[property]}`); */
            }
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    async editPackage(packageIndex) {
      this.package = this.packages[packageIndex];
      //console.log("Edit package " + this.package.appName);
    },
    async deletePackage(packageIndex) {
      const mypackage = {
        packageName: this.packages[packageIndex].packageName,
        id: this.packages[packageIndex].id,
      };
      //console.log("Delete package " + mypackage);

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/delete_approved_package/", mypackage)
          .then((response) => {
            this.getPackages();
            toast({
              message: "Removed approved package successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },
    async upgradeAllAgents(packageIndex) {
      var agents = [];
      for (let i in this.agents) {
        agents.push(this.agents[i].computerID);
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: agents,
          mypackage: this.packages[packageIndex].packageName,
        };
        await doClientWork(token, "upgradeAllAgents", CMDDATA);
        this.getPackages();
        toast({
          message: "Upgrade package on all agents sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    async installAllAgents(packageIndex) {
      var agents = [];
      for (let i in this.agents) {
        agents.push(this.agents[i].computerID);
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: agents,
          mypackage: this.packages[packageIndex].packageName,
        };
        await doClientWork(token, "installAllAgents", CMDDATA);
        this.getPackages();
        toast({
          message: "Install package on all agents sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    async removeAllAgents(packageIndex) {
      var agents = [];
      for (let i in this.agents) {
        agents.push(this.agents[i].computerID);
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: agents,
          mypackage: this.packages[packageIndex].packageName,
        };
        await doClientWork(token, "removeAllAgents", CMDDATA);
        this.getPackages();
        toast({
          message: "Remove package on all agents sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },

    //Get Users Team
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        setTimeout(function () {
            $("#chocolateyAgentsTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
      this.$store.commit("setIsLoading", false);
    },
    async refreshAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

    // Get locations Functions
    async getLocations() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/locations_by_client/${clientID}`)
        .then((response) => {
          this.num_locations = response.data.length;
          this.locations = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    // Get all agent logs for client Functions
    async getClientLogs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_client_logs/${clientID}`)
        .then((response) => {   
          this.clientLogs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        setTimeout(function () {
            $("#chocolateyClientErrorLogsTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
      this.$store.commit("setIsLoading", false);
    },

    // Get Sites Data Function
    async getSites() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_sites/${clientID}/`)
        .then((response) => {
          this.sites = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Sources Data Function
    async getSources() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_sources/`)
        .then((response) => {
          this.sources = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Package Data function
    async getPackages() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_packages/${clientID}/`)
        .then((response) => {
          this.packages = response.data;
          setTimeout(function () {
            $("#approvedPackagesTable").DataTable({
              pagingType: "full_numbers",
              pageLength:50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
        // Get Package Data function
      async refreshPackages() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_packages/${clientID}/`)
        .then((response) => {
          this.packages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Current Available Package Data function
    async getCurrentAvailablePackages() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_current_package_versions/`)
        .then((response) => {
          this.currentAvailablePackages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //Enable Sites Function
    async enableSite(siteIndex) {
      this.errors = [];
      const mysite = this.sites[siteIndex];
      //console.log("Updating site  " + mysite.siteName);
      const siteData = '{ "id": ' + mysite.id + "}";
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/enable_site/`, siteData)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.getSites();

            toast({
              message: "Site status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    //Enable Source Function
    async enableSource(sourceIndex) {
      this.errors = [];
      const mysource = this.sources[sourceIndex];
      //console.log("Updating site  " + mysite.siteName);
      const Data = '{ "id": ' + mysource.id + "}";
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/enable_source/`, Data)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.getSources();

            toast({
              message: "Source status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Enable Agents Function
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgents();

            toast({
              message: "Agent enabled status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    //set Agent Site Function
    async setAgentSite(siteIndex) {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      const mysite = this.sites[siteIndex];
      const mySiteID = mysite.id;
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/set_agents_site/${mySiteID}/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgents();

            toast({
              message: "Site caching status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },

    //set Agent Source Function
    async setAgentSource(Index) {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      const mysource = this.sources[Index];
      const mySourceID = mysource.id;
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/set_agents_source/${mySourceID}/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgents();

            toast({
              message: "Source repo status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    //Update Site Cache Function
    async updateSiteCache(siteIndex) {
      this.errors = [];
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          mysite: this.sites[siteIndex].id,
        };
        await doClientWork(token, "updateSiteCache", CMDDATA);
        toast({
          message: "Update package cache status sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    // MSP license Agent Function
    async licenseAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
        };
        await doClientWork(token, "licenseAgents", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Licensing Chocolatey Agents queued successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    //Remove MSP License function
    async mspLicenseRemove() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
        };
        await doClientWork(token, "mspLicenseRemove", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Removing licensing  from Chocolatey Agents has queued successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    // Install Chocolatey to Agents function
    async installChocolateyToAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
        };
        await doClientWork(token, "installChocolateyToAgents", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Installing Chocolatey To Agents has queued successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    // Set Agents to StandAlone function
    async setAgentsAsStandAlone() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/set_agents_as_standalone/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;

            toast({
              message: "Switching agents as standalone successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Set Agents to cache manager function
    async setAgentsAsCacheManager() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);

        await axios
          .post(`/api/v1/chocolatey/set_agents_as_cache_manager/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshAgents();
            toast({
              message: "Switching agents as caching manager successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Submit Site Cache Form
    async submitSiteCacheForm() {
      this.errors = [];
      //console.log(this.siteID.length);
      if (this.siteID.length === 0) {
        this.errors.push("You need to select caching site from the list and try again.");
      }
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }

      this.agentConfig.siteID = this.siteID;
      this.agentConfig.standAlone = false;
      this.agentConfig.clientID = this.$route.params.id;

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        this.agentConfig.computerID = this.selected;
        await axios
          .post("/api/v1/chocolatey/set_chocolatey_sites/", this.agentConfig)
          .then((response) => {
            document.getElementById("mySiteCacheForm").style.display = "none";
            this.selected = [];
            this.selectAll = false;

            toast({
              message: "Agent site cache locations updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },

    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);
      this.chocoConfig.clientID = this.$route.params.id;
      if (this.chocoConfig.schedule === undefined) {
        this.chocoConfig.schedule = "Monthly";
      }
      if (this.chocoConfig.schedule === "") {
        this.chocoConfig.schedule = "Monthly";
      }
      if (this.chocoConfig.scheduleDay === undefined) {
        this.chocoConfig.scheduleDay = "1";
      }
      if (this.chocoConfig.scheduleDay === null) {
        this.chocoConfig.scheduleDay = "1";
      }
      //console.log(this.chocoConfig.schedule);
      await axios
        .post("/api/v1/chocolatey/edit_chocolatey_config/", this.chocoConfig)
        .then((response) => {
          //console.log(response.data);
          toast({
            message: "Chocolatey configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //Submit Site Form Function
    async submitSiteForm() {
      this.errors = [];
      this.site.clientID = this.$route.params.id;
      if (this.site.isEnabled === undefined) {
        this.site.isEnabled = false;
      }
      if (this.site.userName === undefined) {
        this.errors.push("Username is missing");
      }
      if (this.site.networkShare === undefined) {
        this.errors.push("Network share location is missing");
      }
      if (this.site.siteName === undefined) {
        this.errors.push("Site name is missing");
      }
      if (this.site.userPass === undefined) {
        this.errors.push("Password is missing");
      }

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/edit_chocolatey_site/", this.site)
          .then((response) => {
            this.site = {};

            toast({
              message: "Chocolatey site cache updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.getSites();
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    //Submit Source Form Function
    async submitSourceForm() {
      this.errors = [];
      if (this.source.isEnabled === undefined) {
        this.source.isEnabled = false;
      }
      if (this.source.userName === undefined) {
        //this.errors.push("Username is missing");
      }
      if (this.source.URL === undefined) {
        this.errors.push("Source URL is missing");
      }
      if (this.source.name === undefined) {
        this.errors.push("Source name is missing");
      }
      if (this.source.userPass === undefined) {
        //this.errors.push("Password is missing");
      }

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/edit_chocolatey_source/", this.source)
          .then((response) => {
            this.source = {};

            toast({
              message: "Chocolatey source repo updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.getSources();
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Submit Package Form function
    async submitPackageForm() {
      this.errors = [];
      if (this.package.packageName === undefined) {
        this.errors.push("Chocolatey package name is missing");
      }
      if (this.package.isEnabled === undefined) {
        this.package.isEnabled = false;
      }
      if (this.package.pushToAllClients === undefined) {
        this.package.pushToAllClients = false;
      }
      //console.log(this.package.pushToAllClients);
      if (!this.errors.length) {
        this.package.appName = this.package.packageName;
        this.package.clientID = this.$route.params.id;
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/edit_chocolatey_package/", this.package)
          .then((response) => {
            toast({
              message: "Chocolatey package updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.getPackages();
            this.package = {};
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async installPackageForm() {
      this.errors = [];
      let install_package = "";
      if (this.installApprovedPackage === undefined) {
        if (this.installPackageName === undefined) {
          this.errors.push(
            "You must eiter select a approved package or provide a package name to install."
          );
        }
      }
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (this.installPackageName) {
        install_package = this.installPackageName;
        this.installPackageName = '';
      } else {
        install_package = this.installApprovedPackage;
      }

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
          install_package: install_package,
        };
        await doClientWork(token, "installChocoPackageToAgents", CMDDATA);
        this.showApprovedPackages = false;
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Chocolatey agent sent install request",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    openForm() {
      document.getElementById("mySiteCacheForm").style.display = "block";
    },
    closeForm() {
      document.getElementById("mySiteCacheForm").style.display = "none";
    },

    //Purge agent data  function
    async purgeClientPackageData() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/purge_chocolatey_client_packages/${clientID}/`)
        .then((response) => {
          toast({
            message: "Chocolatey agent data purged",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //install Approved Client Packages
    async installClientApprovedPackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
        };
        await doClientWork(token, "installClientApprovedPackages", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Installing approved packages to agent(s) sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    //Update Approved Client Packages
    async upgradeClientApprovedPackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
        };
        await doClientWork(token, "upgradeClientApprovedPackages", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Updating approved packages to agent(s) sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    //Remove Approved Client Packages
    async removeClientApprovedPackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected,
        };
        await doClientWork(token, "removeClientApprovedPackages", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Removing approved packages from agent(s) sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    //Test Chocolatey packages are current
    async testChocolateyPackageVersionsCurrent() {
      this.errors = [];
      const clientID = this.$route.params.id;
      const Data = { clientID: clientID };
      this.$store.commit("setIsLoading", true);
      await axios
        .post(`/api/v1/chocolatey/test_chocolatey_package_versions_current/`, Data)
        .then((response) => {
          this.selected = [];
          this.selectAll = false;
          this.refreshAgents();

          toast({
            message: "Package Version testing completed successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              /* this.errors.push(`${property}: ${error.response.data[property]}`); */
            }
            //console.log("selected " + selected);
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            //console.log("selected " + selected);
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    async scanClient() {
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      const CMDDATA = {
        clientID: this.$route.params.id,
        axiosBaseURL: axios.defaults.baseURL,
      };
      await doClientWork(token, "scanClient", CMDDATA);
      toast({
        message: "Sent sync request to client successfully",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
      this.$store.commit("setIsLoading", false);
    },
    async updateClient() {
      this.errors = [];

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
        };
        await doClientWork(token, "updateClient", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "Updating client sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    //Scheduler functions

    editSchedule(index) {
      this.schedule = this.schedules[index];
    },
    //enable/disable schedule
    async enableSchedule(index) {
      if (this.schedules[index].isEnabled == false) {
        this.schedules[index].isEnabled = true;
      } else {
        this.schedules[index].isEnabled = false;
      }
      this.$store.commit("setIsLoading", true);
      await axios
        .post(
          "/api/v1/chocolatey/edit_chocolatey_client_schedule/",
          this.schedules[index]
        )
        .then((response) => {
          this.refreshSchedules();
          toast({
            message: "Schedule updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.getPackages();
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by API");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    async deleteSchedule(index) {
      const mySchedule = {
        id: this.schedules[index].id,
        name: this.schedules[index].location,
      };
      console.log("Delete schedule" + mySchedule);
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/delete_chocolatey_client_schedule/", mySchedule)
          .then((response) => {
            this.getSchedules();
            toast({
              message: "Removed schedule successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by API");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },

    // Submit schedule Form function
    async submitScheduleForm() {
      this.errors = [];
      this.schedule.clientID = this.$route.params.id;

      if (this.schedule.location === undefined) {
        this.errors.push("Location is missing");
      }
      if (this.schedule.isEnabled === undefined) {
        this.schedule.isEnabled = false;
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/edit_chocolatey_client_schedule/", this.schedule)
          .then((response) => {
            toast({
              message: "Schedule updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.getSchedules();
            this.schedule = {};
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by API");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Get Schedule Data function
    async getSchedules() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_client_schedules/${clientID}/`)
        .then((response) => {
          this.schedules = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

        // Push Client Sources to agents function
      async pushClientSources() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/update_client_sources/${clientID}/`)
        .then((response) => {
          toast({
              message: "Push sources request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          toast({
              message: "Push sources request failed to send",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style>
span.a {
  display: inline-block; /* the default for span */
  width: 20%;
  height: 100px;
  padding: 5px;
}
</style>

<template>
  <div class="dropdown">
    <button class="dropbtn">
      <va-icon class="icon ion-md-apps"></va-icon>
    </button>
    <div class="dropdown-content">
      <button class="button is-light" @click="pushManagementPackageToClient">
        <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Push Out Management
        Package
      </button>
      <router-link :to="{ name: 'Agents', params: { id: client.clientID } }"
        ><va-icon class="icon ion-md-albums mr-1"></va-icon> Agents</router-link
      >

      <router-link
        :to="{
          name: 'ChocolateyClientManager',
          params: { id: client.clientID },
        }"
        ><va-icon class="icon ion-md-planet mr-1"></va-icon> Chocolatey
        Manager</router-link
      >
      <router-link
        :to="{
          name: 'SurfLogClientManager',
          params: { id: client.clientID },
        }"
        ><va-icon class="icon ion-md-pie mr-1"></va-icon> SurfLog Manager</router-link
      >
      <router-link
        :to="{
          name: 'BitLockerClientManager',
          params: { id: client.clientID },
        }"
        ><va-icon class="icon ion-md-unlock mr-1"></va-icon> BitLocker
        Manager</router-link
      >
      <router-link
        :to="{
          name: 'BleachBitClientManager',
          params: { id: client.clientID },
        }"
        ><va-icon class="icon ion-md-trash mr-1"></va-icon> BleachBit Manager</router-link
      >
      <router-link
        :to="{
          name: 'DefenderClientManager',
          params: { id: client.clientID },
        }"
        ><va-icon class="icon ion-md-medkit mr-1"></va-icon> Defender Manager
      </router-link>
      <router-link
        :to="{
          name: 'LinuxUpdateClientManager',
          params: { id: client.clientID },
        }"
        ><img src="/images/tux-18.png" class="ml-2" />&nbsp;&nbsp;&nbsp;Linux Update
        Manager
      </router-link>
      <router-link
        :to="{
          name: 'BrewClientManager',
          params: { id: client.clientID },
        }"
        ><va-icon class="icon ion-md-beer mr-1"></va-icon> HomeBrew Manager</router-link
      >
    </div>
  </div>
</template>
<script>
import { doClientWork } from "@/workers/surflog/surflog-client-api";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "ClientMenu",
  props: {
    client: Object,
  },
  methods: {
    // Push Management Package to client
    async pushManagementPackageToClient() {
      this.errors = [];
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clients: [this.client.clientID],
          axiosBaseURL: axios.defaults.baseURL,
        };
        await doClientWork(token, "pushManagementPackageToClient", CMDDATA);
        toast({
          message: "Push request sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>
<style>
/* Dropdown Button */
.dropbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 275px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 275px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content1 a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content1 {
  display: block;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #ccc;
}
</style>

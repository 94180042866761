<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="buttons">
          <router-link
            :to="{
              name: 'SurfLogClientManager',
              params: { id: this.$route.params.id },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Client Manager
          </router-link>
          <button class="button" @click="getYesterdayLogs">
            <va-icon class="icon ion-md-list mr-1"></va-icon> Yesterday
          </button>
        </div>
      </div>

      <div class="column is-12">
        <div class="card card-body">
          <h1 class="subtitle">Raw Web Logs</h1>
          <p class="mb-3 mt-3">
            This log view shows all logs collected for the curent date collected by
            SurfLog scans. You can select the "Yesterday" button to get all log files
            collectd with yesterday's date. If you want to see all the logs collected then
            you will need to select an agent and view the logs at the agent level.
          </p>
          <table class="table" id="webLogs">
            <thead>
              <tr>
                <th width="20px"></th>
                <th>Title</th>
                <th>URL</th>
                <th>Date</th>
                <th>User</th>
                <th>Computer</th>
                <th>Visits</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in clientLogs" v-bind:key="log.id">
                <td v-if="log.webBrowser === 'Chrome'">
                  <img src="/images/chrome.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else-if="log.webBrowser === 'Firefox'">
                  <img src="/images/firefox.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else-if="log.webBrowser === 'Internet Explorer 10/11 / Edge'">
                  <img src="/images/edge.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else-if="log.webBrowser === 'Moon'">
                  <img src="/images/moon.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else-if="log.webBrowser === 'Yandex'">
                  <img src="/images/Yandex.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else-if="log.webBrowser === 'Safari'">
                  <img src="/images/safari.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else-if="log.webBrowser === 'Internet Explorer'">
                  <img src="/images/ie.png" alt="{{ log.webBrowser }}" />
                </td>
                <td v-else><img src="/images/chromium.png" /></td>
                <td>
                  {{ log.title }}
                </td>
                <td>
                  {{ log.URL }}
                </td>
                <td>{{ format_date(log.visitTime) }}</td>
                <td>{{ log.userProfile }}</td>
                <td>{{ myAgentName(log.computerID) }}</td>
                <td>{{ log.visitCount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "SurfLogClientRawLogs",

  data() {
    return {
      clientLogs: [],
      agents: [],
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getClientLogs();
    this.getAgents();
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    myAgentName(computerID) {
      for (let i in this.agents) {
        if (this.agents[i].computerID === computerID) {
          return this.agents[i].computerName;
        }
      }
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClientLogs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_surflog_client_logs/${clientID}/`)
        .then((response) => {
          this.clientLogs = response.data;
          setTimeout(function () {
            $("#webLogs").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getYesterdayLogs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/surflog/get_surflog_client_logs_yesterday/${clientID}/`)
        .then((response) => {
          this.clientLogs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style>
table {
  width: 100%;
  table-layout: fixed;
}
</style>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="notification is-danger mt-6" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <h1 class="title">Sync RMM infomation to RMMMax</h1>
        <p>
          RMMMax runs automated services that will execute these functions on a regular
          bases once your RMM has been correctly configured, you can also use the
          functions below to manually sync the different tools to get a current up to the
          minute refresh of your data.
        </p>
        <p>
          These tools will push a background worker to start processing each client that
          is enabled in your environment. The environment will update as allowed by your
          configurations settings for clients and agents. If a client or agent is not
          enabled for a given tool then it will be skipped during the syncing process. Any
          agents not online will be skipped if any sync function requires direct
          communications with agent via RMM platform requests.
        </p>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync Clients & Agents</h2>
          <p class="mb-3">
            If you are just starting out or have purged client data then selecting
            Synchronize Clients and Computers will go collect the current client and agent
            data needed to populate the tool.
          </p>
          <div class="buttons justify-content-center">
            <button @Click="syncRMM()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize Clients and Computers
            </button>
          </div>
        </div>
        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync SurfLog</h2>
          <p class="mb-3">
            This will send out the command to all SurfLog enabled and configured agents to
            send back their current data for the last 24 hours.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncSurflog()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize Surflogs
            </button>
          </div>
        </div>
        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync BitLocker</h2>
          <p class="mb-3">
            This will send out the command to all BitLocker enabled and configured agents
            to send back their current data and status.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncBitLocker()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize BitLocker Volumes
            </button>
          </div>
        </div>
        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync BleachBit</h2>
          <p class="mb-3">
            This will send out the command to all BleachBit enabled and configured agents
            to send back their current data and status.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncBleachBit()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize BleachBit Options
            </button>
          </div>
        </div>
        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync Windows Defender</h2>
          <p class="mb-3">
            This will send out the command to all Defender enabled and configured agents
            to send back their current data and status.
          </p>
          <div class="buttons justify-content-center">
            <button @Click="syncDefender()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize Defender Status
            </button>
          </div>
        </div>

        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync Chocolatey</h2>
          <p class="mb-3">
            This will send out the command to all Chocolatey enabled and configured agents
            to send back their installed software and chocolatey package data.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncInstalledPackages()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize Chocolatey
            </button>
          </div>
        </div>
        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync Linux Updates</h2>
          <p class="mb-3">
            This will send out the command to all Linux Update Manager enabled and
            configured agents to send back their currect update statuses.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncLinuxUpdates()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize Linux Updates
            </button>
          </div>
        </div>
        <div class="mt-3"></div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync HomeBrew</h2>
          <p class="mb-3">
            This will send out the command to all HomeBrew enabled and configured agents
            to send back their currect update statuses.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncHomeBrew()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize HomeBrew
            </button>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="card card-body">
          <h2 class="mb-3">Sync Device States</h2>
          <p class="mb-3">
            This will send out the command to all windows agents to capture the current
            device state.
          </p>

          <div class="buttons justify-content-center">
            <button @Click="syncDeviceState()" class="button is-success">
              <va-icon class="icon ion-md-cloud-download mr-1"></va-icon>
              Synchronize Device State
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import axios from "axios";
import { doHardWork } from "@/workers/rmmsync/worker-api";
export default {
  name: "SyncRMM",
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    async syncRMM() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "rmmSync", axios.defaults.baseURL);
      toast({
        message: "RMM sync of clients and Computers has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncSurflog() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncSurflog", axios.defaults.baseURL);
      toast({
        message: "RMM sync of the Surflog environment has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncBitLocker() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncBitLocker", axios.defaults.baseURL);
      toast({
        message: "RMM sync of the BitLocker environment has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncBleachBit() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncBleachBit", axios.defaults.baseURL);
      toast({
        message: "RMM sync of the BleachBit environment has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncDefender() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncDefender", axios.defaults.baseURL);
      toast({
        message: "RMM sync of the Defender environment has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncInstalledPackages() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncInstalledPackages", axios.defaults.baseURL);
      toast({
        message: "RMM sync for installed Chocolatey packages has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncLinuxUpdates() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncLinuxUpdates", axios.defaults.baseURL);
      toast({
        message: "RMM sync for Linux Update Manager has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncHomeBrew() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncHomeBrew", axios.defaults.baseURL);
      toast({
        message: "RMM sync for HomeBrew Manager has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncDeviceState() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncDeviceState", axios.defaults.baseURL);
      toast({
        message: "RMM sync for Agent Device State has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
    async syncMasterVersions() {
      const token = localStorage.getItem("token");
      await doHardWork(token, "syncMasterVersions", axios.defaults.baseURL);
      toast({
        message: "RMM sync for Master Versions has been queued.",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
    },
  },
};
</script>

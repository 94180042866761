<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">Defender Agent Manager for {{ agent.computerName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <router-link
            :to="{
              name: 'DefenderClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Agents</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentPrefsForm"
            aria-expanded="false"
            aria-controls="agentPrefsForm"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon>Preferences
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentExclusionsForm"
            aria-expanded="false"
            aria-controls="agentExclusionsForm"
          >
            <va-icon class="icon ion-md-warning mr-1"></va-icon>Exclusions
          </button>
          <!--- Scan Client button does not pass job to worker!!
          <button class="button is-success" @click="scanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan Client
          </button>  --->
          <button class="button is-success" @click="refreshStatus">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Rescan Agent
          </button>
          <button class="button is-success" @click="runQuickScan">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Run Quick Scan
          </button>
          <button class="button is-success" @click="runFullScan">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Run Full Scan
          </button>
        </div>
      </div>
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin2Days(agentConfig.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>

          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Quick Scan:</strong></p></span
            >
            <span class="option">
              <template
                v-if="checkDateWithin2Days(agentStatus.QuickScanEndTime) === false"
              >
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentStatus.QuickScanEndTime) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentStatus.QuickScanEndTime) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Full Scan:</strong></p></span
            >
            <span class="option">
              <template
                v-if="checkDateWithin30Days(agentStatus.FullScanEndTime) === false"
              >
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentStatus.FullScanEndTime) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentStatus.FullScanEndTime) }}
                </p></template
              >
            </span>
          </div>

          <template v-if="agentConfig.isEnabled === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- start agentPrefs dropdown -->
      <div class="column is-12">
        <div class="collapse" id="agentPrefsForm">
          <div class="card card-body">
            <h1 class="subtitle">Preferences and Options</h1>
            <p class="mb-5">
              Configure the preferences for Windows Defender scans and updates.
            </p>

            <form @submit.prevent="submitPrefsForm">
              <div class="field-group1">
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.AllowDatagramProcessingOnWinServer"
                      />Allow Datagram Processing On Windows Server</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.AllowNetworkProtectionDownLevel"
                      />Allow Network Protection Down Level</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.AllowNetworkProtectionOnWinServer"
                      />Allow Network Protection On Windows Server</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.CheckForSignaturesBeforeRunningScan"
                      />Check For Signatures Before Running Scan</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DefinitionUpdatesChannel"
                      />Definition Updates Channel</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableArchiveScanning"
                      />Disable Archive Scanning</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableAutoExclusions"
                      />Disable Auto Exclusions</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableBehaviorMonitoring"
                      />Disable Behavior Monitoring</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableBlockAtFirstSeen"
                      />Disable Block At First Seen</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableCatchupFullScan"
                      />Disable Catchup Full Scan</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableCatchupQuickScan"
                      />Disable Catchup Quick Scan</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableCpuThrottleOnIdleScans"
                      />Disable Cpu Throttle On Idle Scans</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableDatagramProcessing"
                      />Disable Datagram Processing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableDnsOverTcpParsing"
                      />Disable Dns Over Tcp Parsing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableDnsParsing"
                      />Disable Dns Parsing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableEmailScanning"
                      />Disable Email Scanning</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableFtpParsing"
                      />Disable Ftp Parsing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableGradualRelease"
                      />Disable Gradual Release</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableHttpParsing"
                      />Disable Http Parsing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableInboundConnectionFiltering"
                      />Disable Inbound Connection Filtering</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableIntrusionPreventionSystem"
                      />Disable Intrusion Prevention System</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableIOAVProtection"
                      />Disable IO AV Protection</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableNetworkProtectionPerfTelemetry"
                      />Disable Network Protection Perf Telemetry</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisablePrivacyMode"
                      />Disable Privacy Mode</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableRdpParsing"
                      />Disable Rdp Parsing</label
                    >
                  </div>
                </div>
              </div>
              <div class="field-group1">
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableRealtimeMonitoring"
                      />Disable Realtime Monitoring</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableRemovableDriveScanning"
                      />Disable Removable Drive Scanning</label
                    >
                  </div>
                </div>

                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableRestorePoint"
                      />Disable Restore Point</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableScanningMappedNetworkDrivesForFullScan"
                      />Disable Scanning Mapped Network Drives For Full Scan</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableScanningNetworkFiles"
                      />Disable Scanning Network Files</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableScriptScanning"
                      />Disable Script Scanning</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableSshParsing"
                      />Disable SSH Parsing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableTlsParsing"
                      />Disable TLS Parsing</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.EnableControlledFolderAccess"
                      />Enable Controlled Folder Access</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.EnableFileHashComputation"
                      />Enable File Hash Computation</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.EnableFullScanOnBatteryPower"
                      />Enable Full Scan On Battery Power</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.EnableLowCpuPriority"
                      />Enable Low Cpu Priority</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.EnableNetworkProtection"
                      />Enable Network Protection</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.EnableDnsSinkhole"
                      />Enable DNS Sinkhole</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.ForceUseProxyOnly"
                      />Force Use Proxy Only</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.MeteredConnectionUpdates"
                      />Metered Connection Updates</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.RandomizeScheduleTaskTimes"
                      />Randomize Schedule Task Times</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.ScanAvgCPULoadFactor"
                      />Scan Avg CPU Load Factor</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.ScanOnlyIfIdleEnabled"
                      />Scan Only If Idle Enabled</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.SignatureDisableUpdateOnStartupWithoutEngine"
                      />Signature Disable Update On Startup Without Engine</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.ThrottleForScheduledScanOnly"
                      />Throttle For Scheduled Scan Only</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.UILockdown"
                      />UI Lockdown</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.AllowSwitchToAsyncInspection"
                      />Allow Switch To Async Inspection</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.DisableTDTFeature"
                      />Disable TDT Feature</label
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentPrefs.ReportDynamicSignatureDroppedEvent"
                      />Report Dynamic Signature Dropped Event</label
                    >
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-success mt-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#agentPrefsForm"
                    aria-expanded="false"
                    aria-controls="agentPrefsForm"
                  >
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end agentPrefsFrom -->
      <!-- start agentExclusions dropdown -->
      <div class="column is-12">
        <div class="collapse" id="agentExclusionsForm">
          <div class="card card-body">
            <h1 class="subtitle">Add Exclusions</h1>
            <p class="mb-3">
              Manage client wide exclusions for all agents. Exclusions created here will
              be applied to all enabled agents.
            </p>
            <form @submit.prevent="submitExclusionsForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="exclusionForm.isFile"
                    />Attack Surface Reduction Only Exclusion</label
                  >
                </div>
              </div>
              <div class="field">
                <label>Exclusion (Full path to file or folder)</label>
                <div class="control">
                  <input
                    type="text"
                    name="name"
                    class="input"
                    v-model="exclusionForm.fileFolder"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-success"
                    data-bs-toggle="collapse"
                    data-bs-target="#agentExclusionsForm"
                    aria-expanded="false"
                    aria-controls="agentExclusionsForm"
                  >
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
            <h1 class="subtitle mt-5">Exclusions</h1>
            <p class="mb-1">
              The following entries repersent the current exclusions for this agent. There
              are two types of exclusions, the common file and folder exclusions and
              Attack Surface Reduction Only exclusions. For ASR rules, if you add one
              exclusion, it will affect every ASR rule.
            </p>
            <hr />
            <div class="buttons">
              <button class="button is-success" @click="pushExclusions">
                <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Push Exclusions
              </button>
              <button class="button is-danger" @click="deleteExclusions">
                <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Delete Exclusions
              </button>
            </div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>
                    <label class="form-checkbox">
                      <input
                        type="checkbox"
                        v-model="selectExclAll"
                        @click="selectExcl"
                      />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th>Type</th>
                  <th>Exclude</th>
                  <th>Client Managed</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ex in agentExclusions" v-bind:key="ex.id">
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" :value="ex.id" v-model="selectExcled" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </td>
                  <td v-if="ex.isFile == true">
                    <va-icon class="icon ion-md-copy mr-1"></va-icon>
                  </td>
                  <td v-else>
                    <va-icon class="icon ion-md-folder-open mr-1"></va-icon>
                  </td>
                  <td>{{ ex.fileFolder }}</td>
                  <td>
                    <img
                      v-if="ex.isClientManaged === true"
                      class="icon ion-md-done-all mr-1"
                      src="/images/green_check.png"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end agentExclusions dropdown -->

      <div class="column is-3">
        <div class="card card-body">
          <h2 class="subtitle mt-3">
            <va-icon class="icon ion-md-analytics"></va-icon> Engine
          </h2>
          <div class="boxdiv mb-3" v-if="agentStatus.AMServiceEnabled">
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/green_check.png" /></span>
          </div>
          <div class="boxdiv mb-3" v-else>
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/red_x.png" /></span>
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">AM Running Mode: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: small">{{
                agentStatus.AMRunningMode
              }}</small></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">AM Engine Ver: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                agentStatus.AMEngineVersion
              }}</small></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">AM Product Ver: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                agentStatus.AMProductVersion
              }}</small></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">NIS Engine Ver: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                agentStatus.NISEngineVersion
              }}</small></span
            >
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="card card-body">
          <h2 class="subtitle mt-3">
            <va-icon class="icon ion-md-analytics"></va-icon> Antispyware
          </h2>
          <div class="boxdiv mb-3" v-if="agentStatus.AntispywareEnabled">
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/green_check.png" /></span>
          </div>
          <div class="boxdiv mb-3" v-else>
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/red_x.png" /></span>
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Signature Age: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small"
                >{{ agentStatus.AntispywareSignatureAge }} days</small
              ></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Last Updated: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                format_date(agentStatus.AntispywareSignatureLastUpdated)
              }}</small></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Signature Ver: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                agentStatus.AntispywareSignatureVersion
              }}</small></span
            >
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="card card-body">
          <h2 class="subtitle mt-3">
            <va-icon class="icon ion-md-analytics"></va-icon> Antivirus
          </h2>
          <div class="boxdiv mb-3" v-if="agentStatus.AntivirusEnabled">
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/green_check.png" /></span>
          </div>
          <div class="boxdiv mb-3" v-else>
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/red_x.png" /></span>
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Signature Age: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small"
                >{{ agentStatus.AntivirusSignatureAge }} days</small
              ></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Last Updated: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                format_date(agentStatus.AntivirusSignatureLastUpdated)
              }}</small></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Signature Ver: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                agentStatus.AntivirusSignatureVersion
              }}</small></span
            >
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="card card-body">
          <h2 class="subtitle mt-3">
            <va-icon class="icon ion-md-analytics"></va-icon> NIS
          </h2>
          <div class="boxdiv mb-3" v-if="agentStatus.NISEnabled">
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/green_check.png" /></span>
          </div>
          <div class="boxdiv mb-3" v-else>
            <span class="boxspan-l">Enabled: </span
            ><span class="boxspan-r"><img src="/images/red_x.png" /></span>
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Signature Age: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small"
                >{{ agentStatus.NISSignatureAge }} days</small
              ></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Last Updated: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                format_date(agentStatus.NISSignatureLastUpdated)
              }}</small></span
            >
          </div>
          <div class="boxdiv">
            <span class="boxspan-l" style="font-size: small">Signature Ver: </span>
            <span class="boxspan-r"
              ><small class="text-muted" style="font-size: x-small">{{
                agentStatus.NISSignatureVersion
              }}</small></span
            >
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle">
            <span class="name"><h1 class="subtitle">Alerts</h1></span>
            <span class="option">
              <button class="button is-success ml-5" @click="refreshAgent()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>
          <table class="table is-fullwidth" id="detectionTable">
            <thead>
              <tr>
                <th>Action</th>
                <th>User</th>
                <th>Date</th>
                <th>Process/Resource</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="avlog in agentDetect" v-bind:key="avlog.id">
                <td>
                  <div v-if="avlog.ActionSuccess">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else>
                    <img src="/images/red_x.png" />
                  </div>
                </td>
                <td>{{ avlog.DomainUser }}</td>
                <td>{{ format_date(avlog.InitialDetectionTime) }}</td>
                <td>
                  <div>
                    Process: <small class="text-muted">{{ avlog.ProcessName }}</small>
                  </div>
                  <div>
                    Resource: <small class="text-muted">{{ avlog.Resources }}</small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "DefenderAgentManager",
  data() {
    return {
      errors: [],
      agentExclusions: [],
      exclusionForm: {},
      agent: {},
      agentStatus: {},
      agentConfig: {},
      agentDetect: [],
      agentPrefs: {},
      selected: [],
      selectAll: false,
      selectExcled: [],
      selectExclAll: false,
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getAgent();
    this.getAgentsConfig();
    this.getAgentStatus();
    this.getAgentPrefs();
    this.getAgentDetection();
    this.getExclusions();
  },
  methods: {
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > twoDaysEarlier;
    },
    refreshAgent() {
      this.getAgentsConfig();
      this.getAgentStatus();
      this.getAgentPrefs();
      this.refreshAgentDetection();
      this.getExclusions();
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    selectExcl() {
      this.selectExcled = [];
      if (!this.selectExclAll) {
        for (let i in this.agentExclusions) {
          this.selectExcled.push(this.agentExclusions[i].id);
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${agentID}`)
        .then((response) => {
          this.agent = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Configs  function
    async getAgentsConfig() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_defender_agent_config/${computerID}/`)
        .then((response) => {
          this.agentConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //get agent statuses
    async getAgentStatus() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agent_status/${computerID}/`)
        .then((response) => {
          this.agentStatus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //get agent prefs
    async getAgentPrefs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agent_prefs/${computerID}/`)
        .then((response) => {
          this.agentPrefs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //get agent detections
    async getAgentDetection() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agent_detection/${computerID}/`)
        .then((response) => {
          this.agentDetect = response.data;
          setTimeout(function () {
            $("#detectionTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom:
                "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //refresh agent detections
    async refreshAgentDetection() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agent_detection/${computerID}/`)
        .then((response) => {
          this.agentDetect = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async refreshStatus() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/refresh_agent_status/${computerID}/`)
        .then((response) => {
          toast({
            message: "Refresh status command sent to agent.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async runQuickScan() {
      this.selected = [];
      this.$store.commit("setIsLoading", true);
      const computer = { Agents: this.$route.params.id };
      await axios
        .post(`/api/v1/defender/run_quick_scan_on_agents/`, computer)
        .then((response) => {
          this.agentPrefs = response.data;
          toast({
            message: "Run quick scan command sent to agent.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async runFullScan() {
      const computer = { Agents: this.$route.params.id };
      this.$store.commit("setIsLoading", true);

      await axios
        .post(`/api/v1/defender/run_full_scan_on_agents/`, computer)
        .then((response) => {
          this.agentPrefs = response.data;
          toast({
            message: "Run quick scan command sent to agent.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async deleteExclusions() {
      this.errors = [];
      if (!this.selectExcled.length) {
        this.errors.push(
          "You need to select 1 or more exclusions from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const ID = this.$route.params.id;
        await axios
          .post(`/api/v1/defender/delete_agent_exclusion/${ID}/`, this.selectExcled)
          .then((response) => {
            //console.log(response.data);
            this.getExclusions();
            this.selectExcled = [];
            this.selectExclAll = false;
            toast({
              message: "Defender agent exclusions deleted successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.$store.commit("setIsLoading", false);
    },
    async submitExclusionsForm() {
      this.$store.commit("setIsLoading", true);
      this.exclusionForm.computerID = this.$route.params.id;
      this.exclusionForm.clientID = this.agent.clientID;
      if (!this.exclusionForm.isFile == true) {
        this.exclusionForm.isFile = false;
      }
      await axios
        .post("/api/v1/defender/add_agent_exclusion/", this.exclusionForm)
        .then((response) => {
          //console.log(response.data);
          this.exclusionForm.fileFolder = "";
          this.getExclusions();
          toast({
            message: "Defender agent exclusions updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getExclusions() {
      this.$store.commit("setIsLoading", true);
      const ID = this.$route.params.id;
      await axios
        .get(`/api/v1/defender/get_agent_exclusions/${ID}/`)
        .then((response) => {
          this.agentExclusions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async submitPrefsForm() {
      this.$store.commit("setIsLoading", true);
      this.agentPrefs.computerID = this.agent.computerID;
      this.agentPrefs.clientID = this.agent.clientID;
      const computerID = this.agent.computerID;
      await axios
        .post("/api/v1/defender/update_agent_prefs/", this.agentPrefs)
        .then((response) => {
          //console.log(response.data);
          axios
            .get(`/api/v1/defender/push_preferences_to_agent/${computerID}/`)
            .then((response) => {
              //console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            });

          toast({
            message: "Defender agent exclusions updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async pushExclusions() {
      const computerID = this.$route.params.id;
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/defender/push_exclusion_to_agent/${computerID}/`)
        .then((response) => {
          toast({
            message: "Defender update exclusions on agent sent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
        <div class="column is-12">
          <div class="collapse" id="deviceStateTable">
            <div class="card card-body">
              <h1 class="subtitle">Device State</h1>
              <div class="tabletitle mb-5">
                <span class="name">
                  This is the output of dsregcmd /status. This report is collected regularly
                  and repersents current status of the Windows device.
                </span>
                <span class="option">
                <button
                    class="button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#deviceStateTable"
                    aria-expanded="false"
                    aria-controls="deviceStateTable"
                  >
                    <va-icon class="icon ion-md-cog mr-1"></va-icon> Close Window
                  </button>
                </span>
              </div>
              <pre>{{ deviceStateResult }}</pre>
              <hr />
              <pre>{{ deviceStateAccounts }}</pre>
            </div>
          </div>
        </div>
      <div class="column is-4">
        <div class="box">
          <h1 class="subtitle">Device State</h1>
       
          <div class="tabletitle">
            <span class="name">AzureAD:</span>
            <span class="option">
              {{ azureAD }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">Enterprise:</span>
            <span class="option">
              {{ enterpriseAD }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">Domain:</span>
            <span class="option">
              {{ domainAD }}
            </span>
          </div>
          <button
                  class="button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#deviceStateTable"
                  aria-expanded="false"
                  aria-controls="deviceStateTable"
                >
                  <va-icon class="icon ion-md-cog mr-1"></va-icon> Device State
                </button>
        </div>
      </div>


      <div class="column is-4">
        <div class="box">
          <router-link
            v-bind:to="{
              name: 'ChocolateyAgentManager',
              params: {
                id: agent.computerID,
                clientID: agent.clientID,
              },
            }"
            style="text-decoration: none"
          >
            <h2 class="subtitle">Chocolatey</h2>
          </router-link>
          <table class="smalltable">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <div class="is-size-6">Enabled:</div>
                </td>
                <td>
                  <div v-if="agentChocolateyConfig.isEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Packages</div>
                </td>
                <td>
                  <div>{{ packages.length }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Software</div>
                </td>
                <td>
                  <div>{{ software.length }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Chocolatey:</div>
                </td>
                <td>{{ agentChocolateyConfig.chocoVersion }}</td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Script:</div>
                </td>
                <td>{{ agentChocolateyConfig.scriptVersion }}</td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Last Sync:</div>
                </td>
                <td style="font-size: small">{{ format_date(agentChocolateyConfig.lastSync) }}</td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Last Update:</div>
                </td>
                <td style="font-size: small">{{ format_date(agentChocolateyConfig.lastUpdate) }}</td>
              </tr>
              <tr>
                <template v-if="agentChocolateyConfig.standAlone == false">
                  <td>
                    <div class="is-size-6">Cache Site:</div>
                  </td>
                  <td>{{ this.siteName }}</td></template
                >
                <template v-else>
                  <td>
                    <div class="is-size-6">StandAlone:</div>
                  </td>
                  <td>
                    <div class="firstLetterCap">
                      {{ agentChocolateyConfig.standAlone }}
                    </div>
                  </td></template
                >
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-4">
        <div class="box">
          <router-link
            v-bind:to="{
              name: 'SurfLogAgentManager',
              params: {
                id: agent.computerID,
                clientID: agent.clientID,
              },
            }"
            style="text-decoration: none"
          >
            <h2 class="subtitle">SurfLog</h2>
          </router-link>

          <table class="smalltable">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <div class="is-size-6">Enabled:</div>
                </td>
                <td>
                  <div v-if="agentSurfLogConfig.isEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Script</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ agentSurfLogConfig.scriptVersion }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Last Sync</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ format_date(agentSurfLogConfig.lastSync) }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Log Items:</div>
                </td>
                <td>
                  <div>{{ agentSurfLogStats.LogCount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Profiles:</div>
                </td>
                <td>
                  <div>{{ agentSurfLogStats.ProfileCount }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-4">
        <div class="box">
          <router-link
            v-bind:to="{
              name: 'BitLockerAgentManager',
              params: {
                id: agent.computerID,
                clientID: agent.clientID,
              },
            }"
            style="text-decoration: none"
          >
            <h2 class="subtitle">BitLocker</h2>
          </router-link>
          <table class="smalltable">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <div class="is-size-6">Enabled:</div>
                </td>
                <td>
                  <div v-if="agentBitLockerConfig.isEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Script:</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ agentBitLockerConfig.scriptVersion }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Last Sync:</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ format_date(agentBitLockerConfig.lastSync) }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Volumes:</div>
                </td>
                <td>
                  <p v-for="volumes in agentBitLockerVolumes">
                    <b>{{ volumes.mountPoint }}:</b> {{ volumes.volumeStatus }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">TPM Present:</div>
                </td>
                <td>
                  <div v-if="agentBitLockerConfig.tpmPresent === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">TPM Ready:</div>
                </td>
                <td>
                  <div v-if="agentBitLockerConfig.tpmReady === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="mt-3"></p>
          <p></p>
        </div>
      </div>
      <div class="column is-4">
        <div class="box">
          <router-link
            v-bind:to="{
              name: 'DefenderAgentManager',
              params: {
                id: agent.computerID,
                clientID: agent.clientID,
              },
            }"
            style="text-decoration: none"
          >
            <h2 class="subtitle">Defender</h2>
          </router-link>
          <table class="smalltable">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <div class="is-size-6">Enabled:</div>
                </td>
                <td>
                  <div v-if="agentDefenderConfig.isEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Antivirus:</div>
                </td>
                <td>
                  <div v-if="agentAVStatus.AntivirusEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Spyware:</div>
                </td>
                <td>
                  <div v-if="agentAVStatus.AntispywareEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Script:</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ agentDefenderConfig.scriptVersion }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Quick Scan:</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ format_date(agentAVStatus.QuickScanEndTime) }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Sig Version:</div>
                </td>
                <td>
                  <p style="font-size: small">{{ agentAVStatus.AntivirusSignatureVersion }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Sig Date:</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ format_date(agentAVStatus.AntivirusSignatureLastUpdated) }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Alerts:</div>
                </td>
                <td>
                  <div class="is-size-6">
                    {{ agentAVDetect.length }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p></p>
        </div>
      </div>
      <hr />
      <div class="column is-4">
        <div class="box">
          <router-link
            v-bind:to="{
              name: 'BleachBitAgentManager',
              params: {
                id: agent.computerID,
                clientID: agent.clientID,
              },
            }"
            style="text-decoration: none"
          >
            <h2 class="subtitle">BleachBit</h2>
          </router-link>
          <table class="smalltable">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <div class="is-size-6">Enabled:</div>
                </td>
                <td>
                  <div v-if="agentBleachBitConfig.isEnabled === true" class="">
                    <img src="/images/green_check.png" />
                  </div>
                  <div v-else class="">
                    <img src="/images/red_x.png" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Script:</div>
                </td>
                <td>
                  <div>{{ agentBleachBitConfig.scriptVersion}}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Last Sync</div>
                </td>
                <td>
                  <div style="font-size: small">
                    {{ format_date(agentBleachBitConfig.lastSync) }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Files:</div>
                </td>
                <td>
                  <div>{{ agentBleachBitConfig.fileCount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="is-size-6">Space:</div>
                </td>
                <td>
                  <div>{{ agentBleachBitConfig.diskSpace }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { Buffer } from "buffer";
export default {
  name: "Agent_Windows",
  props: {
    agent: Object,
  },
  data() {
    return {
      agentChocolateyConfig: {},
      agentSurfLogConfig: {},
      agentDefenderConfig: {},
      agentAVStatus: {},
      agentAVDetect: [],
      agentBitLockerConfig: {},
      agentBleachBitConfig: {},
      agentBitLockerVolumes: [],
      packages: [],
      software: [],
      sites: [],
      siteName: "",
      agentSurfLogStats: {},
      tools: [],
      deviceStateResult: "",
      deviceStateAccounts: "",
      azureAD: "NO",
      enterpriseAD: "NO",
      domainAD: "NO",
    };
  },
  mounted() {
    this.getAgentChocolateyConfig();
    this.getPackages();
    this.getSoftware();
    this.getSite();
    this.getAgentSurfLogConfig();
    this.getAgentSurfLogStats();
    this.getAgentDefenderConfig();
    this.getAgentAVDetection();
    this.getAgentAVStatus();
    this.getAgentBitLockerConfigs();
    this.getAgentBitLockerVolumes();
    this.getAgentBleachBitConfigs();
    this.getAgentDS();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },

    getMB(value) {
      if (parseInt(value) != "NaN") {
        let v = value.toString().length;
        if (v > 9) {
          let i = (value / 1000000000).toFixed(2);
          return `${i} GB`;
        } else if (v > 6) {
          let i = (value / 1000000).toFixed(2);
          return `${i} MB`;
        } else if (v > 3) {
          let i = (value / 1000).toFixed(2);
          return `${i} kB`;
        } else {
          return `${value} B`;
        }
      } else return 0;
    },

    async getAgentSurfLogConfig() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/surflog/get_surflog_agent_config/${computerID}/`)
        .then((response) => {
          this.agentSurfLogConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents BleachBit Configs  function
    async getAgentBleachBitConfigs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/bleachbit/get_agent_config/${computerID}/`)
        .then((response) => {
          this.agentBleachBitConfig = response.data;
          this.agentBleachBitConfig.diskSpace = this.getMB(
            this.agentBleachBitConfig.diskSpace
          );
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents BitLocker Configs  function
    async getAgentBitLockerConfigs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/bitlocker/get_agent_config/${computerID}/`)
        .then((response) => {
          this.agentBitLockerConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agent BitLocker Volumes  function
    async getAgentBitLockerVolumes() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/bitlocker/get_agent_volumes/${computerID}/`)
        .then((response) => {
          this.agentBitLockerVolumes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgentSurfLogStats() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/surflog/get_surflog_agent_stats/${computerID}/`)
        .then((response) => {
          this.agentSurfLogStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getAgentChocolateyConfig() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_config/${computerID}/`)
        .then((response) => {
          this.agentChocolateyConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getPackages() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_packages/${computerID}/`)
        .then((response) => {
          this.packages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getSoftware() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/chocolatey/get_installed_software/${computerID}/`)
        .then((response) => {
          this.software = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getSite() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.agent.clientID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_sites/${clientID}/`)
        .then((response) => {
          this.sites = response.data;
          this.mySite();
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    mySite() {
      const siteID = this.agentChocolateyConfig.siteID;
      for (let i in this.sites) {
        if (this.sites[i].id === siteID) {
          this.siteName = this.sites[i].siteName;
        }
      }
    },
    // Windows Defender area
    //get agent configs
    async getAgentDefenderConfig() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/defender/get_defender_agent_config/${computerID}/`)
        .then((response) => {
          this.agentDefenderConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //get agent detections
    async getAgentAVDetection() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/defender/get_agent_detection/${computerID}/`)
        .then((response) => {
          this.agentAVDetect = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgentAVStatus() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/defender/get_agent_status/${computerID}/`)
        .then((response) => {
          this.agentAVStatus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgentDS() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/get_device_state/${agentID}/`)
        .then((response) => {
          this.deviceStateResult = Buffer.from(
            response.data["Results"],
            "base64"
          ).toString();
          this.deviceStateAccounts = Buffer.from(
            response.data["Accounts"],
            "base64"
          ).toString();
          this.azureAD = response.data["AzureAdJoined"];
          this.enterpriseAD = response.data["EnterpriseJoined"];
          this.domainAD = response.data["DomainJoined"];
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);

    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="box">
          <h1 class="title">Thank You</h1>
          <p>
            We wanted to personally thank you for subscribing to RMMMax. We're thrilled to
            have you as a part of our community. As a subscriber, you'll be among the
            first to receive our latest updates, new tools, and exclusive offers. We're
            committed to providing high-quality, time-saving tools along with a top-notch
            user experience, helping to save you time and money.
          </p>
          <p>
            If you have any feedback or suggestions, please send them to sales@RMMMax.com
            Thank you for your support and trust in us.
          </p>
          <p>Best regards, RMMMax Team</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "PlansThankyou",
  data() {
    return {};
  },
  mounted() {
    axios
      .post("/api/v1/stripe/check_session/", {
        session_id: this.$route.query.session_id,
      })
      .then((response) => {
        toast({
          message: "Plan update was successfull",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setTeam", {
          id: response.data.id,
          username: response.data.name,
          plan: response.data.plan.name,
          max_agents: response.data.plan.max_agents,
          max_clients: response.data.plan.max_clients,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

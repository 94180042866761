<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">BitLocker Agent Manager for {{ agent.computerName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: 'BitLockerClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Agents
          </router-link>

          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentKeyProtectors"
            aria-expanded="false"
            aria-controls="agentKeyProtectors"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon>Add Key Protector
          </button>
          <button class="button is-success" @click="refreshVolumes">
            <va-icon class="icon ion-md-sync mr-1"></va-icon> Scan Volumes
          </button>
          <button class="button is-danger" @click="manageVolume('lockVolume')">
            <va-icon class="icon ion-md-lock mr-1"></va-icon> Lock Volume
          </button>
          <button class="button is-success" @click="manageVolume('unlockVolume')">
            <va-icon class="icon ion-md-unlock mr-1"></va-icon> Unlock Volume
          </button>
          <button class="button is-warning" @click="manageVolume('suspendVolume')">
            <va-icon class="icon ion-md-eye-off mr-1"></va-icon> Suspend Volume
          </button>
          <button class="button is-success" @click="manageVolume('resumeVolume')">
            <va-icon class="icon ion-md-eye mr-1"></va-icon> Resume Volume
          </button>
        </div>
      </div>
      <!-- End of Buttons -->
      <!-- status box  -->
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin30Days(agentConfigs.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfigs.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfigs.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="agentConfigs.isEnabled === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <template v-if="agentConfigs.tpmPresent === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>TPM Present:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>TPM Present:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <template v-if="agentConfigs.tpmReady === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>TPM Ready:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>TPM Ready:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
        </div>
      </div>
      <!-- Add Key Protector Drop Down  -->
      <div class="column is-12">
        <div class="panel-collapse collapse" id="agentKeyProtectors">
          <div class="card card-body">
            <h1 class="subtitle">Adding A New Key Protector</h1>
            <p class="">
              The Add BitLocker Key Protector tool adds a key protector for the volume key
              of the volume protected with BitLocker Drive Encryption. When a user
              accesses a drive protected by BitLocker, such as when starting a computer,
              BitLocker requests the relevant key protector. For example, the user can
              enter a PIN or provide a USB drive that contains a key. BitLocker retrieves
              the encryption key and uses it to read data from the drive. You can use one
              of the following methods or combinations of methods for a new key protector:
            </p>

            <p class="mt-3">
              <strong>Trusted Platform Module (TPM).</strong> BitLocker uses the
              computer's TPM to protect the encryption key. If you specify this protector,
              users can access the encrypted drive as long as it is connected to the
              system board that hosts the TPM and the system boot integrity is intact. In
              general, TPM-based protectors can only be associated to an operating system
              volume.
            </p>
            <p class="mt-3">
              <strong>TPM and Personal Identification Number (PIN).</strong> BitLocker
              uses a combination of the TPM and a user-supplied PIN. A PIN is four to
              twenty digits or, if you allow enhanced PINs, four to twenty letters,
              symbols, spaces, or numbers.
            </p>
            <p class="mt-3">
              <strong>TPM, PIN, and startup key.</strong> BitLocker uses a combination of
              the TPM, a user-supplied PIN, and input from of a USB memory device that
              contains an external key.
            </p>
            <p class="mt-3">
              <strong>TPM and startup key.</strong> BitLocker uses a combination of the
              TPM and input from of a USB memory device. Startup key. BitLocker uses input
              from of a USB memory device that contains the external key.
            </p>
            <p class="mt-3">
              <strong>Password.</strong> BitLocker uses a password. Recovery key.
              BitLocker uses a recovery key stored as a specified file in a USB memory
              device.
            </p>
            <p class="mt-3">
              <strong>Recovery password.</strong> BitLocker uses a recovery password.
            </p>
            <p class="mt-3">
              <strong>Active Directory Domain Services (AD DS) account.</strong> BitLocker
              uses domain authentication to unlock data volumes. Operating system volumes
              cannot use this type of key protector.
            </p>
            <p class="mb-5 mt-3">
              You can add only one of these methods or combinations at a time, but you can
              run this cmdlet more than once on a volume. Adding a key protector is a
              single operation; for example, adding a startup key protector to a volume
              that already uses the TPM and PIN combination as a key protector results in
              two key protectors, not a single key protector that uses TPM, PIN, and
              startup key. If a single key is desired, Instead, add a protector that uses
              TPM, PIN, and startup key and then remove the TPM and PIN protector by using
              the Remove BitLocker Key Protector tool.
            </p>

            <form @submit.prevent="submitKeyForm">
              <div class="field-group1">
                <div class="field">
                  <label class="select-inline mr-2"><strong>Select Volume</strong></label>
                  <div class="control">
                    <div class="select">
                      <select v-model="mountPoint">
                        <option
                          v-for="volume in agentVolumes"
                          :value="volume.mountPoint"
                          :key="mountPoint"
                        >
                          {{ volume.mountPoint }} ({{ volume.volumeType }})
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="control mt-3">
                    <lable><strong>Encryption Size</strong></lable>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="encryptionSize"
                        id="128"
                        v-model="encryptSize128"
                        checked
                      />
                      <label class="form-check-label" for="128">
                        128 Bit Encryption
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="encryptionSize"
                        id="256"
                        v-model="encryptSize256"
                      />
                      <label class="form-check-label" for="256">
                        256 Bit Encryption
                      </label>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="control mt-3">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="skipHardwareTest"
                      />Skip Hardware Test</label
                    >
                  </div>
                </div>
              </div>
              <div class="field-group1">
                <div class="field mb-5">
                  <label><strong>Key Protector Types</strong></label>
                  <div class="control">
                    <div class="select">
                      <select v-model="keyProtector">
                        <option value="TPM" selected>TPM Protector</option>
                        <option value="TPMPIN">TPM and Pin Protector</option>
                        <option value="PASSWORD">Password Protector</option>
                        <option value="RECOVERYPASSWORD">
                          Recovery Password Protector
                        </option>
                        <option value="RECOVERYKEY">Recovery Key Protector</option>
                        <option value="STARTUPKEY">Startup Key Protector</option>
                        <option value="TPMSTARTUPKEY">
                          TPM and Startup Key Protector
                        </option>
                        <option value="TPMPINSTARTUPKEY">
                          TPM, PIN and Startup Key Protector
                        </option>
                        <option value="ADACCOUNT">
                          Active Directory Account Protector
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <template v-if="keyProtector == 'TPM'">
                  <p>No extra parameters are required.</p>
                </template>
                <template v-if="keyProtector == 'TPMPIN'">
                  <div class="field">
                    <label
                      ><strong>Pin Code</strong> (6 numerical digits minium, 20 digits
                      maxium)</label
                    >
                    <div class="control">
                      <input type="text" class="input" v-model="PINCODE" />
                    </div>
                  </div>
                </template>
                <template v-if="keyProtector == 'PASSWORD'">
                  <div class="field">
                    <label><strong>Password</strong></label>
                    <div class="control">
                      <input type="text" class="input" v-model="PASSWORD" />
                    </div>
                  </div>
                </template>
                <template v-if="keyProtector == 'RECOVERYPASSWORD'">
                  <p>
                    This key protector will auto generate a 48 digit passcode which will
                    be stored here.
                  </p>
                </template>
                <template v-if="keyProtector == 'STARTUPKEY'">
                  <div class="field">
                    <label
                      ><strong>Startup Key Path</strong> (File path (without filename) to
                      store key, key is auto generated.)</label
                    >
                    <div class="control">
                      <input type="text" class="input" v-model="STARTUPKEY" />
                    </div>
                  </div>
                </template>
                <template v-if="keyProtector == 'TPMSTARTUPKEY'">
                  <div class="field">
                    <label
                      ><strong>Startup Key Path</strong> (File path (without filename) to
                      store key, key is auto generated)</label
                    >
                    <div class="control">
                      <input type="text" class="input" v-model="STARTUPKEY" />
                    </div>
                  </div>
                </template>
                <template v-if="keyProtector == 'TPMPINSTARTUPKEY'">
                  <div class="field">
                    <label
                      ><strong>Startup Key Path</strong> (File path (without filename) to
                      store key, key is auto generated.)</label
                    >
                    <div class="control">
                      <input type="text" class="input" v-model="STARTUPKEY" />
                    </div>
                  </div>
                  <div class="field">
                    <label
                      ><strong>Pin Code</strong> (6 numerical digit minium, 20 digit
                      maxium)</label
                    >
                    <div class="control">
                      <input type="text" class="input" v-model="PINCODE" />
                    </div>
                  </div>
                </template>
                <template v-if="keyProtector == 'RECOVERYKEY'">
                  <div class="field">
                    <label
                      ><strong>Recovery Key Path</strong> (File path (without file name)
                      to store key, key is auto generated.)</label
                    >
                    <div class="control">
                      <input type="text" class="input" v-model="RECOVERYKEY" />
                    </div>
                  </div>
                </template>
              </div>
              <div class="field">
                <div class="control mt-5">
                  <button
                    class="button is-success"
                    data-bs-toggle="collapse"
                    data-bs-target="#agentKeyProtectors"
                    aria-expanded="false"
                    aria-controls="agentKeyProtectors"
                  >
                    Create New Key
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!---  Main View --->
      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle">
            <span class="name"> <h1 class="subtitle">Volumes</h1></span>
            <span class="option">
              <button class="button is-success" @click="getAgentVolumes()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>

          <template v-if="agentVolumes.length >= 1">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th style="width: 12px"></th>
                  <th>Volume</th>
                  <th>Size</th>
                  <th>Encryption</th>
                  <th>Status</th>
                  <th>Suspend</th>
                  <th>Locked</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(volume, itemObjKey) in agentVolumes" v-bind:key="volume.id">
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" :value="itemObjKey" v-model="selected" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </td>
                  <td>
                    <span class="ml-1">{{ volume.mountPoint }}:</span>
                    <span v-if="volume.autoUnlockEnabled === true">
                      <va-icon class="icon ion-md-key"></va-icon>
                    </span>
                    <span class="ml-3"  style="font-size: x-small">({{ volume.volumeType }})</span>
                  </td>
                  <td>
                    <span class="ml-1">{{ volume.capacityGB }} GB</span>
                  </td>
                  <td>
                    <span class="ml-1"
                      >({{ volume.encryptionPercentage }}%)</span><span class="ml-3" style="font-size: x-small">{{ volume.volumeStatus }}
                    </span>
                  </td>
                  <td>
                    <span class="ml-1">{{ volume.protectionStatus }}</span>
                  </td>
                  <td v-if="volume.isDisabled === true">
                    <img src="/images/green_check.png" /><span style="display: none"
                      >enabled</span
                    >
                  </td>
                  <td v-else>
                    <img src="/images/red_x.png" /><span style="display: none"
                      >disabled</span
                    >
                  </td>
                  <td v-if="volume.isLocked === true">
                    <img src="/images/locked.png" /><span style="display: none"
                      >locked</span
                    >
                  </td>
                  <td v-else>
                    <img src="/images/unlocked.png" /><span style="display: none"
                      >unlocked</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p>This agent has no volumes listed in the database.</p></template
          >
        </div>
      </div>

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle">
            <span class="name"> <h1 class="subtitle">Key Protectors</h1></span>
            <span class="option">
              <button class="button is-success" @click="getAgentProtectors()">
                <va-icon class="icon ion-md-repeat"></va-icon>
              </button>
            </span>
          </div>
          <template v-if="agentProtectors.length >= 1">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Volume</th>
                  <th>Type</th>
                  <th>Key Filename</th>
                  <th>Recovery Password / PIN</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="key in agentProtectors" v-bind:key="key.id">
                  <td>
                    <button
                      class="button is-danger"
                      @click="
                        removeKeyProtector(
                          key.keyProtectorID,
                          key.mountPoint,
                          key.protectorType
                        )
                      "
                    >
                      <va-icon class="icon ion-md-trash"></va-icon>
                    </button>
                  </td>
                  <td>
                    <span class="ml-1">{{ key.mountPoint }}:</span>
                  </td>
                  <td>
                    <span class="ml-1">{{ key.protectorType }}</span>
                  </td>
                  <td>
                    <span class="ml-1">{{ key.keyFileName }}</span>
                    <span class="ml-1">{{ key.keyCertificationType }}</span>
                  </td>
                  <td>
                    <span class="ml-1"
                      >{{ key.recoveryPassword }} {{ key.password }} {{ key.pin }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table></template
          >
          <template v-else>
            <p>This agent has no key protectors listed in database.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { toast } from "bulma-toast";
export default {
  name: "BitLockerAgentManager",
  components: {},
  data() {
    return {
      errors: [],
      selected: [],
      selectAll: false,
      PINCODE: "",
      PASSWORD: "",
      STARTUPKEY: "",
      RECOVERYKEY: "",
      mountPoint: "",
      encryptSize128: "",
      encryptSize256: "",
      keyProtector: "",
      skipHardwareTest: "",
      agentConfigs: {},
      agentVolumes: [],
      agentProtectors: [],
      agent: {},
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getAgent();
    this.getAgentConfigs();
    this.getAgentVolumes();
    this.getAgentProtectors();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    // Remove Key Protector  function
    async removeKeyProtector(keyID, mountPoint, protectorType) {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      const data = {
        computerID: computerID,
        keyID: keyID,
        mountPoint: mountPoint,
        keyType: protectorType,
        action: "removeKey",
      };
      await axios
        .post(`/api/v1/bitlocker/manage/agent/`, data)
        .then((response) => {
          this.getAgentProtectors();
          toast({
            message: "Remove Key protector sent to agent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${agentID}`)
        .then((response) => {
          this.agent = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Configs  function
    async getAgentConfigs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_agent_config/${computerID}/`)
        .then((response) => {
          this.agentConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agent Volumes  function
    async getAgentVolumes() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_agent_volumes/${computerID}/`)
        .then((response) => {
          this.agentVolumes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agent Key protectors  function
    async getAgentProtectors() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/get_agent_key_protectors/${computerID}/`)
        .then((response) => {
          this.agentProtectors = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Refresh Agent Volumes  function
    async refreshVolumes() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/bitlocker/refresh_agent_volumes/${computerID}/`)
        .then((response) => {
          //console.log(response.data);

          toast({
            message: "Volume refresh sent to agent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Add Key Protector  function
    async submitKeyForm() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      if (!this.keyProtector.length > 0) {
        this.errors.push("You must select a Key protector type.");
      }
      let EncAES = "Aes128";
      let skiptest = "";
      let startUpPath = "";
      let Pin = "";
      let Password = "";
      if (this.encryptSize256) {
        EncAES = "Aes256";
      }
      if (this.skipHardwareTest) {
        skiptest = "-SkipHardwareTest";
      }
      if (this.STARTUPKEY) {
        startUpPath = this.STARTUPKEY;
      }
      if (this.RECOVERYKEY) {
        startUpPath = this.RECOVERYKEY;
      }
      if (this.PINCODE) {
        Pin = this.PINCODE;
      }
      if (this.PASSWORD) {
        Password = this.PASSWORD;
      }
      const computerID = this.$route.params.id;
      const data = {
        computerID: computerID,
        keyID: 0,
        action: "addKeyProtector",
        mountPoint: this.mountPoint,
        keyType: this.keyProtector,
        skipTest: skiptest,
        encryptType: EncAES,
        path: startUpPath,
        password: Password,
        pin: Pin,
      };
      await axios
        .post(`/api/v1/bitlocker/manage/agent/`, data)
        .then((response) => {
          //console.log(response.data);
          this.getAgentProtectors();
          toast({
            message: "Add new key protector sent to agent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    // Manage volumes function
    async manageVolume(action) {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push("You need to select a volume from the list and try again.");
      }
      if (!this.errors.length) {
        const computerID = this.$route.params.id;
        var data = {
          computerID: computerID,
          mountPoint: this.agentVolumes[this.selected].mountPoint,
          action: action,
        };
        this.$store.commit("setIsLoading", true);
        if (action == "unlockVolume") {
          var data = this.testForPasswordKeyProtector(
            computerID,
            this.agentVolumes[this.selected].mountPoint,
            action
          );
        } else if (action == "lockVolume") {
          var data1 = this.testForPasswordKeyProtector(
            computerID,
            this.agentVolumes[this.selected].mountPoint,
            action
          );
        } else if (action == "suspendVolume") {
          var data = {
            computerID: computerID,
            mountPoint: this.agentVolumes[this.selected].mountPoint,
            action: action,
            rebootCount: 3,
          };
        }
        if (!this.errors.length) {
          await axios
            .post(`/api/v1/bitlocker/manage/agent/`, data)
            .then((response) => {
              this.getAgentVolumes();
              this.getAgentProtectors();
              toast({
                message: action + " sent to agent successfully",
                type: "is-success",
                dismissible: true,
                pauseOnHover: true,
                duration: 3000,
                position: "bottom-right",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.$store.commit("setIsLoading", false);
      }
    },
    testForPasswordKeyProtector(computerID, mountPoint, action) {
      for (let i in this.agentProtectors) {
        if (
          this.agentProtectors[i].protectorType == "Password" &&
          this.agentProtectors[i].mountPoint == mountPoint
        ) {
          var data = {
            computerID: computerID,
            mountPoint: mountPoint,
            action: action,
            password: this.agentProtectors[i].password,
          };
          return data;
        } else if (
          this.agentProtectors[i].protectorType == "RecoveryPassword" &&
          this.agentProtectors[i].mountPoint == mountPoint
        ) {
          var data = {
            computerID: computerID,
            mountPoint: mountPoint,
            action: action,
            recoveryPassword: this.agentProtectors[i].recoveryPassword,
          };
          return data;
        } else if (
          this.agentProtectors[i].protectorType == "RecoveryKey" &&
          this.agentProtectors[i].mountPoint == mountPoint
        ) {
          var data = {
            computerID: computerID,
            mountPoint: mountPoint,
            action: action,
            recoveryKeyPath: this.agentProtectors[i].keyFileName,
          };
          return data;
        }
      }
      this.errors.push("Did not find a key protector suitable to use to unlock volume.");
    },
  },
};
</script>

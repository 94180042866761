<template>
  <div class="box">
    <h2 class="subtitle mt-3"><i class="icon ion-md-bulb"></i>Agents By Type</h2>
    <Doughnut :options="options" :chart-data="chartData" />
  </div>
</template>
<script>
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: "AgentTypePie",
  components: {
    Doughnut,
  },
  data() {
    return {
      chartData: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getUpdates();
  },
  methods: {
    async getUpdates() {
      await axios
        .get("/api/v1/agents/get_type_count/")
        .then((response) => {
          console.log(response.data);
          this.chartData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">HomeBrew Client Manager for {{ client.clientName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <!-- start buttons -->
        <div class="buttons">
          <template v-for="cc in clients">
            <template v-if="cc.clientID == client.clientID">
              <div class="mr-3 mb-3"><ClientMenu :client="cc" /></div>
            </template>
          </template>
          <router-link
            to="/dashboard/clients"
            class="button"
            style="text-decoration: none"
            ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Clients</router-link
          >
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#clientConfigParams"
            aria-expanded="false"
            aria-controls="clientConfigParams"
          >
            <va-icon class="icon ion-md-cog mr-1"></va-icon> Settings
          </button>          
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#approvedPackages"
            aria-expanded="false"
            aria-controls="approvedPackages"
          >
          <va-icon class="icon ion-md-gift mr-1"></va-icon> Approved Packages
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#installSoftware"
            aria-expanded="false"
            aria-controls="installSoftware"
          >
          <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon> Install Package
          </button>
          
          <button class="button is-success" @click="enableAgents">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable / Disable
          </button>

          <button class="button is-success" @click="rescanClient">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Rescan Client
          </button>

          <button class="button is-success" @click="updateAgents">
            <va-icon class="icon ion-md-save mr-1"></va-icon> Update Agents
          </button>

          <button class="button is-success" @click="openSudoForm">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Set Sudo
          </button>

          <!-- Sudo pop-up selection form -->
          <div id="mySudoForm">
            <form @submit.prevent="submitSudoForm" class="form-container">
              <div class="field mb-3">
                <label class="form-checkbox">
                          <input
                            type="checkbox"
                            v-model="createUser"
                          />
                          Create user if doesn't exist.
                        </label>
              </div>
              <div class="field mb-3">
                <label>Sudo User</label>
                <div class="control">
                  <input type="text" class="input" v-model="sudoUser" />
                </div>
              </div>
              <div class="field mb-3">
                  <label>Sudo Password</label>
                <div class="control">
                  <input type="password" class="input" v-model="sudoPass" />
                </div>
              </div>
              <button type="submit" class="button is-success ml-3">
                <va-icon class="icon ion-md-save mr-1"></va-icon> Save
              </button>
              <button type="button" class="button is-warning" @click="closeSudoForm">
                <va-icon class="icon ion-md-close mr-1"></va-icon> Close
              </button>
            </form>
          </div>
          <!-- End of Sudo selection form -->
        </div>
      </div>
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Agent Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin2Days(clientConfig.lastScanDate) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(clientConfig.lastScanDate) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastScanDate) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Update Push:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin30Days(clientConfig.lastUpdate) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(clientConfig.lastUpdate) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastUpdate) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Install Push:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin30Days(clientConfig.lastInstall) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(clientConfig.lastInstall) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(clientConfig.lastInstall) }}
                </p></template
              >
            </span>
          </div>
          <template v-if="team.hideDisabledAgents === false">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option"
                ><img class="ml-5" src="/images/green_check.png"
              /></span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Viewing All Agents:</strong></p></span
              >
              <span class="option"><img class="ml-5" src="/images/red_x.png" /></span>
            </div>
          </template>
          <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Service Logs:</strong></p></span>
              <span class="option">
                <router-link
                  class=""
                  style="text-decoration: none"
                  :to="{
                    name: 'LogViewer',
                    params: {
                      logname: 'Brew',
                      id: client.clientID,
                    },
                  }"><va-icon class="icon ion-md-today" style="color:darkgrey;"></va-icon></router-link>
              </span>
            </div>
        </div>
      </div>
 <!-- start Settings dropdown -->
 <div class="column is-12">
        <div class="collapse" id="clientConfigParams">
          <div class="card card-body">
            <h1 class="subtitle">Client Settings</h1>
            <h1 class="subtitle">Automation</h1>
            <p class="mb-5">
              This is the Homebrew Manager client configuration area. You can enable
              or disable package scans, automate approved package installs and automate
              updates across all agents of this client.
            </p>
            <form @submit.prevent="submitConfigForm">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.isEnabled"
                    />Enable Client Scans</label
                  >
                  <label class="checkbox-inline ml-6"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.autoInstall"
                    />Auto Install Packages</label
                  >
                  <label class="checkbox-inline ml-6"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientConfig.autoUpdate"
                    />Auto Update Packages</label
                  >
                  <button
                    class="button is-success ml-5"
                    data-bs-toggle="collapse"
                    data-bs-target="#clientConfigParams"
                    aria-expanded="false"
                    aria-controls="clientConfigParams"
                  >
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>
            </form>
            <hr class="mt-5 mb-5" />
            <div>
              <h1 class="subtitle">Automated Update Schedules</h1>
              <p>
                You can schedule automated updates for all agents under this client or
                schedule different locations under this client for different automated
                updates schedules.
              </p>
              <form @submit.prevent="submitScheduleForm">
                <span class="a">
                  <div class="field mt-5">
                    <label>Select Location(s)</label>
                    <div class="control mt-3">
                      <div class="select">
                        <select v-model="schedule.location">
                          <option>All</option>
                          <template v-for="site in locations">
                            <option>{{ site.locationName }}</option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <label>Schedule Type</label>
                    <div class="control mt-3">
                      <div class="select">
                        <select v-model="schedule.schedule">
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <template v-if="schedule.schedule == 'Weekly'">
                      <label>Schedule day of week</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="schedule.scheduleDay">
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                          </select>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="schedule.schedule == 'Monthly'">
                      <label>Schedule day of month</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="schedule.scheduleDay">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                          </select>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <label></label>
                      <div class="control mt-3"><p></p></div>
                    </template>
                  </div>
                </span>
                <span class="a">
                  <template v-if="schedule.schedule != undefined">
                    <div class="field mt-5">
                      <label>Schedule time (00:00)</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="schedule.scheduleHour">
                            <option value="00">00:00</option>
                            <option value="01">01:00</option>
                            <option value="02">02:00</option>
                            <option value="03">03:00</option>
                            <option value="04">04:00</option>
                            <option value="05">05:00</option>
                            <option value="06">06:00</option>
                            <option value="07">07:00</option>
                            <option value="08">08:00</option>
                            <option value="09">09:00</option>
                            <option value="10">10:00</option>
                            <option value="11">11:00</option>
                            <option value="12">12:00</option>
                            <option value="13">13:00</option>
                            <option value="14">14:00</option>
                            <option value="15">15:00</option>
                            <option value="16">16:00</option>
                            <option value="17">17:00</option>
                            <option value="18">18:00</option>
                            <option value="19">19:00</option>
                            <option value="20">20:00</option>
                            <option value="21">21:00</option>
                            <option value="22">22:00</option>
                            <option value="23">23:00</option>
                          </select>
                        </div>
                      </div>
                    </div></template
                  >
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <div class="control">
                      <button class="button is-success">
                        <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                      </button>
                    </div>
                  </div>
                </span>
              </form>
            </div>
            <div class="mt-3">
              <h1 class="subtitle">Schedules</h1>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 35px"></th>
                    <th>Location</th>
                    <th>Type</th>
                    <th>Day</th>
                    <th>Hour</th>
                    <th>Enabled</th>
                  </tr>
                </thead>
                <tbody class="table-group-divider">
                  <tr v-for="(sch, itemObjKey) in schedules" v-bind:key="sch.id">
                    <th scope="id">
                      <div class="dropdown">
                        <button class="dropbtn">
                          <va-icon class="icon ion-md-apps"></va-icon>
                        </button>
                        <div class="dropdown-content">
                          <a
                            href="#"
                            @click="enableSchedule(itemObjKey)"
                            style="text-decoration: none"
                          >
                            <va-icon class="icon ion-md-git-compare mr-1"></va-icon>
                            Enable / Disable Schedule
                          </a>
                          <a
                            href="#"
                            @click="editSchedule(itemObjKey)"
                            style="text-decoration: none"
                            ><va-icon class="icon ion-md-cog mr-1"></va-icon> Edit
                            Schedule</a
                          >
                          <a
                            href="#"
                            @click="deleteSchedule(itemObjKey)"
                            style="text-decoration: none"
                            ><va-icon class="icon ion-md-trash mr-1"></va-icon> Delete
                          </a>
                        </div>
                      </div>
                    </th>
                    <td>{{ sch.location }}</td>
                    <td>{{ sch.schedule }}</td>
                    <template v-if="sch.schedule == 'Weekly'">
                      <td v-if="sch.scheduleDay == 0">Sunday</td>
                      <td v-else-if="sch.scheduleDay == 1">Mondays</td>
                      <td v-else-if="sch.scheduleDay == 2">Tuesdays</td>
                      <td v-else-if="sch.scheduleDay == 3">Wednesdays</td>
                      <td v-else-if="sch.scheduleDay == 4">Thursdays</td>
                      <td v-else-if="sch.scheduleDay == 5">Fridays</td>
                      <td v-else="sch.scheduleDay == 6">Saturdays</td>
                      <td v-else>Unknown</td>
                    </template>
                    <template v-else>
                      <td>{{ sch.scheduleDay }}</td></template
                    >
                    <td>{{ sch.scheduleHour }}:00</td>
                    <td v-if="sch.isEnabled === true">
                      <img src="/images/green_check.png" />
                    </td>
                    <td v-else><img src="/images/red_x.png" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Settings -->
      <!-- start of apporved packages -->

      <div class="collapse" id="approvedPackages">
        <div class="card card-body">
          <h1 class="subtitle">Approved Packages</h1>
          <p class="mb-3">
            Approved packages are software titles that you want to have standardized
            across the client environment. As agents are enabled they will be checked to
            see if they have the current packages installed and if not it will attempt to
            install the package. Add package names that you want installed to all enabled
            agents and then select the save button. You can select any package from the
            list and it will populate this form allowing you to edit the package or enable
            / disable the package.
          </p>
          <form @submit.prevent="submitPackageForm" class="row g-4">
            <div class="col-auto">
              <label class="checkbox-inline mt-1"
                ><input
                  class="form-check-input mr-2"
                  type="checkbox"
                  v-model="package.isEnabled"
                />Enabled</label
              ><br />
              <label class="checkbox-inline mt-1"
                ><input
                  class="form-check-input mr-2"
                  type="checkbox"
                  v-model="package.pushToAllClients"
                />Approve package for all clients</label
              >
            </div>

            <div class="col-auto">
              <label>Package Name</label>
              <div class="control">
                <input
                  type="text"
                  class="resizedTextbox-md"
                  v-model="package.packageName"
                />
              </div>
            </div>
            <div class="col-auto">
              <div class="control mt-4">
                <button class="button is-success">
                  <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                </button>
              </div>
            </div>
          </form>
          <hr />
          <table class="table">
            <thead>
              <tr>
                <th style="width: 35px"></th>
                <th>Package</th>
                <th>Enabled</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-for="(pack, itemObjKey) in packages" v-bind:key="pack.id">
                <th scope="id">
                  <div class="dropdown">
                    <button class="dropbtn">
                      <va-icon class="icon ion-md-apps"></va-icon>
                    </button>
                    <div class="dropdown-content">
                      <a
                        href="#"
                        @click="enablePackage(itemObjKey)"
                        style="text-decoration: none"
                      >
                        <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Enable /
                        Disable Package
                      </a>
                      <a
                        href="#"
                        @click="editPackage(itemObjKey)"
                        style="text-decoration: none"
                        ><va-icon class="icon ion-md-cog mr-1"></va-icon> Edit Package</a
                      >
                      <a
                        href="#"
                        @click="deletePackage(itemObjKey)"
                        style="text-decoration: none"
                        ><va-icon class="icon ion-md-trash mr-1"></va-icon> Delete
                        Package</a
                      >
                    </div>
                  </div>
                </th>
                <td>{{ pack.packageName }}</td>
                <td v-if="pack.isEnabled === true">
                  <img src="/images/green_check.png" />
                </td>
                <td v-else><img src="/images/red_x.png" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- End Approved Applications -->
      <!-- start Install Software dropdown -->
      <div class="column is-12">
        <div class="collapse" id="installSoftware">
          <div class="card card-body">
            <h1 class="subtitle">Install Software</h1>
            <p class="mb-5">
              Install software quickly across one or more agents. Provide the package name
              in the field below, select the agents you would like the package installed
              to and select install.
            </p>
            <form @submit.prevent="installSoftwareForm">
              <div class="field">
                <label class="mb-2">Package Name</label>
                <div class="control">
                  <input type="text" class="resizedTextbox-md" v-model="packageName" />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-success">Install</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Install Software -->

      <!-- Main View agents -->

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle mb-5">
            <span class="name"> <h1 class="subtitle">Agent Status</h1></span>
            <span class="option">
              <button class="button is-success" @click="refreshClientData()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>
          <template v-if="agents.length">
            <table class="table is-fullwidth" id="brewClientTable">
              <thead>
                <tr>
                  <th style="width: 12px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th style="width: 30px"><va-icon class="icon ion-md-apps"></va-icon></th>
                  <th>Agent Name</th>
                  <th>Location</th>
                  <th>Sudo User</th>
                  <th>Brew Version</th>
                  <th>Updates</th>
                  <th>Last Scan</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="team.hideDisabledAgents === false">
                  <tr v-for="agent in agents" v-bind:key="agent.id">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="checkbox"
                          :value="agent.computerID"
                          v-model="selected"
                        />
                        <va-icon class="form-icon"></va-icon>
                      </label>
                    </td>
                    <td>
                      <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                        <AgentMenu_Mac :agent="agent" />
                    </td>
                    <td>{{ agent.computerName }}</td>
                    <td>{{ agent.locationName }}</td>
                    <td>
                      {{ mySudoUser(agent.computerID) }}
                      <template v-if="myCreateUser(agent.computerID) == true">
                      <va-icon class="icon ion-md-create"></va-icon></template>
                    </td>
                    <td>{{ myBrewVersion(agent.computerID) }}</td>
                    <td>{{ myAgentPackagePercent(agent.computerID) }}% ({{ myAgentUpdateCount(agent.computerID) }})</td>
                    <td>{{ myAgentLastScan(agent.computerID) }}</td>
                    <td v-if="agent.isEnabled === true">
                      <div v-if="myAgentIsEnabled(agent.computerID)">
                        <img src="/images/green_check.png" /><span style="display: none"
                          >enabled</span
                        >
                      </div>
                      <div v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </div>
                    </td>
                    <td v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                        >disabled</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-for="agent in agents" v-bind:key="agent.id">
                    <tr v-if="agent.isEnabled === true" v-bind:key="agent.id">
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="agent.computerID"
                            v-model="selected"
                          />
                          <va-icon class="form-icon"></va-icon>
                        </label>
                      </td>
                      <td>
                        <template v-if="agent.status == 'Online'">
                        <span style="display: none">Online</span>
                      </template>
                      <template v-else>
                        <span style="display: none">Offline</span>
                      </template>
                        <AgentMenu_Mac :agent="agent" />
                      </td>
                      <td>{{ agent.computerName }}</td>
                      <td>{{ agent.locationName }}</td>
                      <td>{{ mySudoUser(agent.computerID) }}
                        <template v-if="myCreateUser(agent.computerID) == true">
                        <va-icon class="icon ion-md-create"></va-icon></template>
                      </td>
                      <td>{{ myBrewVersion(agent.computerID) }}</td>
                      <td>{{ myAgentPackagePercent(agent.computerID) }}% ({{ myAgentUpdateCount(agent.computerID) }})</td>
                      <td>{{ myAgentLastScan(agent.computerID) }}</td>
                      <td v-if="agent.isEnabled === true">
                        <div v-if="myAgentIsEnabled(agent.computerID)">
                          <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span
                          >
                        </div>
                        <div v-else>
                          <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span
                          >
                        </div>
                      </td>
                      <td v-else>
                        <img src="/images/red_x.png" /><span style="display: none"
                          >disabled</span
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </template>
          <template>
            <p>No agents were returned for client.</p>
          </template>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
      <!-- end Main View card -->
    </div>
  </div>
</template>

<script>
import { doClientWork } from "@/workers/brew/brew-client-api";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import AgentMenu_Linux from "@/components/layout/AgentMenu-Linux";
import AgentMenu_Mac from "@/components/layout/AgentMenu-Mac";
import ClientMenu from "@/components/layout/ClientMenu";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "BrewClientManager",
  components: {
    AgentMenu_Linux,
    AgentMenu_Mac,
    ClientMenu,
  },
  data() {
    return {
      num_agents: 0,
      currentAvailablePackages: [],
      sudoUser: undefined,
      sudoPass: undefined,
      createUser: false,
      schedule: {},
      schedules: [],
      locations: [],
      package: {},
      packages: [],
      packageName: "",
      errors: [],
      clientConfig: {},
      client: {},
      clients: [],
      agents: [],
      agentsConfigs: [],
      selected: [],
      selectAll: false,
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.closeSudoForm();
    this.getTeam();
    this.getClient();
    this.getClients();
    this.getAgents();
    this.getConfig();
    this.getPackages();
    this.getLocations();
    this.getSchedules();
    this.getCurrentAvailablePackages();
  },
  methods: {
    myAgentIsEnabled(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].isEnabled;
        }
      }
    },
    openSudoForm() {
      document.getElementById("mySudoForm").style.display = "block";
    },
    closeSudoForm() {
      document.getElementById("mySudoForm").style.display = "none";
    },
    myAgentLastScan(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.format_date(this.agentsConfigs[i].lastScanDate);
        }
      }
    },
    myAgentUpdateCount(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          return this.agentsConfigs[i].updateCount;
        }
      }
    },
    myAgentPackagePercent(computerID) {
      let uc = 0;
      let pc = 0;
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          uc = this.agentsConfigs[i].updateCount;
          pc = this.agentsConfigs[i].packageCount;
          break; // Exit loop once the values are found
        }
      }
      if (isNaN(uc) || isNaN(pc)) {
        return 0;
      } else if((uc == 0)){
        return 100;
      } else if((pc == 0)){
        return 0;
      } else {
        return Math.round((uc/pc)*100);
      }
    },
    myBrewVersion(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          if (this.agentsConfigs[i].brewVersion == "") {
            return "Not Installed";
          } else {
            return this.agentsConfigs[i].brewVersion;
          }
        }
      }
    },
    mySudoUser(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          if (this.agentsConfigs[i].sudoUser == "") {
            return "Not Set";
          } else {
            return this.agentsConfigs[i].sudoUser;
          }
        }
      }
    },
    myCreateUser(computerID) {
      for (let i in this.agentsConfigs) {
        if (this.agentsConfigs[i].computerID === computerID) {
          if (this.agentsConfigs[i].createUser == false) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    async refreshClientData() {
      this.refreshAgents();
      this.getConfig();
    },
    // Get Client Data function
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClient() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/clients/${clientID}/`)
        .then((response) => {
          this.client = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/mac_agents_by_client/${clientID}`)
        .then((response) => {
          setTimeout(function () {
            $("#brewClientTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await axios
        .get(`/api/v1/brew/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });  

      this.$store.commit("setIsLoading", false);
    },

    async getConfig() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/brew/get_brew_client_config/${clientID}/`)
        .then((response) => {
          this.clientConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get locations Functions
    async getLocations() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/locations_by_client/${clientID}`)
        .then((response) => {
          this.num_locations = response.data.length;
          this.locations = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // refresh Agents Configs  function
    async refreshAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/mac_agents_by_client/${clientID}`)
        .then((response) => {
          this.num_agents = response.data.length;
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await axios
        .get(`/api/v1/brew/get_agents_configs_by_client/${clientID}/`)
        .then((response) => {
          this.agentsConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Enable Agents Function
    async enableAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/brew/enable_agents/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshClientData();

            toast({
              message: "HomeBrew status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Submit configuration Form Function
    async submitConfigForm() {
      this.$store.commit("setIsLoading", true);

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
      this.clientConfig.clientID = this.$route.params.id;
      await axios
        .post("/api/v1/brew/edit_brew_client_config/", this.clientConfig)
        .then((response) => {
          //console.log(response.data);
          this.refreshClientData();
          toast({
            message: "HomeBrew client configuration updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
      }
    },
    // Install Software Form Function
    async installSoftwareForm() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);

        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected.toString(),
          packageName: this.packageName,
        };
        await doClientWork(token, "installSoftware", CMDDATA);
        this.packageName = undefined
        this.refreshClientData();
        toast({
          message: "HomeBrew install new package sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    // Update Agents Function
    async updateAgents() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);

        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.$route.params.id,
          axiosBaseURL: axios.defaults.baseURL,
          agents: this.selected.toString(),
        };
        await doClientWork(token, "updateAgents", CMDDATA);
        this.selected = [];
        this.selectAll = false;
        toast({
          message: "HomeBrew client updates sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    // Get Agents Configs  function
    async rescanClient() {
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      const CMDDATA = {
        clientID: this.$route.params.id,
        axiosBaseURL: axios.defaults.baseURL,
      };
      await doClientWork(token, "rescanClient", CMDDATA);
      toast({
        message: "HomeBrew rescan client sent successfully",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
      this.$store.commit("setIsLoading", false);
    },

   // Submit Sudo Form function
   async submitSudoForm() {
      this.errors = [];
      if (this.sudoUser == undefined) {
        this.errors.push("You need to supply a username.");
      }
      if (this.sudoPass == undefined) {
        this.errors.push("You need to supply a Password.");
      }      
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more agents from the list and try again."
        );
      }
      if (!this.errors.length) {
        const data = {
          clientID: this.$route.params.id,
          sudoUser: this.sudoUser,
          sudoPass: this.sudoPass,
          createUser: this.createUser,
          agents: this.selected,
        };
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/brew/set_sudo_username/", data)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.sudoUser = undefined;
            this.sudoPass = undefined;
            this.createUser = false;
            this.closeSudoForm();
            this.refreshClientData();
            toast({
              message: "Sudo Username updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    editSchedule(index) {
      this.schedule = this.schedules[index];
    },
    //enable/disable schedule
    async enableSchedule(index) {
      if (this.schedules[index].isEnabled == false) {
        this.schedules[index].isEnabled = true;
      } else {
        this.schedules[index].isEnabled = false;
      }
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/brew/edit_brew_client_schedule/", this.schedules[index])
        .then((response) => {
          this.refreshSchedules();
          toast({
            message: "Schedule updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.getPackages();
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by API");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    async deleteSchedule(index) {
      const mySchedule = {
        id: this.schedules[index].id,
        name: this.schedules[index].location,
      };
      console.log("Delete schedule" + mySchedule);
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/brew/delete_brew_client_schedule/", mySchedule)
          .then((response) => {
            this.getSchedules();
            toast({
              message: "Removed schedule successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by API");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },
    // Submit schedule Form function
    async submitScheduleForm() {
      this.errors = [];
      this.schedule.clientID = this.$route.params.id;

      if (this.schedule.location === undefined) {
        this.errors.push("Location is missing");
      }
      if (this.schedule.isEnabled === undefined) {
        this.schedule.isEnabled = false;
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/brew/edit_brew_client_schedule/", this.schedule)
          .then((response) => {
            toast({
              message: "Schedule updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.getSchedules();
            this.schedule = {};
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by API");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    // Get Schedule Data function
    async getSchedules() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/brew/get_brew_client_schedules/${clientID}/`)
        .then((response) => {
          this.schedules = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    // Submit Package Form function
    async submitPackageForm() {
      this.errors = [];
      this.package.clientID = this.$route.params.id;

      if (this.package.packageName === undefined) {
        this.errors.push("Package name is missing");
      }
      if (this.package.isEnabled === undefined) {
        this.package.isEnabled = false;
      }
      if (this.package.pushToAllClients === undefined) {
        this.package.pushToAllClients = false;
      }
      //console.log(this.package.pushToAllClients);
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/brew/edit_brew_approved_package/", this.package)
          .then((response) => {
            toast({
              message: "Package updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.getPackages();
            this.package = {};
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by API");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async deletePackage(packageIndex) {
      const mypackage = {
        id: this.packages[packageIndex].id,
        name: this.packages[packageIndex].packageName,
      };
      console.log("Delete package " + mypackage);
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/brew/delete_brew_approved_package/", mypackage)
          .then((response) => {
            this.getPackages();
            toast({
              message: "Removed approved package successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by API");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
      }
    },
    async enablePackage(packageIndex) {
      if (this.packages[packageIndex].isEnabled == false) {
        this.packages[packageIndex].isEnabled = true;
      } else {
        this.packages[packageIndex].isEnabled = false;
      }
      this.packages[packageIndex].pushToAllClients = false;
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/brew/edit_brew_approved_package/", this.packages[packageIndex])
        .then((response) => {
          this.refreshPackages();
          toast({
            message: "Package updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.getPackages();
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by API");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    editPackage(packageIndex) {
      this.package = this.packages[packageIndex];
      //console.log("Edit package " + this.package.appName);
    },
    // Get Package Data function
    async getPackages() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/brew/get_brew_approved_packages/${clientID}/`)
        .then((response) => {
          this.packages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
        // Get Current Available Package Data function
        async getCurrentAvailablePackages() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/brew/get_current_package_versions/`)
        .then((response) => {
          this.currentAvailablePackages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style></style>

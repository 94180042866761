<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import axios from "axios";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);
export default {
  name: "SurfLogClientBrowserTypesPie",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 350,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    clientID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clientLogs: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Browsers In Use",
            data: [],
            backgroundColor: [
              "rgba(153, 102, 255, 0.4)",
              "rgba(201, 203, 27, 0.4)",
              "rgba(255, 99, 132, 0.4)",
              "rgba(255, 159, 64, 0.4)",
              "rgba(255, 205, 86, 0.4)",
              "rgba(75, 192, 192, 0.4)",
              "rgba(54, 162, 235, 0.4)",
            ],
            hoverOffset: 4,
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  mounted() {
    this.getClientLogs();
  },

  methods: {
    async getClientLogs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.clientID;
      let list = [];
      let bc = [];
      await axios
        .get(`/api/v1/surflog/get_surflog_client_browser_types/${clientID}/`)
        .then((response) => {
          response.data.forEach((value, index) => {
            list.push(value.webBrowser);
            bc.push(value.total);
          });
          this.chartData.labels = list;
          this.chartData.datasets[0].data = bc;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

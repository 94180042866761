<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">Chocolatey Agent Manager for {{ agent.computerName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: 'ChocolateyClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Agents
          </router-link>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#installPackage"
            aria-expanded="false"
            aria-controls="installPackage"
          >
            <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon> Install Package
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#installedSoftware"
            aria-expanded="false"
            aria-controls="installedSoftware"
          >
            <va-icon class="icon ion-md-list mr-1"></va-icon> Installed Software
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#siteCache"
            aria-expanded="false"
            aria-controls="siteCache"
          >
            <va-icon class="icon ion-md-cloud-outline mr-1"></va-icon> Site Cache
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#agentSources"
            aria-expanded="false"
            aria-controls="agentSources"
          >
            <va-icon class="icon ion-md-cloud-outline mr-1"></va-icon> Sources
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#chocoAgentErrorLogs"
            aria-expanded="false"
            aria-controls="chocoAgentErrorLogs"
          >
            <va-icon class="icon ion-md-today mr-1"></va-icon> Error Logs
          </button> 
          <button class="button is-success" @click="scanPackages">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Scan For Packages
          </button>
          <button class="button is-success" @click="updatePackages">
            <va-icon class="icon ion-md-checkmark-circle-outline mr-1"></va-icon> Update
            Package(s)
          </button>
          <button class="button is-danger" @click="deletePackages">
            <va-icon class="icon ion-md-close-circle-outline mr-1"></va-icon> Delete
            Package(s)
          </button>
          <button class="button is-danger" @click="purgeAgentPackages">
            <va-icon class="icon ion-md-close-circle-outline mr-1"></va-icon> Purge Agent
            Data
          </button>
        </div>
      </div>
      <!-- End of Buttons -->

      <!-- status box  -->
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin30Days(agentConfig.lastSync) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfig.lastSync) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfig.lastSync) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Updates:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin30Days(agentConfig.lastUpdate) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfig.lastUpdate) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfig.lastUpdate) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Install:</strong></p></span>
            <span class="option">
              <p class="text-success" style="font-size: small">
                  {{ format_date(agentConfig.lastInstall) }}
                </p>
            </span>
          </div>          
          <template v-if="agentConfig.chocoInstalled === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Chocolatey Version:</strong></p></span
              >
              <span class="option">
                {{ agentConfig.chocoVersion }}
              </span>
            </div>
          </template>
          <div class="tabletitle-slim">
            <span class="name"><strong>Updates:</strong></span>
            <span class="option"> [ {{ agentConfig.updateCount }} ] Packages </span>
          </div>
          <template v-if="agentConfig.isEnabled === true">
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"
                ><p><strong>Agent Enabled:</strong></p></span
              >
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- Start hidden drop down areas -->

<!-- Agent Error Log drop down -->
<div class="column is-12">
        <div class="panel-collapse collapse" id="chocoAgentErrorLogs">
          <div class="card card-body">
            <h1 class="subtitle">Current Agent Errors</h1>
            <p class="mb-5">
              If any agent during the last run of either automated installs or updates, leaves any error logs in the standard chocolatey log, they are captured during the next package scan. If logs are listed here, they will be from the enabled agent under this client.
            </p>

            <hr />
            <template v-if="agentLogs.length != 0">
            <table class="table is-fullwidth" id="chocolateyAgentErrorLogsTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Log</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="log in agentLogs" v-bind:key="log.id">
                    <td>{{ log.logDate }}</td>
                    <td>{{ log.log }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p>No agent error logs at this time.</p>

          </template>
          </div>
        </div>
      </div>  


      <!-- Install Package drop down -->
      <div class="column is-12">
        <div class="panel-collapse collapse" id="installPackage">
          <div class="card card-body">
            <h1 class="subtitle">Install Chocolatey Package</h1>
            <p class="mb-5">
              To install a package you can select from the approved packages list or you
              can provide a package name to be installed. If you select to install a
              unapproved package the agent will be instructed to get that package directly
              from the public repositories.
            </p>
            <form @submit.prevent="installPackageForm" class="row g-4">
              <label class="form-checkbox">
                <input type="checkbox" v-model="showApprovedPackages" />
                <va-icon class="form-icon"></va-icon> Install using an Approved Package
              </label>
              <template v-if="showApprovedPackages == false">
                <div class="col-auto">
                  <div class="field">
                    <label>Package Name</label>
                    <div class="control">
                      <input type="text" class="input" v-model="installPackageName" />
                    </div>
                  </div></div
              ></template>
              <template v-else>
                <div class="col-auto">
                  <div class="field">
                    <label class="select-inline mr-2">Approved Packages</label>
                    <div class="control mt-1">
                      <div class="select">
                        <select v-model="installApprovedPackage">
                          <option
                            v-for="p in approvedPackages"
                            :value="p.packageName"
                            :key="p.id"
                          >
                            {{ p.packageName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div></template
              >
              <div class="col-auto">
                <div class="field">
                  <div class="control mt-4">
                    <button class="button is-success">
                      <va-icon class="icon ion-md-checkbox-outline mr-1"></va-icon>
                      Install
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Sources drop down -->
      <div class="column is-12">
        <div class="panel-collapse collapse" id="agentSources">
          <div class="card card-body">
            <h1 class="subtitle">Chocolatey Repository Sources</h1>
            <p class="mb-5">
              The list of sources below are the current sources the agent is reporing as
              configured withing Chocolatey.
            </p>
            <table class="table" id="agentSources">
              <thead>
                <tr>
                  <th style="width: 30px"></th>
                  <th>Name</th>
                  <th>URL</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(mysource, itemObjKey) in sources" v-bind:key="mysource.id">
                  <td>
                    <div class="dropdown">
                      <button class="dropbtn">
                        <va-icon class="icon ion-md-apps"></va-icon>
                      </button>
                      <div class="dropdown-content">
                        <a href="#" @click="deleteSource(mysource.name)"
                          ><va-icon class="icon ion-md-trash mr-1"></va-icon> Remove
                          Source</a
                        >
                      </div>
                    </div>
                  </td>
                  <td>{{ mysource.name }}</td>
                  <td>{{ mysource.URL }}</td>
                  <td>{{ mysource.priority }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Site Cache DropDown -->
      <div class="panel-collapse collapse" id="siteCache">
        <div class="column is-12">
          <div class="card card-body">
            <h1 class="subtitle">Site Cache</h1>
            <p class="mb-3">
              By default the agent is set to stand alone, which will communicate directly
              with the Cocolatey Repositories, bypassing an caching services. You can
              elect to use site caching if configured which will allow you to have a lot
              of agents using chocolatey at a single location. This allows you to bypass
              any blocks or throttling the public repositories may enforce. Set the agent
              to standalone if you are using a Chocolatey MSP Licenses on agents.
            </p>
            <p>
              Select either standalone or uncheck standalone and select a configured
              caching site from dropdown list
            </p>

            <form @submit.prevent="submitSiteForm" class="row g-4">
              <div class="col-2">
                <div class="field">
                  <div class="control">
                    <label class="checkbox-inline"
                      ><input
                        class="form-check-input mr-2"
                        type="checkbox"
                        v-model="agentConfig.standAlone"
                      />Standalone agent</label
                    >
                  </div>
                </div>
              </div>
              <template v-if="agentConfig.standAlone !== true">
                <div class="col-4">
                  <div class="field">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                        checked
                        disabled
                      />
                      <label class="select-inline mr-2"
                        >Agent Is Using Caching Site</label
                      >
                    </div>
                    <div class="control mt-1">
                      <div class="select">
                        <select v-model="agentConfig.siteID">
                          <template v-for="site in sites">
                            <template v-if="site.isEnabled == true">
                              <option :value="site.id">
                                {{ site.siteName }}
                              </option></template
                            >
                            <template v-else>
                              <template v-if="agentConfig.siteID == site.id">
                                <option disabled :value="site.id">
                                  {{ site.siteName }}
                                </option>
                              </template>
                              <template v-else>
                                <option disabled>
                                  {{ site.siteName }}
                                </option>
                              </template>
                            </template>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control mt-3">
                      <p>
                        Do you want this agent to be responsible for communicating with
                        repositories?
                      </p>
                      <label class="checkbox-inline"
                        ><input
                          class="form-check-input mr-2"
                          type="checkbox"
                          v-model="agentConfig.cacheManager"
                        />Set as the cache manager for selected site</label
                      >
                    </div>
                  </div>
                </div>
              </template>
              <template v-else></template>
              <div class="col-1">
                <div class="field">
                  <div class="control mt-5">
                    <button class="button is-success ml-6">
                      <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Installed Software Drop Down  -->
      <div class="column is-12">
        <div class="panel-collapse collapse" id="installedSoftware">
          <div class="card card-body">
            <h1 class="subtitle">Windows Installed Software</h1>
            <p class="mb-5">Installed software list from Windows.</p>

            <table class="table is-fullwidth" id="installedSoftwares">
              <thead>
                <tr>
                  <th>Application</th>
                  <th>Version</th>
                  <th>Last Update</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(mysoft, itemObjKey) in software" v-bind:key="mysoft.id">
                  <td><strong>{{ mysoft.appName }}</strong></td>
                  <td>{{ mysoft.appVersion }}</td>
                  <td>{{ format_date(mysoft.installDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Main View Installed Packages -->

      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle mb-5">
            <span class="name"
              ><h1 class="subtitle">Installed Chocolatey Packages</h1></span
            >
            <span class="option">
              <button class="button is-success ml-5" @click="refreshAgent()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>
          <table class="table" id="installedPackages">
            <thead>
              <tr>
                <th style="width: 15px">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="selectAll" @click="select" />
                    <va-icon class="form-icon"></va-icon>
                  </label>
                </th>
                <th>Package</th>
                <th>Installed Version</th>
                <th>Current Version</th>
                <th>Last Update</th>
                <th>Current</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(mypackage, itemObjKey) in packages" v-bind:key="mypackage.id">
                <td>
                  <label class="form-checkbox">
                    <input type="checkbox" :value="mypackage.id" v-model="selected" />
                    <va-icon class="form-icon"></va-icon>
                  </label>
                </td>
                <td><strong>{{ mypackage.packageName }}</strong></td>
                <td>{{ mypackage.packageVersion }}</td>
                <td>{{ mypackage.availableVersion }}</td>
                <td>{{ format_date(mypackage.lastUpdate) }}</td>
                <td
                  v-if="
                    currentPackageStatus(
                      mypackage.availableVersion,
                      mypackage.packageVersion
                    ) === true
                  "
                >
                  <img src="/images/green_check.png" /><span style="display: none"
                              >enabled</span>
                </td>
                <td v-else><img src="/images/red_x.png" /><span style="display: none"
                              >disabled</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { toast } from "bulma-toast";
export default {
  name: "ChocolateyAgentManager",
  data() {
    return {
      showApprovedPackages: false,
      errors: [],
      selected: [],
      selectAll: false,
      clientID: "",
      agent: {},
      agentConfig: {},
      siteID: "",
      mypackage: {},
      packages: [],
      agentLogs: [],
      approvedPackages: [],
      installApprovedPackage: undefined,
      installPackageName: undefined,

      software: [],
      sources: [],
      sites: [],
      site: {},
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getAgent();
    this.getAgentConfig();
    this.getPackages();
    this.getSoftware();
    this.getSources();
    this.getAgentLogs();

  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.packages) {
          this.selected.push(this.packages[i].id);
        }
      }
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },
    currentPackageVersion(packageName) {
      for (let i in this.currentAvailablePackages) {
        if (this.currentAvailablePackages[i].packageName === packageName) {
          return this.currentAvailablePackages[i].version;
        }
      }
    },
    currentPackageStatus(availableVersion, currentVersion) {
      const arr1 = availableVersion.split('.').map(Number);
      const arr2 = currentVersion.split('.').map(Number);
      const maxLength = Math.max(arr1.length, arr2.length);
      while (arr1.length < maxLength) arr1.push(0);
      while (arr2.length < maxLength) arr2.push(0);
      for (let i = 0; i < maxLength; i++) {
        if (arr1[i] < arr2[i]) {
            return false; // version1 is less than version2
        } else if (arr1[i] > arr2[i]) {
            return false // version1 is greater than version2
        }
      }      
        return true; // version1 and version2 are equal
    },
    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${computerID}/`)
        .then((response) => {
          this.agent = response.data;
          this.clientID = this.agent.clientID;
        })
        .catch((error) => {
          console.log(error);
        });
        const clientID = this.clientID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_sites/${clientID}/`)
        .then((response) => {
          this.sites = response.data;
        })
        .catch((error) => {
          console.log(error);
        }); 
        await axios
        .get(`/api/v1/chocolatey/get_chocolatey_packages/${clientID}/`)
        .then((response) => {
          this.approvedPackages = response.data;
        })
        .catch((error) => {
          console.log(error);
        }); 
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // refresh agent data
    refreshAgent() {
      this.getAgentConfig();
      this.refreshPackages();
      this.getApprovedPackages();
      this.refreshSoftware();
      this.getSources();
      this.getAgentLogs();

    },

    async getSources() {
      this.$store.commit("setIsLoading", true);
      const ID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_sources/${ID}/`)
        .then((response) => {
          this.sources = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getAgentConfig() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_config/${computerID}/`)
        .then((response) => {
          this.agentConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getPackages() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      const clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_packages/${computerID}/`)
        .then((response) => {
          this.packages = response.data;
          setTimeout(function () {
            $("#installedPackages").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
            });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get all agent logs Functions
    async getAgentLogs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_logs/${computerID}`)
        .then((response) => {   
          this.agentLogs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        setTimeout(function () {
            $("#chocolateyAgentErrorLogsTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
      this.$store.commit("setIsLoading", false);
    },


    async refreshPackages() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      const clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/chocolatey/get_chocolatey_agent_packages/${computerID}/`)
        .then((response) => {
          this.packages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getSoftware() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      const clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/chocolatey/get_installed_software/${computerID}/`)
        .then((response) => {
          this.software = response.data;
          setTimeout(function () {
            $("#installedSoftwares").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
            });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async refreshSoftware() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;

      await axios
        .get(`/api/v1/chocolatey/get_installed_software/${computerID}/`)
        .then((response) => {
          this.software = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async submitSiteForm() {
      this.errors = [];
      this.site.clientID = this.clientID;
      this.site.computerID = this.$route.params.id;
      if (this.agentConfig.standAlone === undefined) {
        this.agentConfig.standAlone = false;
      }
      if (this.agentConfig.siteID === undefined) {
        this.agentConfig.siteID = 0;
      }
      if (this.agentConfig.siteID === null) {
        this.agentConfig.siteID = 0;
      }
      if (this.agentConfig.siteID === "") {
        this.agentConfig.siteID = 0;
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/set_chocolatey_site/", this.agentConfig)
          .then((response) => {
            this.getAgentConfig();
            toast({
              message: "Chocolatey agent configuration updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async enablePackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more packages from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post(`/api/v1/chocolatey/enable_packages/`, this.selected)
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.getPackages();

            toast({
              message: "Package status updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async updatePackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more packages from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const computerID = this.$route.params.id;
        await axios
          .post(
            `/api/v1/chocolatey/upgrade_packages_for_agent/${computerID}/`,
            this.selected
          )
          .then((response) => {
            this.selected = [];
            this.selectAll = false;

            toast({
              message: "Update package(s) request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
        this.selected = [];
        this.selectAll = false;
      }
    },
    async deletePackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more packages from the list and try again."
        );
      }
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const computerID = this.$route.params.id;
        await axios
          .post(
            `/api/v1/chocolatey/remove_packages_for_agent/${computerID}/`,
            this.selected
          )
          .then((response) => {
            this.selected = [];
            this.selectAll = false;
            this.refreshPackages();
            toast({
              message: "Delete package(s) request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              //console.log("selected " + selected);
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              //console.log("selected " + selected);
              this.errors.push(this.errors.message);
            }
          });
        this.$store.commit("setIsLoading", false);
        this.selected = [];
        this.selectAll = false;
      }
    },
    async installPackageForm() {
      this.errors = [];
      let install_package = "";
      if (this.installApprovedPackage === undefined) {
        if (this.installPackageName === undefined) {
          this.errors.push(
            "You must eiter select a approved package or provide a package name to install."
          );
        }
      }
      if (this.installPackageName && this.showApprovedPackages == false) {
        install_package = this.installPackageName;
      } else {
        install_package = this.installApprovedPackage;
      }
      if (this.install_package == "") {
        this.errors.push(
          "Unable to determine package name, please make a new selection."
        );
      }
      const myAgent = {
        packageName: install_package,
        computerID: this.$route.params.id,
      };

      if (!this.errors.length) {
        this.errors = [];
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/install_chocolatey_package/", myAgent)
          .then((response) => {
            toast({
              message: "Chocolatey agent sent package install request",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async deletePackage(packageIndex) {
      this.errors = [];
      const mypackage = this.packages[packageIndex];
      //console.log("Delete package " + mypackage.packageName);
      const myAgent = {
        packageName: mypackage.packageName,
        computerID: this.$route.params.id,
      };
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/chocolatey/remove_chocolatey_package/", myAgent)
        .then((response) => {
          this.refreshPackages();
          toast({
            message: "Chocolatey agent sent package uninstall request",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
    async updatePackage(packageIndex) {
      this.errors = [];
      const mypackage = this.packages[packageIndex];
      "Updating package  " + mypackage.packageName;
      const myAgent = {
        packageName: mypackage.packageName,
        computerID: this.$route.params.id,
      };
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/chocolatey/upgrade_chocolatey_package/", myAgent)
        .then((response) => {
          toast({
            message: "Chocolatey agent sent upgrade request",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },

    async deleteSoftware(packageIndex) {
      this.errors = [];
      const myAgent = {
        packageName: this.software[packageIndex].appName,
        computerID: this.$route.params.id,
      };
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/chocolatey/uninstall_agent_software/", myAgent)
        .then((response) => {
          toast({
            message: "Chocolatey agent sent software uninstall request",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },

    async createCacheShare(packageIndex) {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/create_cache_share/${computerID}/`)
        .then((response) => {
          this.getAgentConfig();
          toast({
            message: "Chocolatey cache share created successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });
    },
    async removeCacheShare(packageIndex) {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/remove_cache_share/${computerID}/`)
        .then((response) => {
          this.getAgentConfig();
          toast({
            message: "Chocolatey cache share created successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });
    },
    //Purge agent data  function
    async purgeAgentPackages() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/chocolatey/purge_chocolatey_agent_packages/${computerID}/`)
        .then((response) => {
          toast({
            message: "Chocolatey agent data purged",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async scanPackages() {
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        await axios
          .post("/api/v1/chocolatey/sync/installed_packages_on_agent/", this.agentConfig)
          .then((response) => {
            this.getAgentConfig();
            toast({
              message: "Chocolatey agent package update request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push("Data was rejected by backend");
            } else if (this.errors.message) {
              this.errors.push(this.errors.message);
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
    async deleteSource(sourceName) {
      const myagent = {
        computerID: this.$route.params.id,
        sourceName: sourceName
      }
      this.$store.commit("setIsLoading", true);
      await axios
        .post(`/api/v1/chocolatey/remove_chocolatey_source/`, myagent)
        .then((response) => {
          this.getSources();
          toast({
            message: "Chocolatey source removal request sent successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push("Data was rejected by backend");
          } else if (this.errors.message) {
            this.errors.push(this.errors.message);
          }
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

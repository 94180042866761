<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import axios from "axios";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);
export default {
  name: "SurfLogClientProtocolTypesPie",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 350,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    clientID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clientLogs: [],
      chartData: {
        labels: ["HTTP", "HTTPS"],
        datasets: [
          {
            label: "Protocols In Use",
            data: [],
            backgroundColor: ["rgba(255, 20, 52, 0.4)", "rgba(75, 192, 192, 0.4)"],
            hoverOffset: 4,
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  mounted() {
    this.getClientLogs();
  },

  methods: {
    async getClientLogs() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.clientID;
      await axios
        .get(`/api/v1/surflog/get_surflog_client_protocol_types/${clientID}/`)
        .then((response) => {
          this.chartData.datasets[0].data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="card card-body">
            <div class="tabletitle-slim">
              <span class="name">
                <h1 class="subtitle">{{logName}}</h1></span>
              <span class="option">
                <template v-if="this.$route.params.logname == 'BitLocker'">
                <router-link
                class="button"
                  :to="{
                      name: 'BitLockerClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> BitLocker Manager
                </router-link></template>
                <template v-else-if="this.$route.params.logname == 'BleachBit'">
                <router-link
                class="button"
                  :to="{
                      name: 'BleachBitClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> BleachBit Manager
                </router-link></template>
                <template v-else-if="this.$route.params.logname == 'Chocolatey'">
                <router-link
                class="button"
                  :to="{
                      name: 'ChocolateyClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> Chocolatey Manager
                </router-link></template>   
                <template v-else-if="this.$route.params.logname == 'Brew'">
                <router-link
                class="button"
                  :to="{
                      name: 'BrewClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> Homebrew Manager
                </router-link></template>   
                <template v-else-if="this.$route.params.logname == 'LUM'">
                <router-link
                class="button"
                  :to="{
                      name: 'LinuxUpdateClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> Linux Update Manager
                </router-link></template>
                <template v-else-if="this.$route.params.logname == 'Defender'">
                <router-link
                class="button"
                  :to="{
                      name: 'DefenderClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> Defender Manager
                </router-link></template>   
                <template v-else-if="this.$route.params.logname == 'SurfLog'">
                <router-link
                class="button"
                  :to="{
                      name: 'SurfLogClientManager',
                      params: { id: this.$route.params.id },
                  }">
                    <va-icon class="icon ion-md-return-left mr-1"></va-icon> SurfLog Manager
                </router-link></template>   
                
              </span>
            </div>
            <div class="mt-2"></div>
            <table class="table is-fullwidth" id="serviceLogsTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Client / Agent</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in logs" v-bind:key="log.id">
                  <td>{{ format_date(log.logDate) }}</td>
  
                  <td>
                    <template v-if="log.ClientID !== ''"
                      >{{ getClientName(log.clientID) }}
                      <template v-if="log.computerID"
                        >-> {{ getAgentName(log.computerID) }}</template
                      >
                    </template>
                    <template v-else-if="log.computerID !== ''">
                      {{ getAgentName(log.computerID) }}
                    </template>
                    <template v-else>All Enabled Clients</template>
                  </td>
                  <td>{{ log.logMessage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //Bootstrap and jQuery libraries
  import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
  import "jquery/dist/jquery.min.js";
  //Datatable Modules
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "datatables.net-buttons/js/dataTables.buttons.js";
  import "datatables.net-buttons/js/buttons.colVis.js";
  import "datatables.net-buttons/js/buttons.flash.js";
  import "datatables.net-buttons/js/buttons.html5.js";
  import "datatables.net-buttons/js/buttons.print.js";
  import $ from "jquery";
  import moment from "moment";
  import axios from "axios";
  export default {
    name: "LogViewer",
    data() {
      return {
        logs: [],
        logName: '',
        myclientID: '',
        num_agents: 0,
        agents: [],
        errors: [],
        team: {
          members: [],
          sites: [],
          created_by: {},
        },
        clients: [],
      };
    },
    mounted() {
      this.getTeam();
      this.getLogs();
      this.getClients();
      this.getAgents();
    },
    methods: {
      format_date(value) {
        if (value) {
          return moment(String(value)).format("MM/DD/YYYY hh:mm A");
        }
      },
      getClientName(value) {
        if (value) {
          for (let i in this.clients) {
            if (this.clients[i].clientID == value) {
              return this.clients[i].clientName;
            }
          }
        }
      },
      getAgentName(value) {
        if (value) {
          for (let i in this.agents) {
            if (this.agents[i].computerID == value) {
              return this.agents[i].computerName;
            }
          }
        }
      },
      async getAgents() {
        this.$store.commit("setIsLoading", true);
        await axios
          .get(`/api/v1/agents/`)
          .then((response) => {
            this.agents = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
  
        this.$store.commit("setIsLoading", false);
      },
      async getClients() {
        this.$store.commit("setIsLoading", true);
        this.showNextButton = false;
        this.showPreviousButton = false;
        await axios
          .get(`/api/v1/clients/`)
          .then((response) => {
            this.clients = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
  
        this.$store.commit("setIsLoading", false);
      },
      async getLogs() {
        this.$store.commit("setIsLoading", true);
        let $url = 'None'
        if(this.$route.params.logname == "BitLocker"){
          $url = '/api/v1/logs/get_team_bitlocker_logs';
        }else if(this.$route.params.logname == "BleachBit"){
          $url = '/api/v1/logs/get_team_bleachbit_logs';
        }else if(this.$route.params.logname == "BleachBit"){
          $url = '/api/v1/logs/get_team_bleachbit_logs';
        }else if(this.$route.params.logname == "Chocolatey"){
          $url = '/api/v1/logs/get_team_chocolatey_logs';
        }else if(this.$route.params.logname == "Brew"){
          $url = '/api/v1/logs/get_team_brew_logs';
        }else if(this.$route.params.logname == "LUM"){
          $url = '/api/v1/logs/get_team_lum_logs';
        }else if(this.$route.params.logname == "Defender"){
          $url = '/api/v1/logs/get_team_defender_logs';
        }else if(this.$route.params.logname == "SurfLog"){
          $url = '/api/v1/logs/get_team_surflog_logs';
        }
        await axios
          .get($url)
          .then((response) => {
            this.logs = response.data;
            setTimeout(function () {
              $("#serviceLogsTable").DataTable({
                pagingType: "full_numbers",
                pageLength: 50,
                processing: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                   "<'row'<'col-sm-12'tr>>" +
                   "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                   "<'row'<'col-sm-12'B>>",
                lengthMenu: [
                  [25, 50, 100, -1],
                  [25, 50, 100, "All"],
                ],
                order: [[0, "desc"]],
                buttons: ["copy", "csv", "print"],
              });
            }, 100);
          })
          .catch((error) => {
            console.log(error);
          });
        this.$store.commit("setIsLoading", false);
      },
      async getTeam() {
        this.$store.commit("setIsLoading", true);
        this.logName = this.$route.params.logname
        this.myclientID = this.$route.params.id
        await axios
          .get("/api/v1/teams/get_my_team/")
          .then((response) => {
            this.team = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
  
        this.$store.commit("setIsLoading", false);
      },
    },
  };
  </script>
  
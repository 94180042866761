<template>
  <template v-if="isNaN(myPercent) === false">
    <div class="tabletitle">
      <span class="name">BleachBit </span><span class="option"> [{{ myPercent }}%]</span>
    </div>
    <span><va-progress-bar :size="25" :color="myColor" :model-value="myPercent" /></span>
  </template>
</template>
<script>
import axios from "axios";
export default {
  name: "BleachBitResentSync",

  data() {
    return {
      configs: [],
      myMax: 0,
      myUpdated: 0,
      myPercent: 0,
      myColor: "danger",
    };
  },
  mounted() {
    this.getUpdates();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    async getUpdates() {
      await axios
        .get("/api/v1/bleachbit/get_all_bleachbit_agent_configs/")
        .then((response) => {
          this.configs = response.data;
          this.myMax = this.configs.length;
          this.myUpdated = this.configs.filter((agent) =>
            this.checkDateWithin3Days(agent.lastSync)
          ).length;
          this.myPercent = Math.round((this.myUpdated / this.myMax) * 100);
          if (Number(Math.round((this.myUpdated / this.myMax) * 100)) >= Number(90)) {
            this.myColor = "success";
          } else if (
            Number(Math.round((this.myUpdated / this.myMax) * 100)) >= Number(70)
          ) {
            this.myColor = "warning";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkDateWithin3Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 3
      );
      return new Date(date) > oneMonthEarlier;
    },
  },
};
</script>

<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark" aria-label="mynav">
    <div class="container-fluid">
      <div class="navbar-brand">
        <router-link to="/">
          <img src="/images/rmmmax-logo-medium-white-letters-150px.png" alt="RMMmax" />
          <template v-if="$store.state.isAuthenticated" :key="version">
            <template v-if="this.loop == 0"> <div v-if="getVersion()"></div></template>
            <div class="nav_logo">Build: {{ RMMMAX_build }}</div>
          </template>
        </router-link>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mynav"
        aria-controls="mynav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mynav">
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <template v-if="$store.state.isAuthenticated">
                <router-link to="/dashboard" class="button" style="text-decoration: none"
                  ><va-icon class="icon ion-md-tv mr-1"></va-icon> Dashboard</router-link
                >
              </template>
              <template v-if="!$store.state.isAuthenticated">

                <a
                  href="http://rmmmax.com"
                  class="button is-info"
                  style="text-decoration: none"
                  >Back to RMMmax</a
                >
              </template>
              <template v-else>
                <router-link
                  to="/dashboard/team"
                  class="button is-info"
                  style="text-decoration: none"
                  ><va-icon class="icon ion-md-cog mr-1"></va-icon> Account</router-link
                >
                <button @click="logout()" class="button is-danger">
                  <va-icon class="icon ion-md-log-out mr-1"></va-icon> Logout
                </button>
                <a
                  class="button is-success mb-2"
                  href="https://docs.rmmmax.com"
                  target="_blank"
                  style="text-decoration: none"
                >
                  <va-icon class="icon ion-md-help-circle-outline"></va-icon>
                  <strong>Help Center</strong>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  name: "Navbar",
  data() {
    return {
      RMMMAX_build: "",
      loop: 0,
    };
  },
  mounted() {},
  methods: {
    async getVersion() {
      await axios
        .get(`/api/v1/version/`)
        .then((response) => {
          this.RMMMAX_build = response.data;
          console.log(response.data);
          this.loop = 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async logout() {
      await axios
        .get("/api/v1/logs/logout_user_access")
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
      await axios
        .post("/api/v1/token/logout")
        .then((response) => {
          console.log("Logged out");
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      this.$store.commit("removeToken");
      this.$router.push("/");
    },
  },
};
</script>
<style>
.nav_logo {
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  color: #fff;
}
</style>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="card card-body">
          <h1 class="subtitle">Service Logs</h1>
          <table class="table is-fullwidth" id="serviceLogsTable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Client / Agent</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in logs" v-bind:key="log.id">
                <td>{{ format_date(log.logDate) }}</td>

                <td>
                  <template v-if="log.ClientID !== ''"
                    >{{ getClientName(log.clientID) }}
                    <template v-if="log.computerID"
                      >-> {{ getAgentName(log.computerID) }}</template
                    >
                  </template>
                  <template v-else-if="log.computerID !== ''">
                    {{ getAgentName(log.computerID) }}
                  </template>
                  <template v-else>All Enabled Clients</template>
                </td>
                <td>{{ log.logMessage }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
export default {
  name: "TeamServiceLogs",
  data() {
    return {
      logs: [],
      num_agents: 0,
      agents: [],
      errors: [],
      team: {
        members: [],
        sites: [],
        created_by: {},
      },
      clients: [],
    };
  },
  mounted() {
    this.getTeam();
    this.getTeamServiceLogs();
    this.getClients();
    this.getAgents();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    getClientName(value) {
      if (value) {
        for (let i in this.clients) {
          if (this.clients[i].clientID == value) {
            return this.clients[i].clientName;
          }
        }
      }
    },
    getAgentName(value) {
      if (value) {
        for (let i in this.agents) {
          if (this.agents[i].computerID == value) {
            return this.agents[i].computerName;
          }
        }
      }
    },
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/agents/`)
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClients() {
      this.$store.commit("setIsLoading", true);
      this.showNextButton = false;
      this.showPreviousButton = false;
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getTeamServiceLogs() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/logs/get_team_service_logs`)
        .then((response) => {
          this.logs = response.data;
          setTimeout(function () {
            $("#serviceLogsTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 50,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              order: [[0, "desc"]],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

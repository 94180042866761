import { createRouter, createWebHistory } from 'vue-router'
/* Main Views */
import store from '../store'
import SignUp from '../views/SignUp.vue'
import Login from '../views/Login.vue'
//import About from '../views/AboutView.vue'
//import LearnMore from '../views/LearnMore.vue'
/* DashBoard Views */
import Dashboard from '../views/dashboard/Dashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import SyncRMM from '../views/dashboard/SyncRMM.vue'
import RMMEnvironment from '../views/dashboard/RMMEnvironment.vue'
import Notifications from '../views/dashboard/Notifications.vue'
import Agents from '../views/dashboard/Agents.vue'
import AllAgents from '../views/dashboard/AllAgents.vue'
import Agent from '../views/dashboard/Agent.vue'
import AddTeam from '../views/dashboard/AddTeam.vue'
import Team from '../views/dashboard/Team.vue'
import EditTeam from '../views/dashboard/EditTeam.vue'
import AddMember from '../views/dashboard/AddMember.vue'
import EditMember from '../views/dashboard/EditMember.vue'
import Clients from '../views/dashboard/Clients.vue'
import Client from '../views/dashboard/Client.vue'
import SignupThankyou from '../views/dashboard/SignupThankyou.vue'
import Plans from '../views/dashboard/Plans.vue'
import PlansThankyou from '../views/dashboard/PlansThankyou.vue'

/* Chocolatey Views */
import ChocolateyAgentManager from '../views/chocolatey/ChocolateyAgentManager.vue'
import ChocolateyClientManager from '../views/chocolatey/ChocolateyClientManager.vue'
import ChocolateyBlockTree from '../views/chocolatey/ChocolateyBlockTree.vue'
/* SurfLog Views */
import SurfLogAgentManager from '../views/surflog/SurfLogAgentManager.vue'
import SurfLogClientManager from '../views/surflog/SurfLogClientManager.vue'
import SurfLogClientRawLogs from '../views/surflog/SurfLogClientRawLogs.vue'

/* BitLocker Views */
import BitLockerAgentManager from '../views/bitlocker/BitLockerAgentManager.vue'
import BitLockerClientManager from '../views/bitlocker/BitLockerClientManager.vue'
import BitLockerKeyProtectorExport from '../views/bitlocker/BitLockerKeyProtectorExport.vue'

/* BitLocker Views */
import BleachBitAgentManager from '../views/bleachbit/BleachBitAgentManager.vue'
import BleachBitClientManager from '../views/bleachbit/BleachBitClientManager.vue'

/* Linux Update Manager Views */
import LinuxUpdateAgentManager from '../views/lum/LinuxUpdateAgentManager.vue'
import LinuxUpdateClientManager from '../views/lum/LinuxUpdateClientManager.vue'

/* HomeBrew Manager Views */
import BrewAgentManager from '../views/brew/BrewAgentManager.vue'
import BrewClientManager from '../views/brew/BrewClientManager.vue'

/* Defender Views */
import DefenderAgentManager from '../views/defender/DefenderAgentManager.vue'
import DefenderClientManager from '../views/defender/DefenderClientManager.vue'

/* Logs Views */
import TeamServiceLogs from '../views/logs/TeamServiceLogs.vue'
import LogViewer from '../views/logs/LogViewer.vue'

const routes = [

  /* Main Routes */
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta:{
      requireLogin: false
    }
  },
  {
    path: '/signup_thankyou',
    name: 'SignupThankyou',
    component: SignupThankyou,
    meta:{
      requireLogin: false
    }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta:{
      requireLogin: false
    }
  },
 /* {
    path: '/learn_more',
    name: 'LearnMore',
    component: LearnMore,
    meta:{
      requireLogin: false
    }
  },
  {
    path: '/',
    name: 'About',
    component: About,
    meta:{
      requireLogin: false
    }
  },
*/
  /* DashBoard routes */
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/myaccount',
    name: 'MyAccount',
    component: MyAccount,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/syncrmm',
    name: 'SyncRMM',
    component: SyncRMM,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/rmmenvironment',
    name: 'RMMEnvironment',
    component: RMMEnvironment,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/notifications',
    name: 'Notifications',
    component: Notifications,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/agents',
    name: 'Agents',
    component: Agents,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/all_agents',
    name: 'AllAgents',
    component: AllAgents,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/agents/:id',
    name: 'Agent',
    component: Agent,
    meta:{
      requireLogin: true
    }
  },

  {
    path: '/dashboard/team',
    name: 'Team',
    component: Team,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/add-team',
    name: 'AddTeam',
    component: AddTeam,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/edit-team',
    name: 'EditTeam',
    component: EditTeam,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/add-member',
    name: 'AddMember',
    component: AddMember,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/edit-member/:id',
    name: 'EditMember',
    component: EditMember,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/teams/plans',
    name: 'Plans',
    component: Plans,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/teams/plans/thankyou',
    name: 'PlansThankyou',
    component: PlansThankyou,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients',
    name: 'Clients',
    component: Clients,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/dashboard/client',
    name: 'Client',
    component: Client,
    meta:{
      requireLogin: true
    }
  },
  /* Chocolatey Routes */
  {
    path: '/chocolatey/manager/agent/:id',
    name: 'ChocolateyAgentManager',
    component: ChocolateyAgentManager,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/chocolatey/manager/client/:id',
    name: 'ChocolateyClientManager',
    component: ChocolateyClientManager,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/chocolatey/manager/sites/:id',
    name: 'ChocolateyBlockTree',
    component: ChocolateyBlockTree,
    meta:{
      requireLogin: true
    }
  },

  /* SurfLog Routes */
  {
    path: '/surflog/manager/agent/:id',
    name: 'SurfLogAgentManager',
    component: SurfLogAgentManager,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/surflog/manager/client/:id',
    name: 'SurfLogClientManager',
    component: SurfLogClientManager,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/surflog/manager/client/logs/:id',
    name: 'SurfLogClientRawLogs',
    component: SurfLogClientRawLogs,
    meta:{
      requireLogin: true
    }
  },
  /* BitLocker Routes */
  {
    path: '/bitlocker/manager/agent/:id',
    name: 'BitLockerAgentManager',
    component: BitLockerAgentManager,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/bitlocker/manager/client/:id',
    name: 'BitLockerClientManager',
    component: BitLockerClientManager,
    meta:{
      requireLogin: true
    }
  },
  {
    path: '/bitlocker/manager/client/exportkeys/:id',
    name: 'BitLockerKeyProtectorExport',
    component: BitLockerKeyProtectorExport,
    meta:{
      requireLogin: true
    }
  },
    /* BleachBit Routes */
    {
      path: '/bleachbit/manager/agent/:id',
      name: 'BleachBitAgentManager',
      component: BleachBitAgentManager,
      meta:{
        requireLogin: true
      }
    },
    {
      path: '/bleachbit/manager/client/:id',
      name: 'BleachBitClientManager',
      component: BleachBitClientManager,
      meta:{
        requireLogin: true
      }
    },
    /* Linux Update Manager Routes */
    {
      path: '/lum/agent/:id',
      name: 'LinuxUpdateAgentManager',
      component: LinuxUpdateAgentManager,
      meta:{
        requireLogin: true
      }
    },
    {
      path: '/lum/client/:id',
      name: 'LinuxUpdateClientManager',
      component: LinuxUpdateClientManager,
      meta:{
        requireLogin: true
      }
    },
        /* HomeBrew Manager Routes */
    {
      path: '/brew/agent/:id',
      name: 'BrewAgentManager',
      component: BrewAgentManager,
      meta:{
        requireLogin: true
      }
    },
    {
      path: '/brew/client/:id',
      name: 'BrewClientManager',
      component: BrewClientManager,
      meta:{
        requireLogin: true
      }
    },
        /* Defender Routes */
    {
      path: '/defender/manager/agent/:id',
      name: 'DefenderAgentManager',
      component: DefenderAgentManager,
      meta:{
        requireLogin: true
      }
    },
    {
      path: '/defender/manager/client/:id',
      name: 'DefenderClientManager',
      component: DefenderClientManager,
      meta:{
        requireLogin: true
      }
    },

    /* Logs Routes */
    {
      path: '/logs/team/service',
      name: 'TeamServiceLogs',
      component: TeamServiceLogs,
      meta:{
        requireLogin: true
      }
    },
    {
      path: '/logs/team/logviewer',
      name: 'LogViewer',
      component: LogViewer,
      meta:{
        requireLogin: true
      }
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record=> record.meta.requireLogin) && !store.state.isAuthenticated){
    next('/')
  }else{
    next()
  }
})

export default router

<template>
  <div class="dropdown">
    <button class="dropbtn">
      <va-icon
        v-if="agent.status == 'Offline'"
        class="icon ion-md-apps"
        style="color: #ee0000"
      ></va-icon>
      <va-icon
        v-else-if="agent.status == 'Online'"
        class="icon ion-md-apps"
        style="color: #00dd00"
      ></va-icon>
      <va-icon v-else class="icon ion-md-apps"></va-icon>
    </button>
    <div class="dropdown-content1">
      <router-link
        :to="{
          name: 'Agent',
          params: {
            id: agent.computerID,
            clientID: agent.clientID,
          },
        }"
        ><va-icon class="icon ion-md-cube mr-1"></va-icon> Agent Details</router-link
      >
      <router-link
        :to="{
          name: 'LinuxUpdateAgentManager',
          params: {
            id: agent.computerID,
            clientID: agent.clientID,
          },
        }"
        ><img src="/images/tux-18.png" class="ml-2" />&nbsp;&nbsp;&nbsp;Linux Update
        Manager
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "AgentMenu-Linux",
  props: {
    agent: Object,
  },
};
</script>
<style>
/* Dropdown Button */
.dropbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 275px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content1 a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content1 {
  display: block;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #ccc;
}
</style>

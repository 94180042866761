<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-4">
        <div class="box">
          <router-link
            :to="{
              name: 'LinuxUpdateAgentManager',
              params: {
                id: agent.computerID,
                clientID: agent.clientID,
              },
            }"
            style="text-decoration: none"
            ><h1 class="subtitle">Linux Update Manager</h1>
          </router-link>
          <div class="tabletitle">
            <span class="name"><strong>Enabled:</strong></span>
            <span class="option">
              <template v-if="agentConfig.isEnabled === true" class="">
                <img src="/images/green_check.png" />
              </template>
              <template v-else class="">
                <img src="/images/red_x.png" />
              </template>
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">Last Scan:</span>
            <span class="option" style="font-size: small">
              {{ format_date(agentConfig.lastScanDate) }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">Last Update:</span>
            <span class="option">
              {{ format_date(agentConfig.lastUpdate) }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">Script:</span>
            <span class="option">
              {{ agentConfig.scriptVersion }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">PM:</span>
            <span class="option">
              {{ agentConfig.packageManager }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">PM Version:</span>
            <span class="option">
              {{ agentConfig.packageManagerVersion }}
            </span>
          </div>
          <div class="tabletitle">
            <span class="name">Updates:</span>
            <span class="option"> [ {{ agentConfig.updateCount }} ] </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Agent_Linux",
  props: {
    agent: Object,
  },
  data() {
    return {
      agentConfig: {},
    };
  },
  mounted() {
    this.getAgentConfigs();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    async getAgentConfigs() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.agent.computerID;
      await axios
        .get(`/api/v1/lum/get_agent_config/${computerID}/`)
        .then((response) => {
          this.agentConfig = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template></template>
<script>
import axios from "axios";
export default {
  name: "PushNotifications",
  mounted() {
    this.registerSw();
  },
  methods: {
    //
    // Start The Notifications Services
    //
    async registerSw() {
      if ("serviceWorker" in navigator) {
        const reg = await navigator.serviceWorker.register("/js/sw.js");
        this.initialiseState(reg);
      } else {
        this.showNotAllowed("You can't send push notifications ☹️😢");
      }
    },
    initialiseState(reg) {
      if (!reg.showNotification) {
        this.showNotAllowed("Showing notifications isn't supported ☹️😢");
        return;
      }
      if (Notification.permission === "denied") {
        this.showNotAllowed("You prevented us from showing notifications ☹️🤔");
        return;
      }
      if (!"PushManager" in window) {
        this.showNotAllowed("Push isn't allowed in your browser 🤔");
        return;
      }
      this.subscribe(reg);
    },
    showNotAllowed(message) {
      const button = document.querySelector("form>button");
      button.innerHTML = `${message}`;
      button.setAttribute("disabled", "true");
    },
    urlB64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      const outputData = outputArray.map((output, index) => rawData.charCodeAt(index));
      return outputData;
    },
    async subscribe(reg) {
      const subscription = await reg.pushManager.getSubscription();
      if (subscription) {
        this.sendSubData(subscription);
        return;
      }
      const key =
        "BCG1VZTOq-26ysrHGW3wccXx2GWkyLYHCcxdn78RexeHZhqw67HJEn0NhRt3UyLyzFcgj_LHxjozlFNFY85aXo0";
      const options = {
        userVisibleOnly: true,
        // if key exists, create applicationServerKey property
        ...(key && { applicationServerKey: this.urlB64ToUint8Array(key) }),
      };
      const sub = await reg.pushManager.subscribe(options);
      this.sendSubData(sub);
    },
    async sendSubData(subscription) {
      const browser = navigator.userAgent
        .match(/(firefox|msie|chrome|safari|trident)/gi)[0]
        .toLowerCase();
      const data = {
        status_type: "subscribe",
        subscription: subscription.toJSON(),
        browser: browser,
      };
      console.log(JSON.stringify(data));
      await axios
        .post("/api/v1/notify/save_information", JSON.stringify(data), {
          headers: { "content-type": "application/json" },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // End of Notifications service
  },
};
</script>
<style></style>

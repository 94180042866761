<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12 is-multiline">
        <div class="column is-12">
          <h1 class="title">Edit member</h1>
          <form @submit.prevent="submitForm">
            <div class="field">
              <label>First Name</label>
              <div class="control">
                <input type="text" name="name" class="input" v-model="user.first_name" />
              </div>
            </div>
            <div class="field">
              <label>Last Name</label>
              <div class="control">
                <input type="text" name="name" class="input" v-model="user.last_name" />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <button class="button is-success">Update</button>
              </div>
            </div>
          </form>
        </div>
        <div class="column is-12">
          <!--          <div id="app">
            <button type="button" class="btn" @click="showModal">Open Modal!</button>

            <Modal v-show="isModalVisible" @close="closeModal" />
          </div>
 -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { toast } from "bulma-toast";
import Modal from "@/components/Modal.vue";
export default {
  name: "EditMember",
  data() {
    return {
      isModalVisible: false,
      user: {},
    };
  },
  components: {
    Modal,
  },
  mounted() {
    this.getUser();
  },

  methods: {
    async getUser() {
      this.$store.commit("setIsLoading", true);
      const userID = this.$route.params.id;
      await axios
        .get(`/api/v1/teams/member/${userID}/`)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async submitForm() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      if (this.first_name === "") {
        this.errors.push("First Name  is missing");
      }
      if (this.last_name === "") {
        this.errors.push("Last Name  is missing");
      }
      const userID = this.$route.params.id;

      await axios
        .put(`/api/v1/teams/member/${userID}/`, this.user)
        .then((response) => {
          toast({
            message: "User updated successfully",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.$router.push({ name: "Team" });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

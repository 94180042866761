<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Edit Team</h1>
      </div>
      <div class="column is-12">
        <form @submit.prevent="submitForm">
          <div class="field">
            <label>RMM Type</label>
            <div class="control">
              <div class="select">
                <select v-model="team.apiType">
                  <option value="Automate">ConnectWise Automate</option>
                  <option value="ScreenConnect">ConnectWise Connect</option>
                  <option value="TacticalRMM">Tactical RMM</option>
                  <option value="Datto">Datto</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Company Name</label>
            <div class="control">
              <input type="text" class="input" v-model="team.name" />
            </div>
          </div>
          <div class="field">
            <label class="checkbox-inline"
              ><input
                class="form-check-input mr-2"
                type="checkbox"
                v-model="team.mfa"
              />Enable Multi Factor Authenication</label
            >

            <p class="mt-2">
              <small class="text-muted ml-4 mt-2 mb-2">
                (Enable MFA if you want all team members to be required to use MFA to
                login to RMMmax)</small
              >
            </p>
          </div>
          <div class="field">
            <label>RMM Host Name</label>
            <div class="control">
              <input type="text" class="input" v-model="team.rmmHost" />
            </div>
          </div>
          <div class="field">
            <label>API URL</label>
            <div class="control">
              <input type="text" class="input" v-model="team.apiURL" />
            </div>
          </div>
          <template v-if="team.apiType == 'Automate'">
            <div class="field">
              <label>API User</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiUser" />
              </div>
            </div>
            <div class="field">
              <label>API Password</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiPass" />
              </div></div
          ></template>
          <template v-if="team.apiType == 'TacticalRMM'">
            <div class="field">
              <label>API Token</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiToken" />
              </div>
            </div>
          </template>
          <template v-if="team.apiType == 'Datto'">
            <div class="field">
              <label>API Key</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiToken" />
              </div>
            </div>
            <div class="field">
              <label>API Secret</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiSecret" />
              </div>
            </div>
          </template>
          <template v-if="team.apiType == 'ScreenConnect'">
            <div class="field">
              <label>Allowed Origin</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiToken" />
              </div>
            </div>
            <div class="field">
              <label>Access Key</label>
              <div class="control">
                <input type="text" class="input" v-model="team.apiSecret" />
              </div>
            </div>
          </template>
          <div class="field">
            <label>Billing Email</label>
            <div class="control">
              <input type="text" class="input" v-model="team.billingEmail" />
            </div>
          </div>
          <div class="field">
            <label>Notification Email</label>
            <div class="control">
              <input type="text" class="input" v-model="team.notificationEmail" />
            </div>
          </div>
          <div class="field">
            <label>Address</label>
            <div class="control">
              <input type="text" class="input" v-model="team.address" />
            </div>
          </div>
          <div class="field">
            <label>City</label>
            <div class="control">
              <input type="text" class="input" v-model="team.city" />
            </div>
          </div>
          <div class="field">
            <label>Zipcode</label>
            <div class="control">
              <input type="text" class="input" v-model="team.zipcode" />
            </div>
          </div>
          <div class="field">
            <label>Country</label>
            <div class="control">
              <input type="text" class="input" v-model="team.country" />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button class="button is-success">Submit</button>
            </div>
          </div>
        </form>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "EditTeam",
  data() {
    return {
      errors: [],
      team: {
        members: [],
        created_by: {},
        name: "",
        rmmHost: "",
        mfa: false,
        apiType: "",
        apiURL: "",
        apiUser: "",
        apiPass: "",
        apiToken: "",
        apiSecret: "",
        address: "",
        city: "",
        zipcode: "",
        country: "",
        billingEmail: "",
        notificationEmail: "",
      },
    };
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async submitForm() {
      this.errors = [];
      if (!this.team.name.length > 0) {
        this.errors.push("Team Name can not be blank.");
      }

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        //console.log(this.team);
        this.team.apiURL = this.team.apiURL.replace(/\/?$/, "/");
        await axios
          .post("/api/v1/teams/edit_team/", this.team)
          .then((response) => {
            //console.log(response.data);
            toast({
              message: "Company updated successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            this.$store.commit("setTeam", {
              name: this.team.name,
              rmmHost: this.team.rmmHost,
              apiType: this.team.apiType,
              apiURL: this.team.apiURL,
              apiUser: this.team.apiUser,
              apiPass: this.team.apiPass,
              apiToken: this.team.apiToken,
              apiSecret: this.team.apiSecret,
              plan: this.$store.state.team.plan,
              max_agents: this.$store.state.team.max_agents,
              max_clients: this.$store.state.team.max_clients,
            });
            this.$router.push("/dashboard/team");
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

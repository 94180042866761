<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Dashboard</h1>
        <template v-if="errors.length">
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
        </template>
      </div>
      <div class="column is-3">
        <div class="">
          <!-- Full length menu area -->
          <template v-if="num_clients != 0">
          <router-link
            class="button dashNavMain is-light mb-2"
            :to="{ name: 'Clients' }"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-contacts"></va-icon> <strong>Clients</strong>
          </router-link>
        
          <router-link
            class="button dashNavMain is-light mb-2"
            :to="{ name: 'AllAgents' }"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop"></va-icon> <strong>All Agents</strong>
          </router-link>
        </template>
          <router-link
            class="button dashNavMain is-light mb-2"
            :to="{ name: 'RMMEnvironment' }"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-build"></va-icon> <strong>RMM Environment</strong>
          </router-link>
          <router-link
            class="button dashNavMain is-light mb-2"
            :to="{ name: 'Notifications' }"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-at"></va-icon> <strong>Notifications</strong>
          </router-link>
          <router-link
            :to="{ name: 'SyncRMM', params: { id: $store.state.user.id } }"
            class="button dashNavMain is-light mb-2"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-archive mr-1"></va-icon> <strong>Sync RMM</strong>
          </router-link>
          <router-link
            class="button dashNavMain is-light mb-2"
            :to="{ name: 'TeamServiceLogs' }"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop"></va-icon> <strong>Service Logs</strong>
          </router-link>
          <!-- <button type="button" class="btn" @click="showModal">Open Modal!</button> -->
        </div>
      </div>
      <div class="column is-9">
        <div class="container">
          <div class="columns mb-2">
            
            <template v-if="num_agents != 0">
            <div class="column is-4">
              <AgentStatusPie />
            </div>
            <div class="column is-4">
              <AgentOSTypePie />
            </div>
            <div class="column is-4">
              <AgentTypePie />
            </div>
          </template>
          <template v-else>
            <div class="column is-12">
              <h1 class="title">Welcome to RMMmax!</h1><p class="mt-3">To optimize the interaction between RMMmax, your RMM, clients, and agents, it's essential to make some configuration adjustments. Ensure that RMM Sync has been successfully completed. Navigate to the RMM environment menu to set up any presets, and then check the "Sync RMM" box to initiate data collection. After the collections are finished, a new "Clients" menu will be available, allowing you to access each client's toolbox.</p>
            </div>
          </template>
          </div>
        </div>
        <!---  Start Main Body --->
        <template v-if="num_agents != 0">
        <va-collapse
          v-model="isStatusVisible"
          style="width: 100%"
          header="Tool Sync Status"
        >
          <BitLockerResentSync />
          <ChocoResentSync />
          <DefenderResentSync />
          <SurfLogResentSync />
          <BleachBitResentSync />
          <LUMResentSync />
          <BrewResentSync />
        </va-collapse>
      </template>
        <template v-if="haveStats == 1">
          <va-collapse
            v-model="isMonthlyActivityVisible"
            style="width: 100%"
            header="Monthly Tool Activity"
            class="mt-3"
          >
            <div class="container mt-2">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <div class="box">
                    <h2 class="subtitle mt-3">
                      <i class="icon ion-md-bulb"></i> Stat Box 1
                    </h2>
                    <h4 class="is-size-5 mx-auto">
                      The Windows Defender threat counts per month chart shows overall
                      activity from all enabled clients.
                    </h4>
                    <DashboardBar1 />
                  </div>
                </div>
                <div class="column is-6">
                  <div class="box">
                    <h2 class="subtitle mt-3">
                      <i class="icon ion-md-bulb"></i> Stat Box 2
                    </h2>
                    <h4 class="is-size-5 mx-auto">
                      The amount of overall web requests made for all enabled clients and
                      agent.
                    </h4>
                    <DashboardBar2 />
                  </div>
                </div>
                <div class="column is-6">
                  <div class="box">
                    <h2 class="subtitle mt-3 mx-1">
                      <i class="icon ion-md-bulb"></i> Stat Box 3
                    </h2>
                    <h4 class="is-size-5 mx-auto">Volumes under Bitlocker Management</h4>
                    <DashboardBar3 />
                  </div>
                </div>
                <div class="column is-6">
                  <div class="box">
                    <h2 class="subtitle mt-3 mx-1">
                      <i class="icon ion-md-bulb"></i> Stat Box 4
                    </h2>
                    <h4 class="is-size-5 mx-auto">
                      Chocolatey Package Management Delivery
                    </h4>
                    <DashboardBar4 />
                  </div>
                </div>
              </div>
            </div>
          </va-collapse>
        </template>

      </div>
    </div>
  </div>
  <Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:header> RMMMax Announcements </template>
    <template v-slot:body>
      <div v-for="(index, item) in motd">
        <h1 class="subtitle">{{ index.title }} - [{{ format_date(index.postDate) }}]</h1>
        <div class="box">
          <pre>{{ index.motdMessage }}</pre>
        </div>
        <hr />
      </div>
    </template>

    <template v-slot:footer>
      For more information please contact support@rmmmax.com
    </template>
  </Modal>
</template>

<script>
import AgentTypePie from "@/components/charts/dashboard/AgentTypePie";
import AgentOSTypePie from "@/components/charts/dashboard/AgentOSTypePie";
import AgentStatusPie from "@/components/charts/dashboard/AgentStatusPie";
import DashboardBar1 from "@/components/charts/dashboard/dashboard_bar_1";
import DashboardBar2 from "@/components/charts/dashboard/dashboard_bar_2";
import DashboardBar3 from "@/components/charts/dashboard/dashboard_bar_3";
import DashboardBar4 from "@/components/charts/dashboard/dashboard_bar_4";
import ChocoResentSync from "@/components/charts/dashboard/ChocolateyResentSyncGauge";
import SurfLogResentSync from "@/components/charts/dashboard/SurfLogResentSyncGauge";
import BitLockerResentSync from "@/components/charts/dashboard/BitLockerResentSyncGauge";
import DefenderResentSync from "@/components/charts/dashboard/DefenderResentSyncGauge";
import BleachBitResentSync from "@/components/charts/dashboard/BleachBitResentSyncGauge";
import LUMResentSync from "@/components/charts/dashboard/LUMResentSyncGauge";
import BrewResentSync from "@/components/charts/dashboard/BrewResentSyncGauge";
import Modal from "@/components/Modal.vue";
import axios from "axios";
import moment from "moment";
import { toast } from "bulma-toast";
export default {
  name: "Dashboard",
  components: {
    DashboardBar1,
    DashboardBar2,
    DashboardBar3,
    DashboardBar4,
    ChocoResentSync,
    SurfLogResentSync,
    BitLockerResentSync,
    DefenderResentSync,
    BleachBitResentSync,
    LUMResentSync,
    BrewResentSync,
    AgentTypePie,
    AgentOSTypePie,
    AgentStatusPie,
    Modal,
  },
  data() {
    return {
      num_agents: 0,
      num_clients: 0,
      isModalVisible: false,
      isStatusVisible: false,
      isMonthlyActivityVisible: false,
      errors: [],
      motd: [],
      haveStats: 0,
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getAgentsCount();
    this.getClients();
    this.getTeam();
    this.getStatCount();
    this.getMOTD();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },

    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      if (this.team.name === undefined) {
        this.$router.push("/dashboard/add-team");
      } else if (this.team.name === "") {
        this.$router.push("/dashboard/add-team");
      }

      this.$store.commit("setIsLoading", false);
    },
    async getStatCount() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/stats/team/dashboard_stat_view/")
        .then((response) => {
          this.haveStats = response.data["stats"];
          //console.log(response.data["stats"]);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAgentsCount() {
      await axios
        .get(`/api/v1/agents/`)
        .then((response) => {
          this.num_agents = response.data.length;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMOTD() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/motd/get_messages/`)
        .then((response) => {
          this.motd = response.data;
          if (this.motd.length > 0) {
            this.showModal();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getClients() {
      await axios
        .get(`/api/v1/clients/`)
        .then((response) => {
          this.num_clients = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async readMOTD() {
      this.$store.commit("setIsLoading", true);
      for (let i in this.motd) {
        const motdID = this.motd[i].id;
        await axios
          .get(`/api/v1/motd/read_messages/${motdID}/`)
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      }
      this.$store.commit("setIsLoading", false);
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.readMOTD();
    },
  },
};
</script>
<style lang="scss">
.dashNavMain {
  display: block;
  width: 90%;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
}
</style>

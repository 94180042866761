<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <router-link
          :to="{
            name: 'BitLockerClientManager',
            params: { id: this.$route.params.clientID },
          }"
          class="button"
          style="text-decoration: none"
        >
          <va-icon class="icon ion-md-desktop mr-1"></va-icon> Client Manager
        </router-link>
      </div>

      <div class="column is-12">
        <div class="card card-body">
          <h1>Export Client Key Protectors</h1>
          <p class="mb-3 mt-3">
            Use these controls to export the client key protectors list.
          </p>
          <table class="table" id="keysTable">
            <thead>
              <tr>
                <th>ComputerID</th>
                <th>Name</th>
                <th>Type</th>
                <th>Drive</th>
                <th>KeyID</th>
                <th>(Recovery) Password or PIN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="key in keys">
                <td>
                  {{ key.computerID }}
                </td>
                <td>
                  {{ getComputerName(key.computerID) }}
                </td>
                <td>
                  {{ key.protectorType }}
                </td>
                <td>
                  {{ key.mountPoint }}
                </td>
                <td>
                  {{ key.keyProtectorID }}
                </td>
                <td>
                  {{ key.recoveryPassword }}
                  {{ key.password }}
                  {{ key.pin }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "BitLockerKeyProtectorExport",

  data() {
    return {
      keys: [],
      agents: [],
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
    this.getAgents();
    this.getKeys();
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    getComputerName(id) {
      for (let i in this.agents) {
        if (this.agents[i].computerID === id) {
          return this.agents[i].computerName;
        }
      }
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    // Get Agents Functions
    async getAgents() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/windows_agents_by_client/${clientID}`)
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getKeys() {
      this.$store.commit("setIsLoading", true);
      const clientID = this.$route.params.clientID;
      await axios
        .get(`/api/v1/bitlocker/get_agents_key_protectors_by_client/${clientID}/`)
        .then((response) => {
          this.keys = response.data;
          setTimeout(function () {
            $("#keysTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style>
table {
  width: 100%;
  table-layout: fixed;
}
</style>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">My Account</h1>
      </div>
      <div class="column is-11">
        <div class="buttons">
          <router-link
            :to="{ name: 'EditMember', params: { id: $store.state.user.id } }"
            class="button"
            ><va-icon class="icon ion-md-contact mr-1"></va-icon> Manage Me</router-link
          >
          <template v-if="team.created_by.id === $store.state.user.id">
            <router-link to="/dashboard/team" class="button"
              ><va-icon class="icon ion-md-contacts mr-1"></va-icon> Manage
              Team</router-link
            >
          </template>
          <template v-else>
            <router-link to="/dashboard/team" class="button"
              ><va-icon class="icon ion-md-contacts mr-1"></va-icon> View
              Team</router-link
            >
          </template>
        </div>
      </div>
      <div class="column is-1"></div>
    </div>
    <!---
    <div class="column is-8 is-offset-2">

    </div>
    --->
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "MyAccount",
  data() {
    return {
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.team.name === undefined) {
        this.$router.push("/dashboard/add-team");
      }
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

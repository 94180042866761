<template>
  <div class="container">
    <div class="columns is-multiline">
    <template v-if="this.customersAllowed == true">
      <div class="column is-8 is-offset-2">
        <h1 class="title">Sign up and create your team</h1>
        <h2 class="subtitle mt-3">Sign up and basic services are free!</h2> <p class="mb-3">There are zero fees and no credit cards are required to sign up and manage your first 15 agents. Use all the abilities we have to offer as you try out the different tools and services for as long as you desire.</p>
        <h2 class="subtitle mt-3">Before you sign up!</h2> <p class="mb-3">Has anyone else from your team already signed up? If so, you may need to reach out to them and have them add your email as a member to your company’s existing team. Signing up will cause a new team to be created which may duplicate services for your RMM environment. RMMMax is team based, once your team is established your team leader can add new members to the team giving them the same level of access.</p>
        <h2 class="subtitle mt-4">Why is this important?</h2><p class="mb-3">You want to signup if you’re the first team member (team leader) of your organization, create a team and once created, that team represents your companies RMM or RAT environment. You will be given an opportunity during the team building process to select either our free tier service or select one of our subscription services. You can manage you plan at any time by visiting the account settings for your team.</p> 
        </div>
      <div class="column is-4 is-offset-4 mt-3">
        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Email</label>
            <div class="control">
              <input type="email" name="email" class="input" v-model="username" />
            </div>
          </div>
          <div class="field">
            <label>First Name</label>
            <div class="control">
              <input type="text" name="name" class="input" v-model="first_name" />
            </div>
          </div>
          <div class="field">
            <label>Last Name</label>
            <div class="control">
              <input type="text" name="name" class="input" v-model="last_name" />
            </div>
          </div>
          <div class="field">
            <label>Password</label>
            <div class="control">
              <input type="password" name="password1" class="input" v-model="password1" />
            </div>
          </div>
          <div class="field">
            <label>Retype Password</label>
            <div class="control">
              <input type="password" name="password2" class="input" v-model="password2" />
            </div>
          </div>
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            <p class="mt-5">
        <h3>Need to resend Activation Email?</h3>Make sure your email is in the form above and select to resend activation. This will cause an email to be sent to the address provided.

        </p>
        <button class="button is-warning mt-2" @click="resendActivation">
            <va-icon class="icon ion-md-download mr-1"></va-icon> Resend Activation
          </button>
          </div>
          <div class="field">
            <div class="control">
              <button class="button is-success">Submit</button>
            </div>
          </div>
        </form>
      </div>
      </template>
      <template v-else-if="this.customersAllowed == 'undefined'">
        <p>&nbsp;</p>
      </template>
      <template v-else>
        <div class="column is-8 is-offset-2">
        <h1 class="title mt-5">Thank you for your interest in RMMMax</h1>
        <p>We greatly appreciate your interest in RMMMax. Unfortunately, we have reached our maximum capacity for new customers due to the overwhelming response from the MSP community. Ensuring a positive experience for all users is our top priority, which is why we have implemented sign-up limits to prevent overloading our services. This will allow us to make necessary adjustments to support the influx of new customers and provide quality service to everyone.

Kindly check back at a later time to see if sign-ups are available again. Thank you for your understanding and continued interest in RMMMax.</p>
        
        <p class="mt-3"><strong>Your RMMMax Team!</strong></p>
        <p class="mb-3"><br><br></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "Signup",
  data() {
    return {
      customersAllowed: 'undefined',
      username: "",
      email: "",
      first_name: "",
      last_name: "",
      password1: "",
      password2: "",
      errors: [],
    };
  },
  mounted() {
    this.getCustomers();

  },
  methods: {
    async getCustomers() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/teams/customers_allowed/")
        .then((response) => {
          if(response.status == 201){this.customersAllowed = true}
          else{this.customersAllowed = false}
          
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

    async submitForm() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      let regExp = /[A-Z]/;
      if (this.username === "") {
        this.errors.push("Email is missing");
      }
      if (this.password1.length < 8) {
        this.errors.push("Password is to short, minimium 8 chars.");
      }
      if (!regExp.test(this.password1)) {
        this.errors.push("Password requires one or more capital letters");
      }
      if (this.password1 !== this.password2) {
        this.errors.push("Passwords do not match");
      }

      if (!this.errors.length) {
        const formData = {
          username: this.username,
          email: this.username,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password1.trim(),
        };

        await axios
          .post("/api/v1/users/", formData)
          .then((response) => {
            toast({
              message:
                "Account was created, a email is being sent that allows you to complete activation.",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.$router.push("/signup_thankyou");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push(
                "The email account may be invalid or already in use. Try sending reactivation request."
              );
            } else if (this.errors.message) {
              this.errors.push("Password was rejected, please make it more complex.");
            }
          });
      }
      this.$store.commit("setIsLoading", false);
    },
    async resendActivation() {
      this.$store.commit("setIsLoading", true);
      this.errors = [];
      let regExp = /[A-Z]/;
      if (this.username === "") {
        this.errors.push("Email is missing");
      }

      if (!this.errors.length) {
        const email = {email:this.username};
        await axios
          .post("/api/v1/users/resend_activation/", email)
          .then((response) => {
            toast({
              message: "A email is being sent that allows you to complete activation.",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.$router.push("/signup_thankyou");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                /* this.errors.push(`${property}: ${error.response.data[property]}`); */
              }
              this.errors.push("Unable to resend activation email to " + this.username + ", The account maybe active already");
            } else if (this.errors.message) {
              this.errors.push("The email address is missing from form.");
            }
          });
      }
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<template>
        <div class="card card-body">
          <P class="mt-3 mb-5"><input type="checkbox" v-model="clientDefaults.chocolateyEnable"  @click="setChocolateyEnable" /> <strong>Enable Chocolatey Tool</strong></P>
          <template v-if="clientDefaults.chocolateyEnable == true">   
          <form @submit.prevent="setChocolateyDefaults"  class="row g-3">       
          <h2 class="subtitle">Chocolatey Settings</h2>
          <p class="mb-2">
              This is the chocolatey parameters area that will control how agents use
              Chocolatey
            </p>

            <div class="col-auto">
              <div class="field">
                <div class="control">
                  <label class="checkbox-inline"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientDefaults.chocolateyEnableInstalls"
                    />Auto Install Packages</label
                  >
                  <label class="checkbox-inline ml-6"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientDefaults.chocolateyEnableUpdates"
                    />Auto Update Packages</label
                  >
                  <label class="checkbox-inline ml-6"
                    ><input
                      class="form-check-input mr-2"
                      type="checkbox"
                      v-model="clientDefaults.chocolateyLicenseEnable"
                    />Auto License Agents</label
                  >
                </div>
              </div>              
            </div>
            <div class="col-8">
              <div class="field">
                <label class="mb-3">Chocolatey MSP License (optional)</label>
                <div class="control">
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="clientDefaults.chocolateyLicense"
                  ></textarea>
                </div>
              </div>
            </div>
     
          <hr/>   
          <h1 class="subtitle mt-4">Chocolatey Source Repository (optional)</h1>
          <P class="mt-3 mb-3"><input type="checkbox" v-model="clientDefaults.chocolateySourceEnable" /> Enable Chocolatey Source</P>
          <p class="mb-3" style="font-size: small">By default all Chocolatey enabled agents use the public Chocolatey repository. However, you may choose to operate a private repository or operate a Chocolatey proxy in which you would want to place that information below. Any new clients will add this sources to it the enabled agents. </p>
          <template v-if="clientDefaults.chocolateySourceEnable == true">
                  <div class="col-auto">
                    <div class="field">
                      <label class="mb-2">Source Name</label> (required)
                      <div class="control">
                        <input type="text" class="resizedTextbox-md" v-model="clientDefaults.chocolateySourceName" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="mb-2">URL</label> (required)
                      <div class="control">
                        <input type="text" class="resizedTextbox-md" v-model="clientDefaults.chocolateySourceURL" />
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="field">
                      <label class="mb-2">Username</label> (optional)
                      <div class="control">
                        <input
                          type="text"
                          class="resizedTextbox-md"
                          v-model="clientDefaults.chocolateySourceUsername"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label class="mb-2">Password</label> (optional)
                      <div class="control">
                        <input
                          type="password"
                          class="resizedTextbox-md"
                          v-model="clientDefaults.chocolateySourcePassword"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="field">
                      <div class="control mt-3">
                        <label class="mt-2">Priority  </label>
                        <input class="resizedTextbox w-25 ml-2" v-model="clientDefaults.chocolateySourcePriority" />
                      </div>
                    </div>
                    
                  </div>
                  <div class="col-auto">
                    <div class="control mt-4">
                      <button class="button is-success">
                        <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                      </button>
                    </div>
                  </div>            
                </template>
                  <hr/>  
          <h1 class="subtitle mt-4">Chocolatey Update Schedule</h1>
                  <P class="mt-3 mb-3"><input type="checkbox" v-model="clientDefaults.chocolateyScheduleEnable" /> Enable Chocolatey Schedule</P>
          <p class="mb-3" style="font-size: small">Set a schedule for default updates to packages. </p>

                  <template v-if="clientDefaults.chocolateyScheduleEnable == true">

                  <span class="a">
                  <div class="field mt-5">
                    <label>Schedule Type</label>
                    <div class="control mt-3">
                      <div class="select">
                        <select v-model="clientDefaults.chocolateySchedule">
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </span>
                <span class="a">
                  <div class="field mt-5">
                    <template v-if="clientDefaults.chocolateySchedule == 'Weekly'">
                      <label>Schedule day of week</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="clientDefaults.chocolateyScheduleDay">
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                          </select>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="clientDefaults.chocolateySchedule == 'Monthly'">
                      <label>Schedule day of month</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="clientDefaults.chocolateyScheduleDay">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                          </select>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <label></label>
                      <div class="control mt-3"><p></p></div>
                    </template>
                  </div>
                </span>
                <span class="a">
                    <div class="field mt-5">
                      <label>Schedule time (00:00)</label>
                      <div class="control mt-3">
                        <div class="select">
                          <select v-model="clientDefaults.chocolateyScheduleHour">
                            <option value="00">00:00</option>
                            <option value="01">01:00</option>
                            <option value="02">02:00</option>
                            <option value="03">03:00</option>
                            <option value="04">04:00</option>
                            <option value="05">05:00</option>
                            <option value="06">06:00</option>
                            <option value="07">07:00</option>
                            <option value="08">08:00</option>
                            <option value="09">09:00</option>
                            <option value="10">10:00</option>
                            <option value="11">11:00</option>
                            <option value="12">12:00</option>
                            <option value="13">13:00</option>
                            <option value="14">14:00</option>
                            <option value="15">15:00</option>
                            <option value="16">16:00</option>
                            <option value="17">17:00</option>
                            <option value="18">18:00</option>
                            <option value="19">19:00</option>
                            <option value="20">20:00</option>
                            <option value="21">21:00</option>
                            <option value="22">22:00</option>
                            <option value="23">23:00</option>
                          </select>
                        </div>
                      </div>
                    </div>
                </span>
  
              </template>  
              <div class="col-auto">
                <div class="control mt-4">
                  <button class="button is-success">
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Save
                  </button>
                </div>
              </div>            
          </form>
          <hr/>
        <h1 class="subtitle">Approved Packages</h1>
        
          <p class="mb-4">
              Add Chocolatey.org package names  you would like to
              associate the package with and select the save button. You can select any
              package from the list and it will populate this form allowing you to remove
              the package.
            </p>

            <form @submit.prevent="addChocolateyPackage" class="row g-4">

              <div class="col-auto">
                <label>Chocolatey Package Name</label>
                <div class="control mt-2">
                  <input
                    type="text"
                    class="resizedTextbox-md"
                    v-model="packageName"
                  />
                </div>
              </div>
              <div class="col-auto">
                <div class="control mt-4">
                  <button class="button is-success">
                    <va-icon class="icon ion-md-save mr-1"></va-icon> Add Package
                  </button>
                </div>
              </div>
            </form>
            <div style="width: 300px" class="mt-5">
              <template v-if="approvedPackages.length > 0">
              <strong>Packages</strong>
            <table class="table table-sm" id="approvedPackagesTable">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(chocopack, itemObjKey) in approvedPackages" v-bind:key="chocopack.id">
                  <td style="width: 20px">
                        <a href="#"
                          @click="deletePackage(chocopack.packageName)"
                          style="text-decoration: none"
                          ><va-icon class="icon ion-md-trash mr-1"></va-icon></a>
                  </td>
                  <td>{{ chocopack.packageName }}</td>         
                </tr>
              </tbody>
            </table>
          </template>
        </div>

          </template>    
          
        </div>  


</template>
<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "ChocolateyClientDefaults",
  props: {
    clientDefaults: Object,
  },
  data() {
    return {
      packageName: '',
      approvedPackages: [],
    };
  },
  mounted() {
    this.getApprovedPackages()
  },
  methods: {
    async getApprovedPackages() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/get_clients_approved_packages/`)
        .then((response) => {
          this.approvedPackages = response.data
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async setChocolateyEnable() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/set_chocolatey_default_enable/`)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async setChocolateyDefaults() {
      this.$store.commit("setIsLoading", true);
      await axios
        .post(`/api/v1/clients/set_clients_defaults/`, this.clientDefaults)
        .then((response) => {
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async addChocolateyPackage() {
      this.$store.commit("setIsLoading", true);
      const mypackage = this.packageName;
      await axios
        .get(`/api/v1/clients/set_approved_package/${mypackage}/`)
        .then((response) => {
          this.packageName = "";
          this.getApprovedPackages()
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
    async deletePackage(mypackage) {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/clients/delete_approved_package/${mypackage}/`)
        .then((response) => {
          this.getApprovedPackages()
          toast({
              message: "Request sent successfully",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
              message: error.message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <transition name="modal-fade1">
    <div class="modal-backdrop1">
      <div
        class="modal1"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header1" id="modalTitle">
          <slot name="header"> RMMMax MOTD</slot>
          <button
            type="button"
            class="btn-close1"
            @click="close"
            aria-label="Close modal"
          >
            x
          </button>
        </header>

        <section class="modal-body1" id="modalDescription">
          <slot name="body"> Thank you for being a partner. </slot>
        </section>

        <footer class="modal-footer1">
          <slot name="footer"> Visit our support community at docs.rmmmax.com </slot>
          <!--          <button
            type="button"
            class="btn-green1"
            @click="close"
            aria-label="Close modal"
          >
            Close me!
          </button>
          -->
        </footer>
      </div>
    </div>
  </transition>
</template>

<style>
.modal-backdrop1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal1 {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  margin: auto;
  height: 65%;
  width: 65%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header1,
.modal-footer1 {
  padding: 15px;
  display: flex;
}

.modal-header1 {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #a20202;
  font-weight: bold;
  justify-content: space-between;
}

.modal-footer1 {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  background-color: #aaaaaa;
  color: #eeeeee;
}

.modal-body1 {
  position: relative;
  padding: 20px 10px;
}

.btn-close1 {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 26px;
  padding: 7px;
  cursor: pointer;
  font-weight: bold;
  color: #4d4f4f;
  background: transparent;
}

.btn-green1 {
  color: white;
  background: #1cd585;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter1,
.modal-fade-leave-to1 {
  opacity: 0;
}

.modal-fade-enter-active1,
.modal-fade-leave-active1 {
  transition: opacity 0.5s ease;
}
</style>

<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-7">
        <h1 class="title">HomeBrew Agent Manager for {{ agent.computerName }}</h1>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: 'BrewClientManager',
              params: { id: this.$route.params.clientID },
            }"
            class="button"
            style="text-decoration: none"
          >
            <va-icon class="icon ion-md-desktop mr-1"></va-icon> Agents
          </router-link>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#installSoftware"
            aria-expanded="false"
            aria-controls="installSoftware"
          >
            <va-icon class="icon ion-md-cloud-download mr-1"></va-icon> Install Software
          </button>
          <button
            class="button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#medkit"
            aria-expanded="false"
            aria-controls="medkit"
          >
            <va-icon class="icon ion-md-medkit mr-1"></va-icon> Doctor
          </button>
          <button class="button is-success" @click="updateAgent">
            <va-icon class="icon ion-md-save mr-1"></va-icon> Update Agent
          </button>
          <button class="button is-success" @click="rescanAgent">
            <va-icon class="icon ion-md-git-compare mr-1"></va-icon> Rescan
          </button>
          <button class="button is-success" @click="removePackages">
            <va-icon class="icon ion-md-trash mr-1"></va-icon> Remove Package(s)
          </button>
        </div>
      </div>
      <!-- End of Buttons -->
      <!-- status box  -->
      <div class="column is-5">
        <div class="card card-body">
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Sync:</strong></p></span
            >
            <span class="option">
              <template v-if="checkDateWithin30Days(agentConfigs.lastScanDate) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfigs.lastScanDate) }}
                </p></template
              >
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfigs.lastScanDate) }}
                </p></template
              >
            </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name">
              <p><strong>Last Update:</strong></p>
            </span>
            <span class="option">
              <template v-if="checkDateWithin30Days(agentConfigs.lastUpdate) === false">
                <p class="text-danger" style="font-size: small">
                  {{ format_date(agentConfigs.lastUpdate) }}
                </p></template>
              <template v-else
                ><p class="text-success" style="font-size: small">
                  {{ format_date(agentConfigs.lastUpdate) }}
                </p></template>
            </span>
          </div>  
          <div class="tabletitle-slim">
            <span class="name"
              ><p><strong>Last Install:</strong></p></span>
            <span class="option">
              <p class="text-success" style="font-size: small">
                  {{ format_date(agentConfigs.lastInstall) }}
                </p>
            </span>
          </div>          
          <template v-if="agentConfigs.isEnabled === true">
            <div class="tabletitle-slim">
              <span class="name"><strong>Agent Enabled:</strong></span>
              <span class="option">
                <img class="ml-5" src="/images/green_check.png" />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="tabletitle-slim">
              <span class="name"><strong>Agent Enabled:</strong></span>
              <span class="option">
                <img class="ml-5" src="/images/red_x.png" />
              </span>
            </div>
          </template>
          <div class="tabletitle-slim">
            <span class="name"><strong>Updates:</strong></span>
            <span class="option"> [ {{ agentConfigs.updateCount }} ] Packages </span>
          </div>
          <div class="tabletitle-slim">
            <span class="name">
              <strong>Brew Version:</strong>
            </span>
            <span class="option"
              ><template v-if="agentConfigs.brewVersion != ''">
                {{ agentConfigs.brewVersion }}
              </template>
              <template v-else>Not Installed</template></span
            >
          </div>
          <div class="tabletitle-slim">
            <span class="name">
              <strong>Sudo User:</strong>
            </span>
            <span class="option">{{ agentConfigs.sudoUser }} </span>
          </div>
        </div>
      </div>
      <!-- start Install Software dropdown -->
      <div class="column is-12">
        <div class="collapse" id="installSoftware">
          <div class="card card-body">
            <h1 class="subtitle">Install Software</h1>
            <p class="mb-5">
              Install software quickly across one or more agents. Provide the package name
              in the field below, select the install and the package request will be sent to the RMM for the agent to process.
            </p>
            <form @submit.prevent="installSoftwareForm">
              <div class="field">
                <label class="mb-2">Package Name</label>
                <div class="control">
                  <input type="text" class="resizedTextbox-md" v-model="packageName" />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-success">Install</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end Install Software -->
                  <!-- start Medkit dropdown -->
                  <div class="column is-12">
        <div class="collapse" id="medkit">
          <div class="card card-body">
            <h1 class="subtitle">Doctor</h1>
            <p class="mb-5">
              Homebrew Doctor reports current issues in the deployment of homebrew or with in the taps, casks, and formulae. This is the output from the last scan of this agent. 
            </p>
            <hr/>
            <template v-if="agentConfigs.doctor !== null">
            <pre>{{ decode_base64(agentConfigs.doctor) }}</pre>
          </template>
          <template v-else><p>No agent data currently available.</p></template>
          </div>
        </div>
      </div>
      <!-- end MedKit -->
      <!---  Main View --->
      <div class="column is-12">
        <div class="card card-body">
          <div class="tabletitle mb-5">
            <span class="name"> <h1 class="subtitle">Installed Packages</h1></span>
            <span class="option">
              <button class="button is-success" @click="getAgentPackages()">
                <va-icon class="icon ion-md-repeat"></va-icon></button
            ></span>
          </div>

          <template v-if="agentPackages.length >= 1">
            <table class="table is-fullwidth" id="brewAgentTable">
              <thead>
                <tr>
                  <th style="width: 12px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="selectAll" @click="select" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </th>
                  <th>Package</th>
                  <th>Type</th>
                  <th>Version</th>
                  <th>Available</th>
                  <th>Current</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, itemObjKey) in agentPackages" v-bind:key="p.id">
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" :value="p.packageName" v-model="selected" />
                      <va-icon class="form-icon"></va-icon>
                    </label>
                  </td>
                  <td>
                    <span class="ml-1"
                      ><strong>{{ p.packageName }}</strong></span
                    >
                  </td>
                  <td>
                    <span class="ml-1">{{ p.packageType }}</span>
                  </td>
                  <td>
                    <span class="ml-1">{{ p.currentVersion }} </span>
                  </td>
                  <td>
                    <span class="ml-1">{{ p.availableVersion }} </span>
                  </td>
                  <td>
                    <template v-if="extractVersion(p.currentVersion) === extractVersion(p.availableVersion)">
                      <img src="/images/green_check.png" /><span style="display: none"
                            >enabled</span>
                    </template>
                    <template v-else>
                      <img src="/images/red_x.png" /><span style="display: none"
                            >disabled</span>
                    </template>
                  </td>     
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p>This agent has no packages listed in the database.</p></template
          >
        </div>

        <div class="mt-6">&nbsp;</div>
        <div class="mt-6">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css"; //for table good looks
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import { doAgentWork } from "@/workers/brew/brew-agent-api";
import axios from "axios";
import moment from "moment";
import { toast } from "bulma-toast";
export default {
  name: "BrewAgentManager",
  data() {
    return {
      errors: [],
      packageName: "",
      selected: [],
      selectAll: false,
      agentConfigs: {},
      agentPackages: [],
      agent: {},
      team: {
        members: [],
        created_by: {},
      },
    };
  },
  async mounted() {
    this.getTeam();
    this.getAgent();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm A");
      }
    },
    extractVersion(version) {
      return version.split('_')[0]; // Splitting the version at the last '_'
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.agents) {
          this.selected.push(this.agents[i].computerID);
        }
      }
    },
    decode_base64(value) {
      if (value) {
        const decodedString = atob(value);
        return decodedString
      }
    },
    checkDateWithin30Days(date) {
      const now = new Date();
      const oneMonthEarlier = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      return new Date(date) > oneMonthEarlier;
    },
    checkDateWithin2Days(date) {
      const now = new Date();
      const twoDaysEarlier = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      );
      return new Date(date) > twoDaysEarlier;
    },
    async getAgent() {
      this.$store.commit("setIsLoading", true);
      const agentID = this.$route.params.id;
      await axios
        .get(`/api/v1/agents/${agentID}`)
        .then((response) => {

          this.agent = response.data;                    
        })
        .catch((error) => {
          console.log(error);
        });
        await axios
        .get(`/api/v1/brew/get_agent_config/${agentID}/`)
        .then((response) => {
          this.agentConfigs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        await axios
        .get(`/api/v1/brew/get_agent_packages/${agentID}/`)
        .then((response) => {
          this.agentPackages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
          setTimeout(function () {
            $("#brewAgentTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 25,
              processing: true,
              dom: "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>" +
                 "<'row'<'col-sm-12'B>>",
              lengthMenu: [
                [25, 50, 100, -1],
                [25, 50, 100, "All"],
              ],
              buttons: ["copy", "csv", "print"],
            });
          }, 100);
      this.$store.commit("setIsLoading", false);
    },
    async getTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/teams/get_my_team/")
        .then((response) => {
          if (!response.data.name.length > 0) {
            this.$router.push({ name: "AddTeam" });
          }
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    // Get Agent Packages  function
    async getAgentPackages() {
      this.$store.commit("setIsLoading", true);
      const computerID = this.$route.params.id;
      await axios
        .get(`/api/v1/brew/get_agent_packages/${computerID}/`)
        .then((response) => {
          this.agentPackages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    // Install Software Form Function
    async installSoftwareForm() {
      this.errors = [];
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);

        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.agentConfigs.clientID,
          axiosBaseURL: axios.defaults.baseURL,
          computerID: this.$route.params.id,
          packageName: this.packageName,
        };
        await doAgentWork(token, "installSoftware", CMDDATA);
        toast({
          message: "HomeBrew install package sent to agent",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.packageName = ""
        this.$store.commit("setIsLoading", false);
      }
    },
    // Update Agents Function
    async updateAgent() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more packages from the list and try again."
        );
      }      
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.agentConfigs.clientID,
          axiosBaseURL: axios.defaults.baseURL,
          computerID: this.$route.params.id,
          packages: this.selected.toString(),
          updateAll: this.selectAll

        };
        await doAgentWork(token, "updateAgent", CMDDATA);
        toast({
          message: "HomeBrew agent set update command",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.selected = [];
        this.selectAll = false;
        this.$store.commit("setIsLoading", false);
      }
    },
    // Remove Packages Function
    async removePackages() {
      this.errors = [];
      if (!this.selected.length) {
        this.errors.push(
          "You need to select 1 or more packages from the list and try again."
        );
      }      
      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);
        const token = localStorage.getItem("token");
        const CMDDATA = {
          clientID: this.agentConfigs.clientID,
          axiosBaseURL: axios.defaults.baseURL,
          computerID: this.$route.params.id,
          packages: this.selected.toString(),
        };
        await doAgentWork(token, "removePackages", CMDDATA);
        toast({
          message: "HomeBrew uninstall request sent successfully",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: "bottom-right",
        });
        this.selected = [];
        this.selectAll = false;
        this.$store.commit("setIsLoading", false);
      }
    },

    // Rescan Agent function
    async rescanAgent() {
      this.$store.commit("setIsLoading", true);
      const token = localStorage.getItem("token");
      const CMDDATA = {
        clientID: this.agentConfigs.clientID,
        axiosBaseURL: axios.defaults.baseURL,
        computerID: this.$route.params.id,
      };
      await doAgentWork(token, "rescanAgent", CMDDATA);
      toast({
        message: "HomeBrew rescan agent sent successfully",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "bottom-right",
      });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
